import {Inject, Injectable} from '@angular/core';
import {APP_CONFIG} from '../../app-config/app-config.constants';
import {IAppConfig} from '../../app-config/app-config.interface';

import {HttpClient, HttpErrorResponse} from '@angular/common/http';

import {contentHeaders} from '../guard/headers';
import {Roles, User, Users} from '../../models/user';

import {AuthService} from '../../auth/auth.service';

import {throwError} from 'rxjs';
import {catchError} from 'rxjs';

@Injectable()
export class UsersService {
    private loginUrl = '/api/admin/v1/login';
    private loggedIn = false;
    private currentUser: User;

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an ErrorObservable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    };

    // private _currentUserSource = new Subject<User>();
    // currentUser$ = this._currentUserSource.asObservable();

    constructor(private authService: AuthService,
                private http: HttpClient,
                @Inject(APP_CONFIG) private config: IAppConfig) {
        this.loginUrl = config.API_DOMAIN + this.loginUrl;
        this.loggedIn = !!localStorage.getItem('id_token');
    }

    login(email: string, password: string) {
        const body = JSON.stringify({'_username': email, '_password': password, 'source': 'ADM2'});
        return this.http.post(this.loginUrl, body, {headers: contentHeaders})
            .pipe(
                catchError(this.handleError)
            );
    }

    /**
     * setUser
     */
    setCurrentUser(user: any) {
        if (user.roles.length) {
            user.roles = this.formatRoles(user)
        }
        this.currentUser = new User(user);
        localStorage.setItem('currentUser', JSON.stringify(this.currentUser))
    }

    setOperator(operator: string, user: object) {
        localStorage.setItem(operator, JSON.stringify(user))
    }

    getOperator(operator: string) {
        return JSON.parse(localStorage.getItem(operator));
    }

    bothOperatorsSelectedAndCurrentUserOneOfThem() {
        const operator1 = this.getOperator('operator1') || {};
        const operator2 = this.getOperator('operator2') || {};
        const user = this.getCurrentUser();

        const bothOperatorsSelected = (operator1 && operator1.firstName) && (operator2 && operator2.firstName);
        const currentUserIsOperator = (user.id === operator1.id) || (user.id === operator2.id);

        return bothOperatorsSelected && currentUserIsOperator;
    }

    removeOperator(operator: string) {
        return localStorage.removeItem(operator);
    }

    addUserToLoggedUsersList(data: {user: User, token: string, refresh_token: string}) {
        const user: User = data.user;
        const users: Users = this.getLoggedUsersList();

        // add user to users dictionary
        users[user.id] = data;
        this.updateLoggedUsersList(users);
    }

    removeUserFromLoggedUsersList(userId: string) {
        const users: Users = this.getLoggedUsersList();

        if (users[userId]) {
            delete users[userId];
        }

        this.updateLoggedUsersList(users);
        const user = this.getCurrentUser();
        if (user.id == userId) {
            this.logOutCurrentUser()
        }
    }

    logOutCurrentUser() {
        const currentUser = this.getCurrentUser();
        const operator1 = this.getOperator('operator1');
        const operator2 = this.getOperator('operator2');

        if (operator1 && operator1.id === currentUser.id) {
            this.removeOperator('operator1');
        }

        if (operator2 && operator2.id === currentUser.id) {
            this.removeOperator('operator2');
        }

        localStorage.removeItem('currentUser');
        localStorage.removeItem('isLoggedin');
        localStorage.removeItem('id_token');
        localStorage.removeItem('refresh_token');
    }

    switchUserById(id: string) {
        const users = this.getLoggedUsersList();
        this.setCurrentUser(users[id].user);
        localStorage.setItem('id_token', users[id].token);
        localStorage.setItem('refresh_token', users[id].refresh_token);
        localStorage.setItem('isLoggedin', 'logged_in');
    }

    switchToLastLoggedUser() {
        const users = this.getLoggedUsersList();
        const lastUserId = Object.keys(users)[0];
        this.switchUserById(lastUserId);
    }

    isNoUserLoggedIn() {
        const users = this.getLoggedUsersList();
        return Object.keys(users).length < 1;
    }

    isOnlyOneUserLogged() {
        const users = this.getLoggedUsersList();
        return Object.keys(users).length === 1;
    }

    getLoggedUsersList() {
        return JSON.parse(localStorage.getItem('loggedUsers')) || {};
    }

    updateLoggedUsersList(users) {
        localStorage.setItem('loggedUsers', JSON.stringify(users));
    }

    getPreviouslyLoggedUser(id: string) {
        const users = this.getLoggedUsersList();
        return users[id];
    }

    getCurrentUser(): any {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        if (user) {
            this.currentUser = new User(user);
            return this.currentUser;
        }
        return false
    }

    formatRoles(user) {
        const roles: Roles = {};
        user.roles.forEach(role => {
            switch (role) {
                case 'ROLE_ADMIN':
                case 'ROLE_SUPER_ADMIN':
                    roles.admin = true;
                    break;
                case 'ROLE_USER':
                    roles.user = true;
                    break;
                case 'ROLE_PRINTER':
                    roles.printer = true;
                    break;
                case 'ROLE_PICKER':
                    roles.picker = true;
                    break;
            }
        });

        return roles;
    }


    isLoggedIn(): boolean {
        return this.loggedIn;
    }

}
