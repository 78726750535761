import {Injectable} from '@angular/core';
import {Filter} from '../filter';
import {
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER,
    DYNAMIC_FORM_CONTROL_TYPE_INPUT,
    DynamicFormOption,
    DynamicInputModel
} from '@ng-dynamic-forms/core';
import * as listFilterActions from '../../../../../../store/list-filter/list-filter.actions';
import {FilterStore} from '../../../models/filter-store';
import {Store} from '@ngrx/store';
import * as fromCrud from '../../../../../../store/list-filter';
import {ListFilter} from '../../../models/filter';
import {FilterPresentationInterface} from '../filter.presentation';
import {ListFilterService} from '../../../list-filter.service';

@Injectable()
export class NumberRangeFilter extends Filter {
    static readonly modelType: string = 'number_range';

    private numberRangeFromModel: DynamicInputModel;
    private numberRangeToModel: DynamicInputModel;

    constructor(
        listFilterService: ListFilterService,
        store$: Store<fromCrud.State>,
        filter: ListFilter,
        filterPresentation: FilterPresentationInterface,
    ) {
        super(listFilterService, store$, filter, filterPresentation, NumberRangeFilter.modelType);
    }

    getModels(): any[] {
        const models = [this.getNumberRangeFromModel(), this.getNumberRangeToModel()];

        this.applyFilter(this.getFromToNumbers(), false);

        return models;
    }

    protected initMainModel(): void {
        this.mainModel = new DynamicInputModel({
            id: this.filter.field,
            label: this.filter.label,
            placeholder: this.filter.label,
            inputType: DYNAMIC_FORM_CONTROL_TYPE_INPUT,
            suffix: this.mainModelSuffix,
            disabled: true,
        });
    }

    public accept(emitChanges = true): void {
        const numbers = this.getFromToNumbers();

        this.applyFilter(numbers, emitChanges);
        this.updateMainModelValue(this.getNumberRange(numbers));
    }

    public setValue(value: {}): void {
        this.numberRangeFromModel.value = value['numberRangeFromModel']
        this.numberRangeToModel.value = value['numberRangeToModel']
    }

    public getValue(): {} {
        return {
            'numberRangeFromModel': this.numberRangeFromModel.value,
            'numberRangeToModel': this.numberRangeToModel.value,
        };
    }

    protected reset(): void {
        super.reset();

        if (this.numberRangeFromModel) {
            this.numberRangeFromModel.value = null;
            this.numberRangeToModel.value   = null;
        }
    }

    protected initComparisonModel(config: {} = {}): void {
        super.initComparisonModel({hidden: true});

        this.comparisonModel.add(new DynamicFormOption({
            disabled: true,
            label: ' ',
            value: ''
        }));

        const comparison = this.getComparisonValue(this.getInitialNumberFromValue(), this.getInitialNumberToValue());
        this.comparisonModel.value = comparison;
    }

    protected applyFilter(numbers: [number?, number?], emitChanges = true): void {
        const filterData: any = {},
            [numberFrom, numberTo] = numbers;

        if (!numberFrom && !numberTo) {
            return this.store$.dispatch(new listFilterActions.RemoveFilter(this.id));
        }

        if (!this.filter.inBothPresentations) {
            filterData[this.filter.field] = this.getFilterData(numberFrom, numberTo);
            this.store$.dispatch(new listFilterActions.LoadData(filterData));

            const filterModel = {[this.id]: new FilterStore(this, this.getNumberRange(numbers))};
            this.store$.dispatch(new listFilterActions.AddFilter(filterModel));
        }

        if (emitChanges) {
            this.comparisonModel.value = this.getComparisonValue(numberFrom, numberTo);
        }

        if (emitChanges) {
            this.listFilterService.getFiltersChangeEmitter().emit();
        }
    }

    private getNumberRangeFromModel(): DynamicInputModel {
        if (this.numberRangeFromModel) {
            return this.numberRangeFromModel;
        }

        return this.numberRangeFromModel = new DynamicInputModel({
            id: `${this.filter.field}-field-number-from`,
            label: `${this.filter.label} Von`,
            inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER,
            value: this.getInitialNumberFromValue()
        });
    }

    private getNumberRangeToModel(): DynamicInputModel {
        if (this.numberRangeToModel) {
            return this.numberRangeToModel;
        }

        return this.numberRangeToModel = new DynamicInputModel(
            {
            id: `${this.filter.field}-field-number-to`,
            label: `${this.filter.label} Bis`,
            inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER,
            value: this.getInitialNumberToValue()
        },
            {
                grid: {
                    control: 'margin-left',
                }
            });
    }

    protected getComparisonValue(numberFrom, numberTo) {
        let comparisonValue = '';

        if (numberFrom && numberTo) {
            return comparisonValue;
        } else if (numberFrom) {
            comparisonValue = 'gte';
        } else if (numberTo) {
            comparisonValue = 'lte';
        }

        return comparisonValue;
    }

    protected getFromToNumbers(): [number?, number?] {
        const numberFrom: any = this.numberRangeFromModel.value ? this.numberRangeFromModel.value : null;
        const numberTo: any = this.numberRangeToModel.value ? this.numberRangeToModel.value : null;

        return [numberFrom, numberTo];
    }

    protected getFilterData(numberFrom: number, numberTo: number) {
        const filterData = {};
        if (numberFrom !== null) {
            filterData['gte'] = numberFrom;
        }
        if (numberTo !== null) {
            filterData['lte'] = numberTo;
        }

        return filterData;
    }

    protected getNumberRange(numbers: [number?, number?]) {
        const [numberFrom, numberTo] = numbers;

        return numberFrom && numberTo ? numberFrom + ' - ' + numberTo : numberFrom || numberTo;
    }

    protected getInitialValue(): any[] {
        const values = this.filter.initialValue ? Object.values(this.filter.initialValue) : [];
        return values.filter((value) => value !== 'null').map((value: string) => parseInt(value, 10));
    }

    protected getInitialNumberFromValue(): number | null {
        return this.filter.initialValue ? this.filter.initialValue['gte'] as number : null;
    }

    protected getInitialNumberToValue(): number | null {
        return this.filter.initialValue ? this.filter.initialValue['lte'] as number : null;
    }

    protected setInitialValue(): void {
        const initialValue = this.getInitialValue() as [number?, number?];

        if (initialValue) {
            this.updateMainModelValue(this.getNumberRange(initialValue));
        }
    }

    protected getInitialComparisonValue(): any {
        return false;
    }

}
