import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ColorPickerModule} from 'ngx-color-picker';

import {CustomColorPickerComponent} from '@shared/modules/color-picker/custom-color-picker.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        ColorPickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatFormFieldModule,
    ],
    declarations: [CustomColorPickerComponent],
    exports:      [CustomColorPickerComponent],
})
export class CustomColorPickerModule {
}
