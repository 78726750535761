import {ListFilterActions, ListFilterActionTypes} from './list-filter.actions';
import * as _ from 'lodash';

export interface ListFilterState {
    data: any,
    filters: any,
    filtersModels: any,
}

let initialState: ListFilterState = {
    data:          {},
    filters:       {},
    filtersModels: {},
};

export function listFilterReducer(state = initialState, action: ListFilterActions): ListFilterState {
    switch (action.type) {
        case ListFilterActionTypes.ClearFilters:
            return {...state, filters: {}, filtersModels: {}};
        case ListFilterActionTypes.ClearFilterModels:
            return {...state, filtersModels: {}};
        case ListFilterActionTypes.LoadData:
            const filtersPayload: any = action.payload;
            const filters: any = {};

            Object.assign(filters, {...state.filters}, {...filtersPayload});

            return {...state, ...{filters: filters}};

        case ListFilterActionTypes.AddFilter:
            const field      = Object.values(action.payload).find(item => item['field'])['field'];
            const idToDelete = Object.values(state.filtersModels).find(item => item['field'] === field)?.['id'];

            if (idToDelete) {
                delete state.filtersModels[idToDelete];
            }

            const filtersModels = {};
            Object.assign(filtersModels, {...state.filtersModels}, {...action.payload});

            return {...state, ...{filtersModels: filtersModels}};

        case ListFilterActionTypes.RemoveFilter:
            const filter = state.filtersModels[action.payload];

            if (!filter) {
                return state;
            }

            const newFilterModels = _.omit(state.filtersModels, [action.payload]);
            const newFilters      = _.omit(state.filters, [filter.field]);

            return {data: {}, filters: newFilters, filtersModels: newFilterModels};
        default:
            return state;
    }
}
