import {Action} from '@ngrx/store';

export enum HeaderMenuActionTypes {
    SetTitle = '[HEADER_MENU] Set title',
    GetTitle = '[HEADER_MENU] Get title',
}

export class SetTitle implements Action {
    readonly type = HeaderMenuActionTypes.SetTitle;

    constructor(public payload: string) { }
}

export class GetTitle implements Action {
    readonly type = HeaderMenuActionTypes.GetTitle;
}

export type HeaderMenuActions = SetTitle
    | GetTitle
