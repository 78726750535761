import {DynamicFormGroupModel, DynamicFormOption, DynamicSelectModel} from '@ng-dynamic-forms/core';
import {UUID} from 'angular2-uuid';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';

import * as fromCrud from '../../../../../store/list-filter';
import * as fromListFilter from '../../../../../store/list-filter';
import * as listFilterActions from '../../../../../store/list-filter/list-filter.actions';
import {ListFilter} from '../../models/filter';
import {DatesService} from '@shared/services/dates.service';
import {FilterStore} from '../../models/filter-store';
import {FilterPresentationInterface} from './filter.presentation';
import {ListFilterService} from '../../list-filter.service';

export abstract class Filter {
    static readonly modelType: string = null;
    public modelType: string = Filter.modelType;

    protected id: string;
    protected filter: ListFilter;
    protected filterIds: string[] = [];
    protected mainModel: any;
    protected mainModelSuffix = '';
    protected comparisonModel: DynamicSelectModel<any>;
    protected store$: Store<fromCrud.State>;
    protected storeFilters$: Observable<any>;
    protected filterPresentation: FilterPresentationInterface;
    protected listFilterService: ListFilterService;

    public abstract getModels(): any[];

    public abstract setValue(value: any): void;
    public abstract getValue(): any;

    protected abstract initMainModel(): void;

    constructor(
        listFilterService: ListFilterService,
        store$: Store<fromCrud.State>,
        filter: ListFilter,
        filterPresentation: FilterPresentationInterface,
        modelType: string
    ) {
        this.id = UUID.UUID();
        this.modelType = modelType;
        this.store$ = store$;
        this.filter = filter;
        this.filterPresentation = filterPresentation;
        this.listFilterService = listFilterService;
        this.filterPresentation.setFilter(this);

        this.storeFilters$ = this.store$.pipe(select(fromListFilter.getFiltersValues));
        this.storeFilters$.subscribe((filters) => this.resetHandler(filters));

        this.initComparisonModel();
        this.initMainModel();
        this.setInitialValue();
    }

    public getId(): string {
        return this.id;
    }

    public getFilter(): ListFilter {
        return this.filter;
    }

    public accept(emitChanges = true, _inputValue: string = null): void {
        this.updateMainModelValue(this.mainModel.value);
        this.applyFilter(this.mainModel.value, emitChanges);
    }

    public getSelectedComparison(): DynamicFormOption<any> {
        return this.comparisonModel.options.find((option) => {
            return this.comparisonModel.value === option.value;
        });
    }

    public getFormGroupModel(): DynamicFormGroupModel {
        return this.filterPresentation.getFormGroupModel();
    }

    public updateMainModelValue(value: any, prefix: string = ''): void {
        this.filterPresentation.updateMainModelValue(value, prefix);
    }

    public getMainModel(): any {
        return this.mainModel;
    }

    public getComparisonModel(): DynamicSelectModel<any> {
        return this.comparisonModel;
    }

    public isComparisonNullable(value: any): boolean {
        return value === 'isNull' || value === 'isNotNull'
    }

    protected initComparisonModel(config: {} = {}): void {
        const initialComparisonValue = this.getInitialComparisonValue();
        const options = this.filter.getOptionsForSelect();
        const value = initialComparisonValue ? initialComparisonValue : (config['value'] || options[0]['value']);

        this.comparisonModel = new DynamicSelectModel({
            id: `${this.filter.field}-comparison`,
            multiple: config['multiple'] || false,
            filterable: config['filterable'] || true,
            options: config['options'] || options,
            hidden: config['hidden'] || options.length === 1,
            value: value,
        });
    }

    protected applyFilter(value: any, emitChanges = true): void {
        let emptyValue = this.isValueEmpty(value);

        if (this.isComparisonNullable(this.getSelectedComparison().value)) {
            value = '';
            emptyValue = false;
        }

        if (emptyValue) {
            return this.removeFilterFromStore(emitChanges);
        }

        this.addFilterValueToStore(value);
        this.addFilterModelToStore(value);

        if (emitChanges) {
            this.listFilterService.emitFiltersChange();
        }
    }

    protected resetHandler(filters: {}): void {
        const newFiltersIds = Object.keys(filters);
        const filterId = this.filterIds.filter(i => !newFiltersIds.includes(i)).shift();

        this.clearFilterValue(filterId);
        this.filterIds = newFiltersIds;
    }

    protected clearFilterValue(filterId: string): void {
        if (this.id === filterId) {
            this.reset();
        }
    }

    protected reset(value = null): void {
        if (this.mainModel) {
            this.mainModel.prefix = '';
            this.mainModel.suffix = this.mainModelSuffix;
            this.mainModel.value = value;

            // this.listFilterService.emitFiltersChange();
        }
    }

    protected removeFilterFromStore(emitChanges = true): void {
        this.store$.dispatch(new listFilterActions.RemoveFilter(this.id));

        if (emitChanges) {
            this.listFilterService.emitFiltersChange();
        }
    }

    protected addFilterValueToStore(value: any): void {
        if (this.filter.inBothPresentations) {
            return;
        }

        if (value instanceof Date) {
            value = DatesService.convertDateToIso(value);
        }

        const filterValue: any = {[this.filter.getField()]: {[this.comparisonModel.value]: value}};
        this.store$.dispatch(new listFilterActions.LoadData(filterValue));
    }

    protected addFilterModelToStore(value: any): void {
        if (this.filter.inBothPresentations) {
            return;
        }

        if (value instanceof Date) {
            value = value.toLocaleDateString('de');
        }

        const filterModel = {[this.id]: new FilterStore(this, value)};
        this.store$.dispatch(new listFilterActions.AddFilter(filterModel));
    }

    protected isValueEmpty(value: any): boolean {
        return value === null || value === false || value === '' || ((value instanceof Array && !value.length))
    }

    protected getInitialValue(): any {
        let value = this.filter.initialValue ? Object.values(this.filter.initialValue).shift() : '';
        return value ? value.toString() : '';
    }

    protected getInitialComparisonValue(): any {
        let value = this.filter.initialValue ? Object.keys(this.filter.initialValue).shift() : '';
        return value ? value.toString() : '';
    }

    protected setInitialValue(): void {
        const initialValue = this.getInitialValue();
        this.updateMainModelValue(initialValue);
    }

}
