import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';

import {DynamicDatePickerModel, DynamicInputModel} from '@ng-dynamic-forms/core';

import {Filter} from '../filter';
import {DateFilterHelper} from '../../../helpers/filters/date-filter.helper';
import * as fromCrud from '../../../../../../store/list-filter';
import {ListFilter} from '../../../models/filter';
import {FilterPresentationInterface} from '../filter.presentation';
import {DatesService} from '@shared/services/dates.service';
import {ListFilterService} from '../../../list-filter.service';


@Injectable()
export class DateFilter extends Filter {
    static readonly modelType: string = 'date';

    protected datePickerModel: DynamicDatePickerModel;
    protected preDefinedModel: DynamicInputModel;

    constructor(
        listFilterService: ListFilterService,
        store$: Store<fromCrud.State>,
        filter: ListFilter,
        filterPresentation: FilterPresentationInterface,
    ) {
        super(listFilterService, store$, filter, filterPresentation, DateFilter.modelType);
    }

    public getModels(): any[] {
        return [this.getDatePickerModel(), this.getPreDefinedModel()];
    }

    public accept(emitChanges = true): void {
        this.updateMainModelValue(this.datePickerModel.value);
        this.applyFilter(this.datePickerModel.value, emitChanges);
    }

    public setValue(value: any): void {
        this.datePickerModel.value = value;
    }

    public getValue(): any {
        return this.datePickerModel.value;
    }

    protected reset(): void {
        super.reset();

        this.datePickerModel.value = null;
    }

    protected initMainModel(): void {
        this.mainModel = new DynamicDatePickerModel({
            id:       this.filter.field,
            label:    this.filter.label,
            disabled: true,
        });
    }

    protected getDatePickerModel(): DynamicDatePickerModel {
        if (this.datePickerModel) {
            return this.datePickerModel
        }

        this.getComparisonModel().valueChanges.subscribe((value) => {
            if (this.datePickerModel) {
                this.datePickerModel.readOnly = this.isComparisonNullable(value);
                this.datePickerModel.disabled = this.isComparisonNullable(value);
                this.toggleDisableDatepicker();
            }
        });

        const initialValue           = this.getInitialValue();
        const initialComparisonValue = this.getInitialComparisonValue();

        if (initialValue || this.isComparisonNullable(initialComparisonValue)) {
            this.addFilterValueToStore(initialValue ? DatesService.convertDateToIso(new Date(initialValue)) : '');
            this.addFilterModelToStore(initialValue ? DatesService.toLocaleDateString(new Date(initialValue)) : '');
        }

        return this.datePickerModel = new DynamicDatePickerModel({
            id:       `${this.filter.field}-field`,
            label:    this.filter.label,
            value:    initialValue ? new Date(initialValue) : null,
            disabled: this.isComparisonNullable(initialComparisonValue)
        });
    }

    protected getPreDefinedModel(): DynamicInputModel {
        if (this.preDefinedModel) {
            return this.preDefinedModel;
        }

        this.addPreDefinedModelListener();

        return this.preDefinedModel = new DynamicInputModel({
            id:       `${this.filter.field}-pd`,
            label:    this.filter.label,
            multiple: true,
            list:     this.filter.getPreDefinedLabels(),
            value:    this.filter.getPreDefinedLabels(),
        });
    }

    protected addPreDefinedModelListener(): void {
        setTimeout(() => DateFilterHelper.addPredefinedClickListener(this.filter, this.datePickerModel, this.getComparisonModel()), 0);
    }

    protected toggleDisableDatepicker(): void {
        if (this.datePickerModel.disabled) {
            DateFilterHelper.disableDatepicker(this.datePickerModel.id);
        } else {
            DateFilterHelper.enableDatepicker(this.datePickerModel.id);
        }
    }

    protected getInitialValue(): any {
        let value = this.filter.initialValue ? Object.values(this.filter.initialValue).shift() : false;
        return value;
    }

}
