import {Store} from '@ngrx/store';
import {DynamicFormModel} from '@ng-dynamic-forms/core';

import {ListFilter} from '../../models/filter';
import {State as ListFilterState} from '../../../../../store/list-filter/index';
import {RequestService} from '@shared/services/request.service';

import {
    DateFilter,
    DatePeriodFilter,
    DateRangeFilter,
    DateRangeWithInputFilter,
    NumberFilter,
    NumberRangeFilter,
    NumberRangeSliderFilter,
    QueryFilter,
    SelectFilter,
    SelectMultipleAutocompleteFilter,
    SelectMultipleFilter,
    SwitchFilter,
} from '../filters';
import {RightPanelFilterPresentation} from '../filters/right-panel-filter.presentation';
import {Filter} from '../../filter-main-panel/filters';
import {ListFilterService} from '../../list-filter.service';
import {BaseFormModelFactory} from '../../form/base-form-model-factory';
import {IsSetFilter} from "@shared/modules/list-filter/filter-right-panel/filters/is-set/is-set.filter";

export class FormModelFactory extends BaseFormModelFactory {

    static create(
        listFilterService: ListFilterService,
        filters: Array<ListFilter>,
        store$: Store<ListFilterState>,
        requestService: RequestService
    ): DynamicFormModel {
        const model: DynamicFormModel = [];
        FormModelFactory.filters      = [];

        for (const filter of filters) {
            let filterControl: Filter;
            const filterPresentation = new RightPanelFilterPresentation();

            switch (filter.type) {
                case QueryFilter.modelType:
                    filterControl = new QueryFilter(listFilterService, store$, filter, filterPresentation, requestService);
                    break;
                case NumberFilter.modelType:
                    filterControl = new NumberFilter(listFilterService, store$, filter, filterPresentation);
                    break;
                case IsSetFilter.modelType:
                    filterControl = new IsSetFilter(listFilterService, store$, filter, filterPresentation);
                    break;
                case SelectFilter.modelType:
                    filterControl = new SelectFilter(listFilterService, store$, filter, filterPresentation);
                    break;
                case SwitchFilter.modelType:
                    filterControl = new SwitchFilter(listFilterService, store$, filter, filterPresentation);
                    break;
                case DateFilter.modelType:
                    filterControl = new DateFilter(listFilterService, store$, filter, filterPresentation);
                    break;
                case SelectMultipleFilter.modelType:
                    filterControl = this.isAutocompleteUrlProvided(filter)
                        ? new SelectMultipleAutocompleteFilter(listFilterService, store$, filter, filterPresentation, requestService)
                        : new SelectMultipleFilter(listFilterService, store$, filter, filterPresentation, requestService)
                    break;
                case DateRangeFilter.modelType:
                    filterControl = new DateRangeFilter(listFilterService, store$, filter, filterPresentation);
                    break;
                case DateRangeWithInputFilter.modelType:
                    filterControl = new DateRangeWithInputFilter(listFilterService, store$, filter, filterPresentation);
                    break;
                case NumberRangeFilter.modelType:
                    filterControl = this.isMaxMinSettingProvided(filter)
                        ? new NumberRangeSliderFilter(listFilterService, store$, filter, filterPresentation)
                        : new NumberRangeFilter(listFilterService, store$, filter, filterPresentation);
                    break;
                case DatePeriodFilter.modelType:
                    filterControl = new DatePeriodFilter(listFilterService, store$, filter, filterPresentation);
                    break;
                default:
                    throw new Error(`Unexpected filter control type: ${filter.type}.`);

            }

            FormModelFactory.filters.push(filterControl);
            model.push(filterControl.getFormGroupModel());
        }

        return model;
    }
}
