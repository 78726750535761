import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {MenuItem} from './menu-item';
import {MENU_SLIDE_IN, MENU_SLIDE_OUT} from '../../../store/layout/menu';
import {API_ROUTES} from '../../services/api-router.service';
import {PermissionService} from '../../services/permission.service';
import * as fromHeaderMenuActions from '../../../store/layout/header-menu/header-menu.actions';
import * as fromHeaderMenu from '../../../store/layout/header-menu/index';
import {HeaderMenuState} from '../../../store/layout/header-menu/header-menu.reducer';
import {APP_PATH} from '@shared/constants/app-path.constants';

export interface AppState {
    menu: boolean;
    layout: {
        headerMenu: HeaderMenuState
    };
}

@Component({
    selector:        'app-sidebar',
    templateUrl:     './sidebar.component.html',
    styleUrls:       ['./sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SidebarComponent implements OnInit {
    static LOCAL_STORAGE_KEY = 'sidebar_menu_active_item_url';

    headerMenu$: Observable<any>;
    public activeMenuItem: MenuItem;
    public menuItems: Array<MenuItem> = [
        new MenuItem()
            .setTitle('Aufträge')
            .setIcon('local_atm')
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.orderAdjustmentsSearch)
                    .setTitle('Retouren')
                    .setUrl('/return')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.orderAdjustmentsManualPayments)
                    .setTitle('Erstattungen')
                    .setUrl('/refund/banktransfer')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.orderAdjustmentsAmazonManualProcessing)
                    .setTitle('Amazon Erstattungen')
                    .setUrl('/refund/amazon')
            ),
        new MenuItem()
            .setTitle('Rohprodukt Verwaltung')
            .setIconClass('fas fa-tshirt')
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.goodsTariffNumbersAll)
                    .setTitle('Warentarifnummern')
                    .setUrl('/raw-product/goods-tariff-numbers')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.rawProductsAll)
                    .setTitle('Rohprodukte')
                    .setUrl('/' + APP_PATH.rawProduct)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.platformCategoryAll)
                    .setTitle('Platform Mappings')
                    .setUrl('/raw-product/platform-category')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.mainMaterialAll)
                    .setTitle('Hauptmaterial')
                    .setUrl('/raw-product/main-material')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.colorGroupsAll)
                    .setTitle('Farbgruppen')
                    .setUrl('/raw-product/color-groups')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.colorsAll)
                    .setTitle('Produktfarben')
                    .setUrl('/raw-product/colors')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.sexesAll)
                    .setTitle('Zielgruppen')
                    .setUrl('/raw-product/sexes')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.sizeGroupsAll)
                    .setTitle('Größengruppen')
                    .setUrl('/raw-product/size-groups')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.sizesAll)
                    .setTitle('Größen')
                    .setUrl('/raw-product/sizes')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.supplierList)
                    .setTitle('Großhändler')
                    .setUrl('/raw-product/suppliers')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.fulfillmentProviderAll)
                    .setTitle('Produzent')
                    .setUrl('/raw-product/fulfillment-providers')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.fulfillmentProviderPrintingFileTemplateAll)
                    .setTitle('Druckdatei Templates')
                    .setUrl('/' + APP_PATH.fulfillmentProviderPrintingFileTemplates)
            ),
        new MenuItem()
            .setTitle('Bestellungen Verwaltung')
            .setIcon('shopping_cart')
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.ordersAll)
                    .setTitle('Aufträge')
                    .setUrl('/' + APP_PATH.orders)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.ordersNotShipped)
                    .setTitle('Nicht versendete')
                    .setUrl('/' + APP_PATH.notShippedOrders)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.ordersAll)
                    .setTitle('Zuweisung Produzenten')
                    .setUrl('/' + APP_PATH.fulfillmentProviderOrders)
            ),
        new MenuItem()
            .setTitle('Motiv Verwaltung')
            .setIconClass('fas fa-paint-brush')
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.motiveAll)
                    .setTitle('Motive')
                    .setUrl('/motive/motives')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.motiveCategoryAll)
                    .setTitle('Motiv Kategorien')
                    .setUrl('/motive/motive-categories')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.designResearchAll)
                    .setTitle('Design Recherche')
                    .setUrl('/design-research')
            ),
        new MenuItem()
            .setACLRoute(API_ROUTES.mockupsAll)
            .setIcon('image')
            .setTitle('Mockups')
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.mockupsAll)
                    .setTitle('Mockups')
                    .setUrl('/mockups/mockups')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.mockupUsageConditionsAll)
                    .setTitle('Mockup Usage Conditions')
                    .setUrl('/mockups/mockup-usage-conditions/channels')
            ),
        new MenuItem()
            .setTitle('Shop Produkte Verwaltung')
            .setIcon('store_mall_directory')
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.productProductsGetAll)
                    .setTitle('Shop Produkte')
                    .setUrl('/product/products')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.productMarketplaceInfoGetList)
                    .setTitle('Markplatz Produktstatus')
                    .setUrl('/product/marketplace-info')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.amazonReviewsAll)
                    .setTitle('Amazon Rezensionen')
                    .setUrl('/product/amazon-reviews')
            ),
        new MenuItem()
            .setTitle('Admin')
            .setIcon('settings')
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.settingsAll)
                    .setTitle('Einstellungen')
                    .setUrl('/admin/settings')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.responseMessageCodeAll)
                    .setTitle('ADM2 Fehlermeldungen')
                    .setUrl('/admin/admin-api-response-message-code')
            ),
        new MenuItem()
            .setTitle('Suchbegriffe Verwaltung')
            .setIcon('edit')
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.searchTermsAll)
                    .setTitle('Suchbegriffe')
                    .setUrl('/search-term/search-terms')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.trademarksAll)
                    .setTitle('Markencheck')
                    .setUrl('/search-term/trademarks')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.generalSearchTermRelationsList)
                    .setTitle('Tag Editor')
                    .setUrl('/' + APP_PATH.searchTermRelations)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.generalSearchTermRelationsList)
                    .setTitle('Tag Editor Monitor')
                    .setUrl('/' + APP_PATH.searchTermTagEditorPerformanceTime)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.generalSearchTermTagList)
                    .setTitle('Tag List')
                    .setUrl('/search-term/tags')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.advertisingDataExportAll)
                    .setTitle('Advertising Datenexport')
                    .setUrl('/' + APP_PATH.advertisingDataExport)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.searchTermReportsAll)
                    .setTitle('Suchbegriff Berichte')
                    .setUrl('/search-term/search-term-reports')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.amazonRestrictedTitlePhrasesAll)
                    .setTitle('Title blockierte Phrasen')
                    .setUrl('/search-term/amazon-restricted-title-phrases')
            ),
        new MenuItem()
            .setTitle('Amazon')
            .setIconClass('fa fa-amazon')
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.feedQueueAll)
                    .setTitle('Amazon Feeds')
                    .setUrl('/' + APP_PATH.feedQueues)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.blockedProductVariationAll)
                    .setTitle('Datenupdate Blockade')
                    .setUrl('/' + APP_PATH.amazonBlockedProductVariations)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.feedTestToolGetShopProducts)
                    .setTitle('Amazon Test-Tool')
                    .setUrl('/' + APP_PATH.feedTestToolShopProducts)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.shippingGroupsAll)
                    .setTitle('Versandvorlagen')
                    .setUrl('/' + APP_PATH.amazonShippingGroups)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.sponsoredBrandCampaignAll)
                    .setTitle('ADs - Sponsored Brands')
                    .setUrl('/amazon/sponsored-brand-campaigns')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.vineProductAll)
                    .setTitle('VINE Produkte')
                    .setUrl('/' + APP_PATH.vineProducts)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.vineOrderAll)
                    .setTitle('VINE Bestellungen')
                    .setUrl('/' + APP_PATH.vineOrders)
            ),
        new MenuItem()
            .setTitle('Fba')
            .setIcon('storage')
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.orderResearchAll)
                    .setTitle('Auftragsrecherche')
                    .setUrl('/fba/order-research')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.fbaResearchFilterAll)
                    .setTitle('Filter')
                    .setUrl('/fba/filter')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.orderDraftAll)
                    .setTitle('Auftrags-Entwurf')
                    .setUrl('/fba/order-draft')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.parcelParkingSlotAll)
                    .setTitle('Paket Parkplatz Mangement')
                    .setUrl('/fba/parcel-management')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.deliveryPlanAll)
                    .setTitle('Delivery plan')
                    .setUrl('/fba/delivery-plan')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.fulfillmentParcelAll)
                    .setTitle('Fulfillment Paket Mangement')
                    .setUrl('/fba/fulfillment-parcel-management')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.fulfillmentParcelAll)
                    .setTitle('Offene Pakete')
                    .setUrl('/fba/open-fulfillment-parcel')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.fbaShippingGetOrderItem)
                    .setTitle('Versand')
                    .setUrl('/fba/shipman')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.fbaShippingGetOrderItem)
                    .setTitle('ROQ Versand')
                    .setUrl('/fba/roq-shipman')
            ),
        new MenuItem()
            .setTitle('Stockman')
            .setIcon('inbox')
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.supplierParcelItemsList)
                    .setTitle('Tracking Nummer Scan')
                    .setUrl('/stockman/tracking-number-scan')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.supplierList)
                    .setTitle('Sendung wählen')
                    .setUrl('/stockman/manual-select')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.rawProductsAll)
                    .setTitle('Produkt wählen')
                    .setUrl('/stockman/raw-product')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.roqRecipesAll)
                    .setTitle('ROQ Rezepte')
                    .setUrl('/stockman/roq-recipes')
            ),
        new MenuItem()
            .setTitle('ShipMan')
            .setIcon('local_shipping')
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.roqQueueGetOrderItemsList)
                    .setTitle('Scan Auftragsposition')
                    .setUrl('/' + APP_PATH.shipmanOrderItems)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.roqQueueGetOpenList)
                    .setTitle('Liste der offenen ROQ Queue')
                    .setUrl('/' + APP_PATH.shipmanOpenRoqQueues)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.manifestCreate)
                    .setTitle('Versandübergabeprotokoll')
                    .setUrl('/' + APP_PATH.shipmanManifest)
            ),
        new MenuItem()
            .setACLRoute(API_ROUTES.productDataImportAll)
            .setTitle('Produkte CSV Datenimport')
            .setUrl('/products/data-import')
            .setIcon('publish'),
        new MenuItem()
            .setTitle('AX Semantics')
            .setCustomIcon('axsemantics-logo')
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.axCollectionList)
                    .setTitle('Kollektionen')
                    .setUrl('/' + APP_PATH.axSemanticCollections)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.axSemanticMotiveDescriptionAdjectiveList)
                    .setTitle('Adjektive')
                    .setUrl('/' + APP_PATH.axSemanticMotiveDescriptionAdjectives)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.axSemanticEventList)
                    .setTitle('Events')
                    .setUrl('/ax-semantic/events')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.axSemanticBuyerGroupList)
                    .setTitle('Käufergruppen')
                    .setUrl('/' + APP_PATH.axSemanticBuyerGroups)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.axSemanticMotiveDescriptionNounList)
                    .setTitle('Nomen')
                    .setUrl('/' + APP_PATH.axSemanticMotiveDescriptionNouns)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.axSemanticMotiveStyleList)
                    .setTitle('Stilrichtungen')
                    .setUrl('/' + APP_PATH.axSemanticMotiveStyles)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.axSemanticThemeList)
                    .setTitle('Themen')
                    .setUrl('/' + APP_PATH.axSemanticThemes)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.axSemanticTargetGroupList)
                    .setTitle('Zielgruppen')
                    .setUrl('/' + APP_PATH.axSemanticTargetGroups)
            ),
        new MenuItem()
            .setTitle('Channel data')
            .setIcon('dashboard')
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.channelDataEventBusGetDashboardTotal)
                    .setTitle('Dashboard')
                    .setUrl('/channel-data/dashboard')
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.channelDataEventBusGetList)
                    .setTitle('Event bus')
                    .setUrl('/' + APP_PATH.channelDataEventBus)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.channelDataSyncQueueGetList)
                    .setTitle('Sync queue')
                    .setUrl('/' + APP_PATH.channelDataSyncQueue)
            ),
        new MenuItem()
            .setTitle('Content Tools')
            .setIcon('language')
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.languageAnalyzeEntity)
                    .setTitle('Namensanalyse')
                    .setUrl('/' + APP_PATH.languageAnalyzeEntity)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.stopWordList)
                    .setTitle('Stoppwörter')
                    .setUrl('/' + APP_PATH.languageStopWords)
            ),
        new MenuItem()
            .setTitle('Kornit')
            .setIcon('print')
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.kornitGetSettings)
                    .setTitle('Kornit')
                    .setUrl('/' + APP_PATH.kornit)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.kornitMediaList)
                    .setTitle('Kornit media')
                    .setUrl('/' + APP_PATH.kornitMedia)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.kornitPrinterList)
                    .setTitle('Drucker')
                    .setUrl('/' + APP_PATH.kornitPrinters)
            )
            .addItem(
                new MenuItem()
                    .setACLRoute(API_ROUTES.kornitMotiveSettingsList)
                    .setTitle('Kornit settings')
                    .setUrl('/' + APP_PATH.kornitSettings)
            )
    ];

    constructor(private translate: TranslateService,
                private store$: Store<AppState>,
                private permissionService: PermissionService) {
    }

    ngOnInit() {
        const url = this.getSavedActiveItemUrl() === window.location.pathname && this.getSavedActiveItemUrl() !== null
            ? this.getSavedActiveItemUrl()
            : window.location.pathname;

        this.activateItemByUrl(url, this.menuItems);

        this.headerMenu$ = this.store$.pipe(select(fromHeaderMenu.getTitle));
    }

    activateItemByUrl(url: string, items: Array<MenuItem>) {
        if (!url) {
            return;
        }

        for (const item of items) {
            item.deactivate();

            if (url.startsWith(item.url)) {
                this.activeMenuItem = item.parent ? item.parent : item;
                this.activeMenuItem.activate();
                item.activate();

                this.setHeaderMenuTitle(this.activeMenuItem.title);

                continue;
            }

            if (item.items.length) {
                this.activateItemByUrl(url, item.items);
            }
        }
    }

    activateItem(menuItem: MenuItem, drawerSecondLevel) {
        if (this.activeMenuItem) {
            this.activeMenuItem.deactivate();
        }
        if (menuItem.items.length) {
            this.store$.dispatch({type: MENU_SLIDE_OUT});
            drawerSecondLevel.open();
        } else {
            this.store$.dispatch({type: MENU_SLIDE_IN});
            if (this.activeMenuItem) {
                this.deactivateChildItems(this.activeMenuItem.items);
                drawerSecondLevel.close();
            }
        }

        this.activeMenuItem = menuItem;
        this.activeMenuItem.activate();

        this.setHeaderMenuTitle(menuItem.title);
    }

    deactivateChildItems(items: Array<MenuItem>) {
        if (items.length) {
            for (const item of items) {
                item.deactivate();
            }

            this.removeActiveItem();
        }
    }

    saveActiveItem(menuItem: MenuItem) {
        localStorage.setItem(SidebarComponent.LOCAL_STORAGE_KEY, menuItem.url);
        this.activateItemByUrl(this.getSavedActiveItemUrl(), this.menuItems);
    }

    removeActiveItem() {
        localStorage.removeItem(SidebarComponent.LOCAL_STORAGE_KEY);
    }

    getSavedActiveItemUrl() {
        return localStorage.getItem(SidebarComponent.LOCAL_STORAGE_KEY);
    }

    hideSecondLevelSidebar(drawerSecondLevel) {
        drawerSecondLevel.toggle();
        this.store$.dispatch({type: MENU_SLIDE_IN});
    }

    isPermitted(menuItem: MenuItem) {
        if (menuItem.getAclRoute() === undefined) {
            if (menuItem.hasItems()) {
                return menuItem.getItems().some((subItem: MenuItem) => {
                    return this.permissionService.isPermitted(subItem.getAclRoute());
                })
            }

            return false;
        }

        return this.permissionService.isPermitted(menuItem.getAclRoute());
    }

    setHeaderMenuTitle(title: string) {
        this.store$.dispatch(new fromHeaderMenuActions.SetTitle(title));
    }
}
