import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DynamicFormsCoreModule} from '@ng-dynamic-forms/core';
import {DynamicFormsMaterialUIModule} from '@ng-dynamic-forms/ui-material';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {NgxMatFileInputModule} from '@angular-material-components/file-input';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {ListFilterComponent} from './list-filter.component';
import {FilterRightPanelComponent} from './filter-right-panel/filter-right-panel.component';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {crudReducer} from '../../../store/crud/crud.reducer';
import {CrudEffects} from '../../../store/crud/crud.effects';
import {listFilterReducer} from '../../../store/list-filter/list-filter.reducer';
import {FilterStatusPanelComponent} from './filter-status-panel/filter-status-panel.component';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {ListFilterService} from './list-filter.service';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatBadgeModule} from '@angular/material/badge';
import {MatDividerModule} from '@angular/material/divider';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {SharedPipesModule} from '@shared/pipes/shared-pipes.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DynamicFormsCoreModule.forRoot(),
        DynamicFormsMaterialUIModule,
        NgxSliderModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatChipsModule,
        MatInputModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatTooltipModule,
        NgxMatFileInputModule,
        MatBadgeModule,
        MatDividerModule,
        StoreModule.forFeature('crud', crudReducer),
        EffectsModule.forFeature([CrudEffects]),
        StoreModule.forFeature('list-filter', listFilterReducer),
        SharedPipesModule,
    ],
    exports:      [
        CommonModule,
        TranslateModule,
        ListFilterComponent,
        FilterRightPanelComponent,
        FilterStatusPanelComponent,
    ],
    declarations: [
        ListFilterComponent,
        FilterRightPanelComponent,
        FilterStatusPanelComponent,
    ],
    providers:    [
        ListFilterService
    ],
})
export class ListFilterModule {
}
