import {DynamicSelectModel} from '@ng-dynamic-forms/core';
import {ValidatorsBuilder} from '../validators/validators-builder';
import {FormBuilder} from '../form-builder';

export class SelectControl {
    field: any;
    formBuilder: FormBuilder;

    constructor(formBuilder: FormBuilder, field: any) {
        this.field       = field;
        this.formBuilder = formBuilder;
    }

    build() {
        let validatorBuilder = (new ValidatorsBuilder(this.field.constraints, this.formBuilder)).build();
        let options          = this.field['choices'] ? this.field['choices'] : {};

        let selectOptions = [];
        let values        = Object.keys(options);
        for (let optionValue of values) {
            selectOptions.push({
                value: options[optionValue],
                label: optionValue,
            });
        }
        this.addNoneOption(selectOptions);

        let selected = this.getSelected();

        for (const selectOption in selectOptions) {
            if (selectOptions[selectOption]['value'] === selected
                || (selected instanceof Object
                    && (selectOptions[selectOption]['value'] === selected['value']
                        || selectOptions[selectOption]['value'] === selected['id'])
                )
            ) {
                selected = [selectOption] || (values.length ? [0] : null);
            }
        }

        let model = new DynamicSelectModel({
            id:              this.field.name,
            label:           this.field.label,
            hint:            this.field.help,
            multiple:        false,
            filterable:      true,
            disabled:        this.field.attributes?.disabled,
            options:         selectOptions,
            value:           this.field.data,
            validators:      validatorBuilder.getValidators(),
            asyncValidators: validatorBuilder.getAsyncValidators(),
            errorMessages:   validatorBuilder.getErrorMessages()
        });

        if (selected) {
            const selectedOption = selectOptions.find((item) => item['value'] === selected);

            if (selected && selectedOption) {
                model.select.apply(model, [selected]);
            } else if (selected && selectOptions[selected]) {
                model.select.apply(model, [selected]);
            }
        }

        return model;
    }

    private getSelected() {
        return this.formBuilder.modelData[this.field.name] ?? this.field.data ?? null;
    }

    private addNoneOption(selectOptions: Array<object>) {
        if (selectOptions.length === 0 || this.field.attributes?.skipNone) {
            return;
        }

        selectOptions.unshift({value: null, label: 'None'});
    }
}
