import {Injectable} from '@angular/core';

import {SelectFilter as BaseSelectFilter} from '../../../filter-main-panel/filters/select/select.filter';

@Injectable()
export class SelectFilter extends BaseSelectFilter {

    protected initMainModel(): void {
    }
}

