import {HeaderMenuState} from './header-menu.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromRoot from '../../../../app/app.module';

export interface State extends fromRoot.State {
    headerMenu: HeaderMenuState
}

const getCrudFeatureState = createFeatureSelector<State>('layout');

export const getTitle = createSelector(
    getCrudFeatureState,
    state => state.headerMenu.title
);
