import * as moment from 'moment';
import {Filter} from '../filter';
import {ListFilterService} from '../../../list-filter.service';
import {Store} from '@ngrx/store';
import * as fromCrud from '../../../../../../store/list-filter';
import {ListFilter} from '../../../models/filter';
import {FilterPresentationInterface} from '../filter.presentation';
import {
    DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER,
    DYNAMIC_FORM_CONTROL_TYPE_INPUT,
    DynamicFormOption,
    DynamicInputModel,
    DynamicSelectModel,
    MATCH_VISIBLE,
} from '@ng-dynamic-forms/core';
import * as listFilterActions from '../../../../../../store/list-filter/list-filter.actions';
import {FilterStore} from '../../../models/filter-store';
import {Injectable} from "@angular/core";

const CUSTOM_DATE_PERIOD = 'custom';

@Injectable()
export class DatePeriodFilter extends Filter {
    static readonly modelType = 'date_period';
    readonly modelType: string = DatePeriodFilter.modelType;

    protected datePeriodFrom: DynamicSelectModel<any> | any;
    protected datePeriodTo: DynamicSelectModel<any> | any;
    protected customDatePeriodFrom: DynamicInputModel | any;
    protected customDatePeriodTo: DynamicInputModel | any;
    protected errorDiv: HTMLDivElement;

    constructor(
        listFilterService: ListFilterService,
        store$: Store<fromCrud.State>,
        filter: ListFilter,
        filterPresentation: FilterPresentationInterface,
    ) {
        super(listFilterService, store$, filter, filterPresentation, DatePeriodFilter.modelType);

        this.createErrorDiv();
    }

    createErrorDiv() {
        setTimeout(() => {
            this.errorDiv = document.createElement('div');
            this.errorDiv.className = 'popup-errors';

            const periodFrom = document.querySelector(`#${this.filter.field}-field-period-from`);
            if (periodFrom) {
                periodFrom.closest('dynamic-material-form-control').before(this.errorDiv);
            }
        }, 0);
    }

    getModels(): any[] {
        const models = [this.getDatePeriodFromModel(), this.getCustomDatePeriodFromModel(), this.getDatePeriodToModel(), this.getCustomDatePeriodToModel()];
        this.applyFilter(this.getPeriods(), false);

        return models;
    }

    public accept(emitChanges = true): void {
        if (!this.isPeriodValid()) {
            return;
        }

        const period = this.getPeriods();
        this.applyFilter(period, emitChanges);
        this.updateMainModelValue(this.getTextPeriodRange(period));
    }

    public setValue(value: {}): void {
        this.datePeriodFrom.value = value['datePeriodFrom'];
        this.datePeriodTo.value = value['datePeriodTo'];
        this.customDatePeriodFrom.value = value['customDatePeriodFrom'];
        this.customDatePeriodTo.value = value['customDatePeriodTo'];
    }

    public getValue(): {} {
        return {
            'datePeriodFrom': this.datePeriodFrom.value,
            'datePeriodTo': this.datePeriodTo.value,
            'customDatePeriodFrom': this.customDatePeriodFrom.value,
            'customDatePeriodTo': this.customDatePeriodTo.value,
        }
    }

    protected applyFilter(period: any[], emitChanges = true): void {
        const filterData: any = {},
            [periodFrom, periodTo] = period;

        if (!periodFrom && !periodTo) {
            return this.store$.dispatch(new listFilterActions.RemoveFilter(this.id));
        }

        if (!this.filter.inBothPresentations) {
            filterData[this.filter.getField()] = this.getFilterData(periodFrom, periodTo);
            this.store$.dispatch(new listFilterActions.LoadData(filterData));

            const filterModel = {[this.id]: new FilterStore(this, this.getTextPeriodRange(period))};
            this.store$.dispatch(new listFilterActions.AddFilter(filterModel));
        }

        if (emitChanges) {
            this.comparisonModel.value = this.getComparisonValue(periodFrom, periodTo);
        }

        if (emitChanges) {
            this.listFilterService.getFiltersChangeEmitter().emit();
        }
    }

    protected reset(): void {
        super.reset();

        this.datePeriodFrom.value = null;
        this.datePeriodTo.value   = null;
    }

    protected initMainModel(): void {
        this.mainModel = new DynamicInputModel({
            id:          this.filter.field,
            label:       this.filter.label,
            placeholder: this.filter.label,
            inputType:   DYNAMIC_FORM_CONTROL_TYPE_INPUT,
            suffix:      this.mainModelSuffix,
            disabled:    true,
        });
    }

    protected getDatePeriodFromModel(): DynamicSelectModel<any> {
        if (this.datePeriodFrom) {
            return this.datePeriodFrom;
        }

        this.datePeriodFrom = new DynamicSelectModel({
            id:       `${this.filter.field}-field-period-from`,
            label:    `${this.filter.label} Von`,
            multiple: false,
            options: this.filter.options,
            value: this.getInitialDatePeriodValue('gte'),
        });

        return this.datePeriodFrom;
    }

    protected getDatePeriodToModel(): DynamicSelectModel<any> {
        if (this.datePeriodTo) {
            return this.datePeriodTo;
        }

        this.datePeriodTo = new DynamicSelectModel(
            {
                id:       `${this.filter.field}-field-period-to`,
                label:    `${this.filter.label} Bis`,
                multiple: false,
                options: this.filter.options,
                value: this.getInitialDatePeriodValue('lte')
            },
            {
                grid: {
                    control: 'margin-left',
                }
            });

        return this.datePeriodTo;
    }

    protected getCustomDatePeriodFromModel(): DynamicInputModel {
        if (this.customDatePeriodFrom) {
            return this.customDatePeriodFrom;
        }

        const value = this.getInitialCustomDatePeriodValue('gte');

        return this.customDatePeriodFrom = new DynamicInputModel(
            {
                id: `${this.filter.field}-field-custom-period-from`,
                label: `Benutzerdefiniert von`,
                value: value,
                placeholder: '123',
                maxLength: 3,
                prefix: '-',
                suffix: 'tage',
                inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER,
                validators: {
                    maxLength: 3,
                    max: 999,
                    min: 1,
                },
                errorMessages: {
                    max: 'Die maximale Anzahl darf weniger als 1000 betragen'
                },
                relations: [
                    {
                        match: MATCH_VISIBLE,
                        when: [
                            {
                                id: this.datePeriodFrom.id,
                                value: 'custom'
                            }
                        ],
                    }
                ],
            },
            {
                grid: {
                    control: 'margin-left',
                }
            });
    }

    protected getCustomDatePeriodToModel(): DynamicInputModel {
        if (this.customDatePeriodTo) {
            return this.customDatePeriodTo;
        }

        const value = this.getInitialCustomDatePeriodValue('lte');

        return this.customDatePeriodTo = new DynamicInputModel(
            {
                id: `${this.filter.field}-field-custom-period-to`,
                label: `Benutzerdefiniert bis`,
                value: value,
                placeholder: '123',
                maxLength: 3,
                prefix: '-',
                suffix: 'tage',
                inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER,
                validators: {
                    maxLength: 3,
                    max: 999,
                    min: 1,
                },
                errorMessages: {
                    max: 'Die maximale Anzahl darf weniger als 1000 betragen'
                },
                relations: [
                    {
                        match: MATCH_VISIBLE,
                        when: [
                            {
                                id: this.datePeriodTo.id,
                                value: 'custom'
                            }
                        ],
                    }
                ],
            },
            {
                grid: {
                    control: 'margin-left',
                }
            }
        );
    }

    protected initComparisonModel(config: {} = {}): void {
        super.initComparisonModel({hidden: true});

        this.comparisonModel.add(new DynamicFormOption({
            disabled: true,
            label:    ' ',
            value:    ''
        }));

        const comparison = this.getComparisonValue(this.getInitialDatePeriodValue('gte'), this.getInitialDatePeriodValue('lte'));
        this.comparisonModel.value = comparison;
    }

    private getComparisonValue(datePeriodFrom, datePeriodTo) {
        let comparisonValue = '';

        if (datePeriodFrom && datePeriodTo) {
            return comparisonValue;
        } else if (datePeriodFrom) {
            comparisonValue = 'gte';
        } else if (datePeriodTo) {
            comparisonValue = 'lte';
        }

        return comparisonValue;
    }

    protected getInitialDatePeriodValue(comparison: string): string | null {
        return this.filter.initialValue && this.filter.initialValue[comparison]
            ? (this.filter.optionValues[this.filter.initialValue[comparison]]
                ? this.filter.initialValue[comparison]
                : CUSTOM_DATE_PERIOD
            )
            : null;
    }

    protected getInitialCustomDatePeriodValue(comparison: string): string | null {
        return this.filter.initialValue && this.filter.initialValue[comparison] && !this.filter.optionValues[this.filter.initialValue[comparison]]
            ? this.filter.initialValue[comparison].replace(/\D/g, '')
            : null
    }

    private getPeriods() {
        const periodFrom = this.datePeriodFrom.value ? this.getFilteredPeriod(this.datePeriodFrom.value, this.customDatePeriodFrom.value) : null
        const periodTo = this.datePeriodTo.value ? this.getFilteredPeriod(this.datePeriodTo.value, this.customDatePeriodTo.value) : null

        return [periodFrom, periodTo];
    }

    private getFilterData(periodFrom?: string, periodTo?: string) {
        const filterData = {};
        if (periodFrom !== null) {
            filterData['gte'] = this.getFilteredPeriod(this.datePeriodFrom.value, this.customDatePeriodFrom.value);
        }
        if (periodTo !== null) {
            filterData['lte'] = this.getFilteredPeriod(this.datePeriodTo.value, this.customDatePeriodTo.value);
        }

        return filterData;
    }

    private getFilteredPeriod(periodValue: string, customPeriodValue?: string) {
        if (periodValue === CUSTOM_DATE_PERIOD) {
            return `-${customPeriodValue} days`;
        }

        return periodValue;
    }

    private isPeriodValid(): boolean {
        if (!this.datePeriodFrom.value || !this.datePeriodTo.value) {
            return true;
        }

        const datePeriodFromParsedValue = this.getDatePeriodParsedValue(this.datePeriodFrom.value, this.customDatePeriodFrom.value);
        const datePeriodToParsedValue   = this.getDatePeriodParsedValue(this.datePeriodTo.value, this.customDatePeriodTo.value);

        if (moment(datePeriodToParsedValue).isBefore(datePeriodFromParsedValue)) {
            this.errorDiv.innerText = 'Invalid period';
            const popup = this.errorDiv.closest('[id$="-popup-group"]');

            if (popup) {
                popup.classList.add('show');
            }

            return false;
        }

        this.errorDiv.innerText = '';
        return true;
    }

    private getDatePeriodParsedValue(periodValue, customPeriodValue) {
        if (periodValue === CUSTOM_DATE_PERIOD) {
            return moment().subtract(customPeriodValue, 'day');
        }

        return this.filter.optionParsedValues[periodValue];
    }

    private showCustomDateInput(model: DynamicInputModel, value: string): void {
        if (value === CUSTOM_DATE_PERIOD) {
            model.hidden = false;
            return;
        }

        model.hidden = true;
    }

    protected getTextPeriodRange(period: any[]) {
        let [periodFrom, periodTo] = period;

        periodFrom = this.getTextPeriod(periodFrom);
        periodTo = this.getTextPeriod(periodTo);

        return periodFrom && periodTo
            ? periodFrom + ' - ' + periodTo
            : periodFrom || periodTo;
    }

    protected getTextPeriod(period?: string) {
        return period
            ? this.filter.optionValues[period]
                ? this.filter.optionValues[period]
                : period.replace('days', 'Tage')
            : null;
    }

    protected getInitialValue(): any[] {
        const values = this.filter.initialValue ? Object.values(this.filter.initialValue) : [];
        return values.filter((value) => value !== 'null');
    }

    protected setInitialValue(): void {
        const initialValue = this.getInitialValue() as [string?, string?];

        if (initialValue.length > 0) {
            this.updateMainModelValue(this.getTextPeriodRange(initialValue));
        }
    }

}
