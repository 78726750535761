import {Injectable} from '@angular/core';
import {DYNAMIC_FORM_CONTROL_TYPE_INPUT, DynamicInputModel} from '@ng-dynamic-forms/core';
import {DYNAMIC_FORM_CONTROL_TYPE_SLIDER_RANGE, DynamicSliderRangeModel} from '../../form/model';
import {Store} from '@ngrx/store';
import * as fromCrud from '../../../../../../store/list-filter';
import {ListFilter} from '../../../models/filter';
import {FilterPresentationInterface} from '../filter.presentation';
import {ListFilterService} from '../../../list-filter.service';
import {NumberRangeFilter} from './number-range.filter';

@Injectable()
export class NumberRangeSliderFilter extends NumberRangeFilter {
    protected numberRangeSliderModel: DynamicSliderRangeModel;

    constructor(
        listFilterService: ListFilterService,
        store$: Store<fromCrud.State>,
        filter: ListFilter,
        filterPresentation: FilterPresentationInterface,
    ) {
        super(listFilterService, store$, filter, filterPresentation);
    }

    getModels(): any[] {
        const models = [this.getNumberRangeSliderModel()];

        this.applyFilter(this.getFromToNumbers(), false);

        return models;
    }

    protected initMainModel(): void {
        this.mainModel = new DynamicInputModel({
            id: this.filter.field,
            label: this.filter.label,
            placeholder: this.filter.label,
            inputType: DYNAMIC_FORM_CONTROL_TYPE_INPUT,
            suffix: this.mainModelSuffix,
            disabled: true,
        });
    }

    public setValue(value: {}): void {
        this.numberRangeSliderModel.fromValue = value['numberRangeSliderModelFrom'];
        this.numberRangeSliderModel.toValue = value['numberRangeSliderModelTo'];
    }

    public getValue(): {} {
        return {
            'numberRangeSliderModelFrom' : this.numberRangeSliderModel.fromValue,
            'numberRangeSliderModelTo' : this.numberRangeSliderModel.toValue
        };
    }

    protected reset(): void {
        super.reset();

        this.numberRangeSliderModel.value = null;
    }

    protected getNumberRangeSliderModel(): DynamicSliderRangeModel {
        if (this.numberRangeSliderModel) {
            return this.numberRangeSliderModel;
        }

        const fromValue = this.getInitialNumberFromValue() || this.filter.settings.min;
        const toValue = this.getInitialNumberToValue() || this.filter.settings.max;

        return this.numberRangeSliderModel = new DynamicSliderRangeModel({
            id: `${this.filter.field}-slider-range`,
            min: this.filter.settings.min,
            max: this.filter.settings.max,
            fromValue: fromValue,
            toValue: toValue,
            logScale: this.filter.settings.logScale,
        }, undefined, DYNAMIC_FORM_CONTROL_TYPE_SLIDER_RANGE);
    }

    protected getFromToNumbers(): [number?, number?] {
        let numberFrom,
            numberTo;

        if (this.numberRangeSliderModel.value) {
            [numberFrom, numberTo] = String(this.numberRangeSliderModel.value).split('|').map(Number);

            numberFrom = numberFrom !== this.filter.settings.min ? numberFrom : null;
            numberTo = numberTo !== this.filter.settings.max ? numberTo : null;

            return [numberFrom, numberTo];
        }

        return [null, null];
    }
}
