import {Injectable} from '@angular/core';

import {NumberFilter as BaseNumberFilter} from '../../../filter-main-panel/filters/number/number.filter';

@Injectable()
export class NumberFilter extends BaseNumberFilter {

    protected initMainModel(): void {
    }

    protected useSettingsValue() {
        return false;
    }
}
