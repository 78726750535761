import {DynamicFormControlLayout, DynamicInputModel, DynamicInputModelConfig} from '@ng-dynamic-forms/core';

export const DYNAMIC_FORM_CONTROL_TYPE_INPUT_AUTOCOMPLETE_SINGLE_WITH_REQUEST = 'INPUT_AUTOCOMPLETE_SINGLE_WITH_REQUEST';

export class DynamicInputAutocompleteSingleWithRequestModel extends DynamicInputModel {
    model: DynamicInputAutocompleteSingleWithRequestModel;

    type: string                    = DYNAMIC_FORM_CONTROL_TYPE_INPUT_AUTOCOMPLETE_SINGLE_WITH_REQUEST;
    checkAllOptionsValue            = 'None';
    values: { number: string } | {} = {};

    set list(list: Array<any>) {
        super.list = list;

        this.setPredefinedList(list);
    }

    get list() {
        return super.list as Array<any>;
    }

    constructor(config: DynamicInputModelConfig, layout?: DynamicFormControlLayout, type?: string, values?: any) {
        super(config, layout);

        this.model  = this;
        this.type   = type || DYNAMIC_FORM_CONTROL_TYPE_INPUT_AUTOCOMPLETE_SINGLE_WITH_REQUEST;
        this.value  = config.value || null;
        this.values = values;
    }

    public closed(inputElement: HTMLInputElement) {
        this.resetInputElementValue(inputElement);
        this.list = [];
    }

    optionClicked(event, option, inputElement: HTMLInputElement, control, changeCallback) {
        event.stopPropagation();
        this.toggleSelection(option, inputElement);
        changeCallback(control);
    }

    public toggleSelection(option, inputElement: HTMLInputElement) {
        this.value = option;
        this.resetInputElementValue(inputElement);
    }

    public removeOption(option) {
        this.value = null;
    }

    public triggerAcceptButton(acceptButtonId) {
        document.getElementById(acceptButtonId).click();
    }

    private setPredefinedList(list: any[]) {
        if (list.length) {
            return;
        }

        const predefinedList = this.getAdditional('predefinedList', []);
        if (!predefinedList.length) {
            return;
        }

        super.list = predefinedList;
    }

    private resetInputElementValue(inputElement: HTMLInputElement) {
        inputElement.value = ''
    }
}
