<section class="filters-pannel-overlay slide-pannel-overlay" [class.slide-in]="show === true"
         [class.slide-out]="show === false">
    <div class="right-side">
        <div class="header-text">
            <mat-icon (click)="onClose()">arrow_back</mat-icon>
            {{ title }}
        </div>
        <div class="content">
            <div class="sub-header-text">{{introText}}</div>

            <form *ngIf="formGroup" [formGroup]="formGroup" class="filters-right-pannel-form">
                <dynamic-material-form [group]="formGroup"
                                       [model]="formModel"
                                       (focus)="onFocus($event)"
                >
                    <ng-template modelType="SLIDER_RANGE"
                                 let-model="model"
                                 let-fromValue="fromValue"
                                 let-toValue="toValue"
                                 let-options="options"
                                 let-sliderValueChange="sliderValueChange">
                        <div class="slider">
                            <ngx-slider [value]="fromValue"
                                        [highValue]="toValue"
                                        [options]="options"
                                        (userChange)="sliderValueChange.call(model, $event)">
                            </ngx-slider>
                        </div>
                    </ng-template>

                    <ng-template modelType="INPUT_AUTOCOMPLETE_MULTIPLE_WITH_SETTINGS"
                                 let-model="model"
                                 let-options="list$"
                                 let-class="id"
                                 let-closed="closed"
                                 let-selectedOptions="value"
                                 let-removeOption="removeOption"
                                 let-optionClicked="optionClicked"
                                 let-toggleSelection="toggleSelection"
                                 let-placeholder="placeholder">
                        <mat-form-field [ngClass]="class">
                            <mat-chip-list #chipList>
                                <mat-chip
                                    *ngFor="let selectedOption of ((selectedOptions | typeof) === 'string' ? [] : selectedOptions)"
                                    [selectable]="true"
                                    [removable]="true"
                                    (removed)="removeOption.call(model, selectedOption)"
                                >
                                    {{selectedOption}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input type="text" class="mat-input-element"
                                       #inputElement
                                       matInput
                                       [value]="!model.multiple ? model.value : ''"
                                       [matAutocomplete]="auto"
                                       [matChipInputFor]="chipList"
                                       [placeholder]="placeholder">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (closed)="closed.call(model, inputElement)">
                                <mat-option *ngFor="let option of options | async" [value]="option">
                                    <div class="mat-option-div"
                                         (click)="optionClicked.call(model, $event, ref, option, inputElement)">
                                        <mat-checkbox #ref (change)="toggleSelection.call(model, option, inputElement)"
                                                      (click)="$event.stopPropagation()">
                                            {{ option }}
                                        </mat-checkbox>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </ng-template>

                </dynamic-material-form>
                <button mat-raised-button mat color="primary" class="submit-button"
                        (click)="onSubmit()"
                >Filter setzen
                </button>
            </form>

        </div>
    </div>
    <div class="logo">
        <img src="/assets/images/logo-white.svg">
    </div>
</section>
