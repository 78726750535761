<div class="header-menu-title">
    <h2 *ngIf="headerMenu$ | async as headerMenuTitle">
        {{ headerMenuTitle }}
    </h2>
</div>

<nav class="sidebar">
    <ul class="list-group">
        <ng-container *ngFor="let item of menuItems">
            <ng-container *ngIf="isPermitted(item)">
                <div class="nested-menu" [ngClass]="{active: item.active}">
                    <a class="list-group-item" title="{{item.title}}" [ngClass]="{active: item.active}" [routerLink]="item.url"
                       (click)="activateItem(item, drawerSecondLevel)">
                        <ng-container *ngIf="item.customIcon" >
                            <img src="/assets/images/sidebar/{{item.customIcon}}" class="custom-icon" alt="">
                        </ng-container>
                        <ng-container *ngIf="!item.customIcon">
                            <em *ngIf="item.iconClass" [ngClass]="item.iconClass"></em>
                            <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon>
                        </ng-container>
                    </a>
                </div>
            </ng-container>
        </ng-container>
    </ul>
</nav>

<mat-drawer-container class="second-level-sidebar">
    <mat-drawer #drawerSecondLevel class="sidebar-second-level" [mode]="'side'" position="start">
        <div class="close-button" (click)="hideSecondLevelSidebar(drawerSecondLevel)">
            <mat-icon>clear</mat-icon>
        </div>

        <ng-container *ngFor="let item of menuItems">
            <ng-container *ngIf="item.items.length">
                <ul class="submenu" [ngClass]="{active: item.active}">
                    <ng-container *ngFor="let childItem of item.items">
                        <li *ngIf="isPermitted(childItem)" [ngClass]="{active: childItem.active}">
                            <a [routerLink]="childItem.url" [routerLinkActive]="['router-link-active']"
                               (click)="saveActiveItem(childItem)"
                               class="list-group-item">
                                {{childItem.title}}
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </ng-container>
        </ng-container>
    </mat-drawer>
</mat-drawer-container>
