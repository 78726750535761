import {Action} from '@ngrx/store';
import {Crud, CrudData, Filter, Statistics} from '@shared/components/crud/crud.interface';

export enum CrudActionTypes {
    ClearData = '[CRUD] Clear data',
    ClearDataSuccess = '[CRUD] Clear data success',
    LoadData = '[CRUD] Load data',
    LoadDataSuccess = '[CRUD] Load data success',
    CreateItem = '[CRUD] Create item',
    CreateItemSuccess = '[CRUD] Create item success',
    UpdateItem = '[CRUD] Update item',
    UpdateItemSuccess = '[CRUD] Update item success',
    DeleteItem = '[CRUD] Delete item',
    DeleteItemSuccess = '[CRUD] Delete item success',
    HandleError = '[CRUD] Handle error',
    HandleErrorSuccess = '[CRUD] Handle error success',
    UpdateStatistics = '[CRUD] Update statistics',
    UpdateStatisticsSuccess = '[CRUD] Update statistics success',
    UpdateFilters = '[CRUD] Update filters',
    UpdateFiltersSuccess = '[CRUD] Update filters success',
}

export class ClearData implements Action {
    readonly type = CrudActionTypes.ClearData;
}

export class ClearDataSuccess implements Action {
    readonly type = CrudActionTypes.ClearDataSuccess;
}

export class LoadData implements Action {
    readonly type = CrudActionTypes.LoadData;

    constructor(public payload: CrudData<Crud>) { }
}

export class LoadDataSuccess implements Action {
    readonly type = CrudActionTypes.LoadDataSuccess;

    constructor(public payload: CrudData<Crud>) { }
}

export class CreateItem implements Action {
    readonly type = CrudActionTypes.CreateItem;

    constructor(public payload: Crud) { }
}

export class CreateItemSuccess implements Action {
    readonly type = CrudActionTypes.CreateItemSuccess;

    constructor(public payload: Crud) { }
}

export class UpdateItem implements Action {
    readonly type = CrudActionTypes.UpdateItem;

    constructor(public payload: Crud) { }
}

export class UpdateItemSuccess implements Action {
    readonly type = CrudActionTypes.UpdateItemSuccess;

    constructor(public payload: Crud) { }
}

export class DeleteItem implements Action {
    readonly type = CrudActionTypes.DeleteItem;

    constructor(public payload: number) { }
}

export class DeleteItemSuccess implements Action {
    readonly type = CrudActionTypes.DeleteItemSuccess;

    constructor(public payload: number) { }
}

export class HandleError implements Action {
    readonly type = CrudActionTypes.HandleError;

    constructor(public payload: object) { }
}

export class HandleErrorSuccess implements Action {
    readonly type = CrudActionTypes.HandleErrorSuccess;

    constructor(public payload: string) { }
}

export class UpdateStatistics implements Action {
    readonly type = CrudActionTypes.UpdateStatistics;

    constructor(public payload: Array<Statistics>) { }
}

export class UpdateStatisticsSuccess implements Action {
    readonly type = CrudActionTypes.UpdateStatisticsSuccess;

    constructor(public payload: Array<Statistics>) { }
}

export class UpdateFilters implements Action {
    readonly type = CrudActionTypes.UpdateFilters;

    constructor(public payload: {availableFilters: Array<Filter>, usedFilters: {}}) { }
}

export class UpdateFiltersSuccess implements Action {
    readonly type = CrudActionTypes.UpdateFiltersSuccess;

    constructor(public payload: {availableFilters: Array<Filter>, usedFilters: {}}) { }
}

export type CrudActions = LoadData
    | LoadDataSuccess
    | CreateItem
    | CreateItemSuccess
    | UpdateItem
    | UpdateItemSuccess
    | DeleteItem
    | DeleteItemSuccess
    | HandleError
    | HandleErrorSuccess
    | ClearData
    | ClearDataSuccess
    | UpdateStatistics
    | UpdateStatisticsSuccess
    | UpdateFilters
    | UpdateFiltersSuccess;
