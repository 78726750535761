import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {select, Store} from '@ngrx/store';

import * as fromListFilter from '../../../../store/list-filter/index';
import * as listFilterActions from '../../../../store/list-filter/list-filter.actions';
import {FilterStore} from '../models/filter-store';
import {ListFilterService} from '../list-filter.service';
import {Subscription} from 'rxjs';
import {intersection} from 'lodash';

@Component({
    selector: 'filter-status-panel',
    templateUrl: './filter-status-panel.component.html',
    styleUrls: ['./filter-status-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FilterStatusPanelComponent implements OnInit, OnDestroy {
    @Input() filterGroups: Array<string>;

    filtersValues: any[] = [];
    storeFiltersValues$: any;
    storeFiltersValuesSubscription: Subscription;

    constructor(private listFilterService: ListFilterService, private store: Store<any>) {
        this.storeFiltersValues$ = this.store.pipe(select(fromListFilter.getFiltersValues));
    }

    ngOnInit(): void {
        this.storeFiltersValuesSubscription = this.storeFiltersValues$
            .subscribe((values) => {
                this.filtersValues = values
                    ? Object.values(values).filter((item: FilterStore) => intersection(item.groups, this.filterGroups).length)
                    : []
            });
    }

    ngOnDestroy() {
        this.storeFiltersValuesSubscription.unsubscribe();
    }

    protected removeValue(filter: FilterStore): void {
        this.store.dispatch(new listFilterActions.RemoveFilter(filter.id));
        this.listFilterService.getFiltersChangeEmitter().emit();
    }

    protected onClick(filter: FilterStore): void {
        this.showFilterSelection(filter);
    }

    public getFilterValue(filter: FilterStore): string {
        return filter.value in filter.optionValues ? filter.optionValues[filter.value] : filter.value;
    }

    private showFilterSelection(filter: FilterStore): void {
        const field = document.querySelector(`#${filter.field}`);

        if (!field) {
            const rightPanel = document.querySelector('.filter-panel-button') as HTMLElement;
            rightPanel.click();

            return;
        }

        const fieldContainer = field.closest('dynamic-material-form-control') as HTMLElement;
        fieldContainer.click();
    }
}
