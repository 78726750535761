import {Injectable} from '@angular/core';

@Injectable()
export class LocalStorageService {
    private static get localStorage(): Storage {
        return window.localStorage;
    }

    static get(key: string): string|null {
        return JSON.parse(this.localStorage.getItem(key));
    }

    static set<T>(key: string, value: T): void {
        this.localStorage.setItem(key, JSON.stringify(value));
    }

    static remove(key: string): void {
        this.localStorage.removeItem(key);
    }

    static setDataForSpecificUrl<T>(key: string, value: T) {
        let data                       = LocalStorageService.get(key) ?? {};
        data                           = typeof data === 'object' ? data : {};
        data[window.location.pathname] = value;

        LocalStorageService.set(key, data);
    }

    static getDataForSpecificUrl(key: string, defaultValue = null) {
        return LocalStorageService.get(key)?.[window.location.pathname] ?? defaultValue;
    }
}
