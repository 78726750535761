import {Injectable} from '@angular/core';

import {NumberRangeSliderFilter as BaseNumberRangeSliderFilter} from '../../../filter-main-panel/filters/number-range/number-range-slider.filter';
import {DynamicSliderRangeModel} from '../../../filter-main-panel/form/model';

@Injectable()
export class NumberRangeSliderFilter extends BaseNumberRangeSliderFilter {

    protected initMainModel(): void {
    }

    protected getNumberRangeSliderModel(): DynamicSliderRangeModel {
        const numberRangeSliderModel = super.getNumberRangeSliderModel();
        numberRangeSliderModel.label = this.filter.label;

        return numberRangeSliderModel;
    }
}
