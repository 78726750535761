import {DynamicFormGroupModel} from '@ng-dynamic-forms/core';

import {Filter} from './filter';

export interface FilterPresentationInterface {

    setFilter(filter: Filter): void;

    getFormGroupModel(): DynamicFormGroupModel;

    updateMainModelValue(value: any, prefix: string): void;

}

export abstract class FilterPresentation implements FilterPresentationInterface {
    protected filter: Filter;

    public abstract getFormGroupModel(): DynamicFormGroupModel;

    public abstract updateMainModelValue(value: any, prefix: string): void;

    public setFilter(filter: Filter): void {
        this.filter = filter;
    }
}
