import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {Observable, catchError, tap, throwError} from 'rxjs';
import {NotificationService} from '@shared/services/notification.service';
import {HIDE_GLOBAL_PROGRESS_BAR} from '../store/layout/progress-bar/progress-bar';
import {Store} from '@ngrx/store';
import {ErrorResponse, SuccessResponse} from '@shared/components/response';

@Injectable()
export class NotifyInterceptor implements HttpInterceptor {
    constructor(public notificationService: NotificationService,
                private store$: Store<any>) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                this.processHttpSuccessResponse(event);
            }),
            catchError((httpErrorResponse: HttpErrorResponse) => {
                if (httpErrorResponse.status !== 401) {
                    this.processHttpErrorResponse(httpErrorResponse);
                    this.store$.dispatch({type: HIDE_GLOBAL_PROGRESS_BAR});
                }

                return throwError(httpErrorResponse.error);
            })
        );
    }

    private processHttpSuccessResponse(event: HttpEvent<any>) {
        if (event instanceof HttpResponse && event.body.messages) {
            const successResponse = new SuccessResponse(event.body);

            this.notificationService.processMessages(successResponse.messages)
        }
    }

    private processHttpErrorResponse(httpErrorResponse: HttpErrorResponse) {
        if (!httpErrorResponse.error) {
            return;
        }

        const errorResponse = new ErrorResponse(httpErrorResponse.error);
        if (!errorResponse.messages) {
            return;
        }

        this.notificationService.processMessages(errorResponse.messages);
    }

}
