import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-hexcode-color-icon',
    templateUrl: './hexcode-color-icon.component.html',
    styleUrls: ['./hexcode-color-icon.component.scss']
})

export class HexcodeColorIconComponent {
    @Input() hexcode: string;
}
