import {DynamicCheckboxModel, DynamicPathable, DynamicSelectModel} from '@ng-dynamic-forms/core';
import {Injectable} from '@angular/core';

import {DynamicInputAutocompleteMultipleModel} from '../../form/model';
import {Filter} from '../filter';
import {SelectMultipleFilterHelper} from '../../../helpers/filters/select-multiple-filter.helper';
import {Store} from '@ngrx/store';
import * as fromCrud from '../../../../../../store/list-filter';
import {ListFilter} from '../../../models/filter';
import {FilterPresentationInterface} from '../filter.presentation';
import {ListFilterService} from '../../../list-filter.service';
import {RequestService} from '@shared/services/request.service';
import {pairwise} from 'rxjs';
import {ObjectHelper} from '@shared/helpers/object.helper';

@Injectable()
export class SelectMultipleFilter extends Filter {
    static readonly modelType: string = 'multiselect';
    readonly modelType: string        = SelectMultipleFilter.modelType;

    public list: any[];
    protected autocompleteModel: DynamicInputAutocompleteMultipleModel | any;
    protected checkAllModel: DynamicCheckboxModel | any;
    public requestService: RequestService;

    constructor(
        listFilterService: ListFilterService,
        store$: Store<fromCrud.State>,
        filter: ListFilter,
        filterPresentation: FilterPresentationInterface,
        requestService: RequestService
    ) {
        super(listFilterService, store$, filter, filterPresentation, SelectMultipleFilter.modelType);

        this.requestService = requestService;
    }

    public getModels(): Array<DynamicPathable> {
        return [this.getCheckAllModel(), this.getAutocompleteModel()];

    }

    public accept(emitChanges = true): void {
        const values = this.getAutocompleteValues();

        this.updateMainModelValue(values, this.getAutocompletePrefix());
        this.applyFilter(values, emitChanges);
    }

    public setValue(value: {}): void {
        this.autocompleteModel.value = value['autocompleteModel']
    }

    public getValue(): {} {
        return {
            'autocompleteModel': this.autocompleteModel.value,
        };
    }

    protected getAutocompleteValues() {
        let values = [];

        const listValues = Object.assign({}, ...(this.filter.options.map(option => {
            return ({[option['label']]: option['value']})
        })));

        if (this.autocompleteModel.value) {
            this.autocompleteModel.value.forEach(item => values.push(listValues[item]));
        }

        return values;
    }

    protected reset(): void {
        super.reset([]);

        this.autocompleteModel.value = [];
        this.checkAllModel.value     = null;
    }

    protected initMainModel(): void {
        this.mainModel = new DynamicSelectModel({
            id:          this.filter.field,
            label:       this.filter.label,
            placeholder: this.filter.label,
            multiple:    true,
            filterable:  true,
            disabled:    true,
            options:     this.filter.options,
        });
    }

    protected getAutocompletePrefix(initialValue: any = null): string {
        const value = initialValue || this.autocompleteModel.value;

        if (!(value && value.length > 1)) {
            return '';
        }

        return `<span class="mat-badge mat-badge-above mat-badge-after mat-badge-medium mat-primary">
                    <span class="mat-badge-content mat-badge-active">${value.length}</span>
                </span>`;
    }

    protected getAutocompleteModel(): DynamicInputAutocompleteMultipleModel {
        if (this.autocompleteModel) {
            return this.autocompleteModel;
        }

        let initialValues = this.getInitialValue();
        if (initialValues) {
            initialValues = this.getInitialValue().map((value) => this.filter.optionValues[value]);
        }

        const fullList = this.filter.getOptionsLabels();

        this.autocompleteModel = new DynamicInputAutocompleteMultipleModel({
            id:          this.getAutocompleteModelId(),
            label:       this.filter.label,
            placeholder: this.filter.label,
            multiple:    true,
            list:        fullList,
            value:       initialValues,
        }, fullList);

        if (initialValues) {
            const values     = [];
            const listValues = Object.assign({}, ...(this.filter.options.map(option => {
                return ({[option['label']]: option['value']})
            })));

            if (this.autocompleteModel.value) {
                this.autocompleteModel.value.forEach(item => values.push(listValues[item]));
            }

            this.updateCheckAllModelState(this.getMissedValues(initialValues));
            this.updateMainModelValue(values, this.getAutocompletePrefix());
            this.applyFilter(this.getInitialValue(), false);
        }

        this.autocompleteModel.valueCount = this.autocompleteModel.value ? this.autocompleteModel.value.length : 0;

        this.autocompleteModel.valueChanges.pipe(pairwise()).subscribe(([prev, values]: [string[], string[]]) => {
            if (ObjectHelper.twoObjectsEqual(prev, values) && values.length >= this.autocompleteModel.valueCount) {
                return;
            }

            this.autocompleteModel.valueCount = values.length;

            const newValues = values.filter((value, index, self) => {
                return self.indexOf(value) === index && this.filter.getOptionsLabels().includes(value);
            });

            const missedValues          = this.getMissedValues(newValues);
            this.autocompleteModel.list = missedValues;

            this.updateCheckAllModelState(missedValues);
        });

        SelectMultipleFilterHelper.bindSearch(this, this.autocompleteModel);

        return this.autocompleteModel;
    }

    protected getCheckAllModel(): DynamicCheckboxModel {
        if (this.checkAllModel) {
            return this.checkAllModel;
        }

        this.checkAllModel = new DynamicCheckboxModel({
            id: this.getCheckAllModelId(),
        });

        this.checkAllModel.valueChanges.subscribe((value: any[]) => {
            if (this.autocompleteModel) {
                this.autocompleteModel.value = value ? this.filter.getOptionsLabels().slice() : [];
            }
        });

        return this.checkAllModel;
    }

    protected updateCheckAllModelState(missedValues: any[]): void {
        if (missedValues.length === 0 && !this.checkAllModel.checked) {
            this.checkAllModel.value = true;
        }

        if (missedValues.length > 0 && this.checkAllModel.checked) {
            this.checkAllModel.value = false;
        }
    }

    protected getMissedValues(values: any[]) {
        return this.filter.getOptionsLabels().filter(item => !values.includes(item));
    }

    protected getInitialValue(): string | any {
        const initialValue = super.getInitialValue();
        return initialValue ? initialValue.split(',') : null;
    }

    protected getAutocompleteModelId() {
        return `${this.filter.field}-field`;
    }

    protected getCheckAllModelId() {
        return `${this.filter.field}-field-check-all`;
    }
}
