import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    Component, ContentChild,
    ContentChildren,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import {MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {Subject, Subscription} from 'rxjs';
import * as _ from 'lodash';

import {Pagination} from './models/pagination';
import {ListFilterService} from '../list-filter/list-filter.service';
import {BulkActionModel} from '../bulk-actions/models/bulk-action.model';
import * as crudActions from '../../../store/crud/crud.actions';
import {select, Store} from '@ngrx/store';
import * as fromCrud from '../../../store/crud';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {ListTableACLPermissions} from './types/list-table-permissions';
import {ACLService} from '../../services/acl.service';
import {Crud} from '@shared/components/crud/crud.interface';
import {SortDirection} from '@angular/material/sort';

const TABLE_DIRECTION_HORIZONTAL = 'horizontal';
const TABLE_DIRECTION_VERTICAL   = 'vertical';

@Component({
    selector:        'list-table',
    templateUrl:     './list-table.component.html',
    styleUrls:       ['./list-table.component.scss'],
    encapsulation:   ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListTableComponent implements AfterContentInit, OnInit, OnDestroy, AfterViewInit {
    @Input() dataSource;
    public _dataSource = [];

    @Input() displayedColumns;
    @Input() sortings;
    @Input() aclService: ACLService<ListTableACLPermissions>;

    @Input() defaultSortingColumn    = '';
    @Input() defaultSortingDirection: SortDirection = '';
    @Input() paginationSource: Pagination;
    @Input() bulkActions: Array<BulkActionModel>;
    @Input() bulkActionItems         = new Map();

    @Input() buttonAddNewItemTitle   = '';
    @Input() buttonEditItemTitle     = '';
    @Input() buttonDeleteItemTitle   = '';
    @Input() tableDirection: string  = TABLE_DIRECTION_HORIZONTAL;
    @Input() dropZones               = [];
    @Input() canBeEditedPropertyName = 'canBeEdited';

    @Input() createNewItemCallback                              = null;
    @Input() editItemCallback                                   = null;
    @Input() deleteItemCallback                                 = null;
    @Input() selectRowCallback                                  = null;
    @Input() clickRowCallback                                   = null;
    @Input() sortingCallback                                    = null;
    @Input() afterExecuteBulkActionCallback                     = null;
    @Input() beforeExecuteBulkActionCallback                    = null;
    @Input() confirmBulkActionSubject: Subject<BulkActionModel> = null;
    @Input() showNotificationCallback                           = null;
    @Input() columnsSortingCallback                             = null;
    @Input() paginationChangeCallback                           = null;
    @Input() hidePaginationIfFewResults                         = false;
    @Input() refreshCallback                                    = null;
    @Input() hasReplacedControls                                = false;
    @Input() removePaginationResult                             = false;

    @Input() breadcrumbCallback = null;
    @Input() breadcrumbSource: Array<object>;

    @Input() emptyTableText: string;

    @ContentChildren('controlsTemplates', {descendants: true})
    public controlsTemplatesList!: QueryList<TemplateRef<any>>;
    public replacedControls: Object = {};

    @ViewChild(MatPaginator, {static: false})
    public paginatorTop: MatPaginator;

    @ViewChild(MatPaginator, {static: false})
    public paginatorBottom: MatPaginator;

    @ViewChildren('paginatorTop, paginatorBottom', {read: ElementRef}) paginators!: QueryList<ElementRef>;

    @ContentChild('additionalControlTemplate', {static: false})
    public additionalControlTemplate: TemplateRef<any>;

    @ViewChild(MatSort, {static: false})
    public matSort: MatSort;

    public dragEnabled   = false;
    public hasSorting    = false;
    public hasPagination = false;

    public _sortings            = [];
    public _usedSorting         = {};
    public crudUsedSorting      = {};
    public paginationTotalCount = 0;
    public dataSourceSubscriber: Subscription;
    public sortingsSubscriber: Subscription;
    public controlsTemplatesListSubscriber: Subscription;
    public crudDataSubscriber: Subscription;
    public sortingEnabled       = true;

    constructor(
        private listFilterService: ListFilterService,
        private store$: Store<fromCrud.State>,
        private renderer: Renderer2
    ) {
    }

    ngOnInit() {
        if (Array.isArray(this.dataSource)) {
            this.initDataSource(this.dataSource);
        } else {
            this.dataSourceSubscriber = this.dataSource.subscribe(dataSource => this.initDataSource(dataSource));
        }

        if (this.sortings) {
            this.sortingsSubscriber = this.sortings.subscribe(sorting => this.initSortings(sorting));
        }

        this.handleSortingAvailability();
    }

    ngOnDestroy() {
        if (this.dataSourceSubscriber) {
            this.dataSourceSubscriber.unsubscribe();
        }

        if (this.sortingsSubscriber) {
            this.sortingsSubscriber.unsubscribe();
        }

        if (this.crudDataSubscriber) {
            this.crudDataSubscriber.unsubscribe();
        }
    }

    ngAfterContentInit() {
        if (this.isVerticalDirection()) {
            this.adaptToVerticalDirection();
        }

        this.hasSorting    = this.sortingCallback != null;
        this.hasPagination = this.paginationSource != null;
    }

    ngAfterViewInit(): void {
        if (!this.removePaginationResult) {
            return;
        }

        this.paginators.forEach(item => {
            this.renderer.removeChild(
                item.nativeElement.querySelector('.mat-paginator-container'),
                item.nativeElement.querySelector('.mat-paginator-range-actions')
            );
        });
    }

    initDataSource(dataSource) {
        if (this.hasReplacedControls) {
            this.initReplacedControls(dataSource);
        } else {
            this._dataSource = [...dataSource];
        }
    }

    initSortings(sortings) {
        this._sortings    = sortings['sortings'] || [];
        this._usedSorting = sortings['usedSorting'] || {};

        if (!this.defaultSortingColumn && Object.keys(this._usedSorting).length) {
            this.defaultSortingColumn    = Object.keys(this._usedSorting).shift();
            this.defaultSortingDirection = this._usedSorting[this.defaultSortingColumn];
            this.defaultSortingDirection = this.defaultSortingDirection.toLowerCase() as SortDirection;
            this.defaultSortingColumn    = this.defaultSortingColumn.replace('dea.', '');
        }
    }

    adaptToVerticalDirection() {
        const newDataSource = [];

        for (const columns of this.dataSource) {
            const properties = Object.getOwnPropertyNames(columns);

            for (const i in properties) {
                const columnName = properties[i];
                const title      = this.displayedColumns['titles'][i];

                if (columns[columnName]) {
                    newDataSource.push({
                        title: title,
                        value: columns[columnName],
                        name:  columnName,
                    });
                }
            }
        }

        this.dataSource                 = newDataSource;
        this.displayedColumns.names     = ['item'];
        this.displayedColumns['titles'] = [];
    }

    initReplacedControls(dataSource) {
        if (!this.controlsTemplatesList) {
            return
        }

        this.replacedControls = {};

        if (this.controlsTemplatesListSubscriber) {
            this.controlsTemplatesListSubscriber.unsubscribe();
        }

        this.controlsTemplatesListSubscriber = this.controlsTemplatesList.changes.subscribe(() => {
            const controlsLength = this.controlsTemplatesList.length;

            for (let i = 0; i < controlsLength; i++) {
                const item                                                          = this.controlsTemplatesList['_results'][i];
                this.replacedControls[item.elementRef.nativeElement['controlName']] = item;
            }

            this._dataSource = dataSource ? [...dataSource] : [];
        });
    }

    isInReplacedControls(name, element) {
        let controlName = 'control-' + name;

        if (this.isHorizontalDirection()) {
            controlName += '-' + element['id'];
        }

        return this.replacedControls[controlName];
    }

    isInReplacedAdditional(name, element) {
        let additionalName = name;

        if (this.isHorizontalDirection()) {
            additionalName += '-' + element['id'];
        }

        return this.replacedControls[additionalName];
    }

    getReplacedControlRef(name, element) {
        let controlName = 'control-' + name;

        if (this.isHorizontalDirection()) {
            controlName += '-' + element['id'];
        }

        return this.replacedControls[controlName];
    }

    getReplacedAdditionalRef(name, element) {
        let additionalName = name;

        if (this.isHorizontalDirection()) {
            additionalName += '-' + element['id'];
        }

        return this.replacedControls[additionalName];
    }

    enableSorting() {
        this.dragEnabled = true;
    }

    disableSorting() {
        this.dragEnabled = false;
    }

    dropSuccess(event: CdkDragDrop<any>) {
        const dropItem       = this._dataSource[event.previousIndex];
        dropItem['position'] = event.currentIndex;

        if (event.currentIndex < event.previousIndex) {
            for (let i = event.previousIndex - 1; i >= event.currentIndex; i--) {
                this._dataSource[i + 1] = this._dataSource[i];
                this._dataSource[i + 1]['position'] += this._usedSorting['dea.position'] === 'DESC' ? -1 : 1;
            }
        }

        if (event.currentIndex > event.previousIndex) {
            for (let i = event.previousIndex + 1; i <= event.currentIndex; i++) {
                this._dataSource[i - 1] = this._dataSource[i];
                this._dataSource[i - 1]['position'] += this._usedSorting['dea.position'] === 'DESC' ? 1 : -1;
            }
        }

        const prevItem = this._dataSource[event.currentIndex - 1];
        const nextItem = this._dataSource[event.currentIndex + 1];

        if (prevItem) {
            dropItem['position'] = prevItem['position'] + (this._usedSorting['dea.position'] === 'DESC' ? -1 : 1);
        } else if (nextItem) {
            dropItem['position'] = nextItem['position'] + (this._usedSorting['dea.position'] === 'DESC' ? 1 : -1);
        }

        this._dataSource[event.currentIndex] = dropItem;

        this.disableSorting();
        this.sortingCallback(dropItem['position'], dropItem);
        this.dataSource  = this._dataSource.slice();
        this._dataSource = [...this.dataSource];

        this.store$.dispatch(new crudActions.LoadData(this.dataSource));
    }

    getEditingControlName(element) {
        return 'edit-button';
    }

    isEditingControlTemplate(element) {
        return !element['isEdited']
    }

    getDeletingControlName(element) {
        return 'delete-button';
    }

    getSelectedControlName(element) {
        return 'select-row-checkbox';
    }

    getAdditionalControlName(element) {
        return 'additional-buttons';
    }

    isDeletingControlTemplate(element) {
        return !element['isDeleted']
    }

    isAdditionalControlTemplate(element) {
        return !element['isAdditional']
    }

    hasEditingButton(element) {
        if (!this.isPermitted('edit')) {
            return false;
        }

        const canBeEdited = element.hasOwnProperty(this.canBeEditedPropertyName) ? element[this.canBeEditedPropertyName] === true : true;
        return this.editItemCallback && !element['isDeleted'] && canBeEdited;
    }

    hasDeletingButton(element) {
        if (!this.isPermitted('delete')) {
            return false;
        }

        const canBeDeleted = element.hasOwnProperty('canBeDeleted') ? element['canBeDeleted'] === true : true;

        return this.deleteItemCallback && !element['isDeleted'] && canBeDeleted;
    }

    hasSelectRowCheckbox(element) {
        const canBeSelected = element.hasOwnProperty('canBeSelected') ? element['canBeSelected'] === true : true;

        return this.selectRowCallback && !element['isDeleted'] && canBeSelected && this.bulkActions.length;
    }

    hasDataModifier(columnName) {
        return this.displayedColumns['modifiers'].hasOwnProperty(columnName);
    }

    modifyColumnData(columnName, element) {
        return this.displayedColumns['modifiers'][columnName](element);
    }

    isHorizontalDirection() {
        return this.tableDirection == TABLE_DIRECTION_HORIZONTAL;
    }

    isVerticalDirection() {
        return this.tableDirection == TABLE_DIRECTION_VERTICAL;
    }

    onPaginationChange(pageEvent: PageEvent) {
        this.paginationSource.perPage     = pageEvent.pageSize;
        this.paginationSource.currentPage = pageEvent.pageIndex + 1;

        if (this.paginationChangeCallback) {
            this.paginationChangeCallback(pageEvent);
        }
    }

    changeSorting(sortings) {
        if (sortings['init'] && sortings['init'] === true) {
            if (this.columnsSortingCallback) {
                this.columnsSortingCallback(sortings);
            }
            return;
        }

        if (this.isSortingsEmpty(sortings)) {
            sortings['empty'] = true;
        }

        if (this.columnsSortingCallback) {
            this.columnsSortingCallback(sortings);
        }

        this.listFilterService.emitFiltersChange();
    }

    isSortingsEmpty(sortings): boolean {
        if (sortings['direction'] === '') {
            return true;
        }

        return sortings['active'] instanceof Object && Object.keys(sortings['active']).length === 0;
    }

    canBeSorted(columnName) {
        columnName = _.camelCase(columnName).replace('_', '');
        return this._sortings.includes(columnName);
    }

    isBulkActionItemDisabled(item: Crud) {
        return this.bulkActionItems.get(item.id)?.disabledCheckbox;
    }

    getNotDisabledBulkActionItemsCount() {
        let notDisabledBulkActionItemsCount = 0;
        this.bulkActionItems.forEach((item) => {
            if (!item.disabledCheckbox) {
                notDisabledBulkActionItemsCount++
            }
        })

        return notDisabledBulkActionItemsCount;
    }

    private handleSortingAvailability() {
        this.crudDataSubscriber = this.store$.pipe(select(fromCrud.getCrud)).subscribe((crud: any) => {
            this.crudUsedSorting      = crud['usedSorting'];
            this.paginationTotalCount = crud['pagination']['totalCount'];

            if (Object.keys(crud['usedFilters']).length) {
                return this.sortingEnabled = false;
            }

            const usedSortingLength = Object.keys(crud['usedSorting']).length;

            if (usedSortingLength >= 2 || (usedSortingLength === 1) && !crud['usedSorting']['dea.position']) {
                return this.sortingEnabled = false;
            }

            this.sortingEnabled = true;
        });
    }

    private editItemCallbackProxy(item: any) {
        if (!this.hasEditingButton(item)) {
            return;
        }

        this.editItemCallback(item);
    }

    private isPermitted(action: ListTableACLPermissions): boolean {
        if (!this.aclService) {
            return true;
        }

        return this.aclService.getACL().can(action);
    }
}
