import {Filter, FilterComparison, FilterSettings, PreDefined} from '@shared/components/crud/crud.interface';

const RELATION_FILTER_SEPARATOR_CHAR = '.';
const RELATION_FILTER_REPLACED_CHAR = '_';
export const FILTER_DEFAULT_GROUPS  = ['MAIN'];

export class ListFilter implements Filter {
    public field: string;
    public groups: Array<string>;
    public label: string;
    public type: string;
    public initialValue: any;
    public comparison: Array<FilterComparison>;
    public preDefined: Array<PreDefined>;
    public options: any[] | null;
    public optionValues: {} = {};
    public optionParsedValues: {} = {};
    public settings: FilterSettings;
    public inBothPresentations: boolean;
    public isRelationFilter: boolean;

    constructor(filter: Filter, usedFilters: {}) {
        this.groups = filter.groups || FILTER_DEFAULT_GROUPS;
        this.isRelationFilter = filter.field.includes('.');
        this.label = filter.label;
        this.type = filter.type;
        this.comparison = filter.comparison;
        this.preDefined = filter.preDefined || [];
        this.options = filter.options || [];
        this.settings = new ListFilterSettings(filter.settings || {});
        this.initialValue = usedFilters[filter.field] || null;
        this.inBothPresentations = filter.inBothPresentations !== undefined;
        this.setField(filter);

        this.options.forEach((option) => {
            this.optionValues[option['value']] = option['label'];

            if (option['parsedValue']) {
                this.optionParsedValues[option['value']] = option['parsedValue'];
            }
        });
    }

    public getAutoCompleteUrl(): string {
        return this.settings.autoCompleteUrl;
    }

    public getOptionsLabels() {
        return this.options.map((option) => option['label'])
    }

    public getOptionsForSelect() {
        return this.comparison.map((item) => {
            return {...item, value: item['operator']}
        });
    }

    public getPreDefinedLabels() {
        return this.preDefined.map((item) => item['label'])
    }

    public getField() {
        return this.isRelationFilter ? this.field.split(RELATION_FILTER_REPLACED_CHAR).join(RELATION_FILTER_SEPARATOR_CHAR) : this.field;
    }

    public setField(filter: Filter) {
        this.field = this.isRelationFilter ? filter.field.split(RELATION_FILTER_SEPARATOR_CHAR).join(RELATION_FILTER_REPLACED_CHAR) : filter.field
    }
}

export class ListFilterSettings implements FilterSettings {
    public autoCompleteUrl          = '';
    public max: number | null       = null;
    public min: number | null       = null;
    public minLength: number | null = null;
    public maxLength: number | null = null;
    public logScale: boolean | null = null;
    public value: number | null     = null;

    constructor(settings: FilterSettings) {
        this.autoCompleteUrl = settings.autoCompleteUrl || '';
        this.min             = settings.min || settings.min === 0 ? settings.min : null;
        this.max             = settings.max || null;
        this.minLength       = settings.minLength || null;
        this.maxLength       = settings.maxLength || null;
        this.logScale        = settings.logScale || null;
        this.logScale        = settings.logScale || null;
        this.value           = settings.value || null;
    }
}
