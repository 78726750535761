import {Injectable} from '@angular/core';

import {DateRangeWithInputFilter as BaseDateRangeWithInputFilter} from '../../../filter-main-panel/filters/date-range/date-range-with-input.filter';

@Injectable()
export class DateRangeWithInputFilter extends BaseDateRangeWithInputFilter {

    protected initMainModel(): void {
    }

    protected useSettingsValue() {
        return false;
    }

}
