import {DynamicPathable, DynamicSelectModel} from '@ng-dynamic-forms/core';
import {Injectable} from '@angular/core';

import {DynamicInputAutocompleteSingleModel} from '../../form/model';
import {Filter} from '../filter';
import {Store} from '@ngrx/store';
import * as fromCrud from '../../../../../../store/list-filter';
import {ListFilter} from '../../../models/filter';
import {FilterPresentationInterface} from '../filter.presentation';
import {ListFilterService} from '../../../list-filter.service';

@Injectable()
export class SelectFilter extends Filter {
    static readonly modelType: string = 'select';
    readonly modelType: string        = SelectFilter.modelType;

    public list: any[];
    protected autocompleteModel: DynamicInputAutocompleteSingleModel | any;

    constructor(
        listFilterService: ListFilterService,
        store$: Store<fromCrud.State>,
        filter: ListFilter,
        filterPresentation: FilterPresentationInterface,
        modelType = SelectFilter.modelType
    ) {
        super(listFilterService, store$, filter, filterPresentation, modelType);
    }

    public getModels(): Array<DynamicPathable> {
        return [this.getAutocompleteModel()];
    }

    public accept(emitChanges = true): void {
        const listValues = Object.assign({}, ...(this.filter.options.map((option) => {
            return ({[option['label']]: option['value']});
        })));

        this.updateAutocompleteList();

        const value = listValues[this.autocompleteModel.value];

        this.updateMainModelValue(value);
        this.applyFilter(value, emitChanges);
    }

    public setValue(value: string): void {
        this.autocompleteModel.value = value;
    }

    public getValue(): string {
        return this.autocompleteModel.value;
    }

    protected reset(): void {
        super.reset();

        this.autocompleteModel.value = null;
        this.autocompleteModel.list  = this.list;
    }

    protected initMainModel(): void {
        this.mainModel = new DynamicSelectModel({
            id:          this.filter.field,
            label:       this.filter.label,
            placeholder: this.filter.label,
            multiple:    false,
            filterable:  true,
            disabled:    true,
            options:     this.filter.options,
        });
    }

    protected getAutocompleteModel(): DynamicInputAutocompleteSingleModel {
        if (this.autocompleteModel) {
            return this.autocompleteModel;
        }

        const initialValue = this.getInitialValue();
        this.list          = this.filter.getOptionsLabels();

        this.autocompleteModel = new DynamicInputAutocompleteSingleModel({
            id:          `${this.filter.field}-field`,
            label:       this.filter.label,
            placeholder: this.filter.label,
            multiple:    false,
            list:        this.list,
            value:       this.filter.optionValues[initialValue] || null,
        });

        if (initialValue) {
            this.applyFilter(initialValue, false);
        }

        this.addAutocompleteListeners();

        return this.autocompleteModel;
    }

    protected isValueEmpty(value: any): boolean {
        return typeof value === 'undefined' || value === null || value === '' || (value instanceof Array && !value.length)
    }

    protected addAutocompleteListeners() {
        setTimeout(() => {
            if (!document.getElementById(this.autocompleteModel.id)) {
                return;
            }

            document.getElementById(this.autocompleteModel.id).addEventListener('keypress', ($event) => {
                const target   = $event.target as HTMLTextAreaElement;
                const searched = (target.value + $event.key).trim().toLowerCase();

                if (searched) {
                    const filteredList = this.list.filter((i) => i.toLowerCase().indexOf(searched) === 0);

                    if (filteredList.length === 0) {
                        $event.preventDefault();
                    } else {
                        this.autocompleteModel.list = filteredList;
                    }

                    return;
                }

                this.autocompleteModel.list = this.list;
            })
        }, 0);

        setTimeout(() => {
            if (!document.getElementById(this.autocompleteModel.id)) {
                return;
            }

            document.getElementById(this.autocompleteModel.id).addEventListener('keydown', ($event) => {
                if ($event.key === 'Backspace') {
                    const target   = $event.target as HTMLTextAreaElement;
                    const searched = target.value.trim().slice(0, -1).toLowerCase();

                    this.autocompleteModel.list = this.list.filter((i) => i.toLowerCase().indexOf(searched) === 0);
                }
            })
        }, 0);
    }

    protected updateAutocompleteList() {
        this.autocompleteModel.list = this.list.filter((i) => i !== this.autocompleteModel.value);
    }

}

