import {Injectable} from '@angular/core';
import {SwitchFilter as BaseSwitchFilter} from '../../../filter-main-panel/filters/switch/switch.filter';
import {DynamicSwitchModel} from '@ng-dynamic-forms/core';

@Injectable()
export class SwitchFilter extends BaseSwitchFilter {

    protected initMainModel() {
        this.mainModel = new DynamicSwitchModel({
            id: this.filter.field + '-right',
            label: this.filter.label,
            value: this.getInitialValue()
        });
    }

    public getModels(): any[] {
        return [this.mainModel];
    }
}
