import {Injectable} from '@angular/core';
import {DynamicInputModel, DynamicPathable, MATCH_HIDDEN, MATCH_VISIBLE} from '@ng-dynamic-forms/core';
import {Store} from '@ngrx/store';

import {Filter} from '../filter';
import {ListFilter} from '../../../models/filter';
import {RequestService} from '@shared/services/request.service';
import * as fromCrud from '../../../../../../store/list-filter';
import {FilterPresentationInterface} from '../filter.presentation';
import {QueryFilterHelper} from '../../../helpers/filters/query-filter.helper';
import {ListFilterService} from '../../../list-filter.service';
import {FilterComparison} from '@shared/components/crud/crud.interface';
import {pairwise} from 'rxjs';
import {Comparison} from '@shared/enums/comparison.enum';

@Injectable()
export class QueryFilter extends Filter {
    static readonly modelType: string = 'query';

    public readonly multipleModelComparisons = [Comparison.IN, Comparison.NOT_IN];
    public queryModel: DynamicInputModel;
    public queryModelMultiple: DynamicInputModel;
    public requestService: RequestService;

    constructor(
        listFilterService: ListFilterService,
        store$: Store<fromCrud.State>,
        filter: ListFilter,
        filterPresentation: FilterPresentationInterface,
        requestService: RequestService) {

        super(listFilterService, store$, filter, filterPresentation, QueryFilter.modelType);

        this.requestService = requestService;
    }

    public getModels(): Array<DynamicPathable> {
        if (this.isMultipleComparisonExist()) {
            return [this.getQueryModel(), this.getQueryMultipleModel()]
        }

        return [this.getQueryModel()];
    }

    public accept(emitChanges = true): void {
        const value = this.getQueryValue();

        this.updateMainModelValue(value);
        this.applyFilter(value, emitChanges);
    }

    public setValue(value: {}): void {
        if (this.isMultipleComparisonExist()) {
            this.queryModelMultiple.value = value['queryModelMultiple'];
        }

        this.queryModel.value = value['queryModel'];
    }

    public getValue(): {} {
        if (this.isMultipleComparisonExist()) {
            return {
                'queryModel' : this.queryModel.value,
                'queryModelMultiple' : this.queryModelMultiple.value,
            }
        }

        return {
            'queryModel': this.queryModel.value,
        };
    }

    protected reset(): void {
        super.reset();

        this.queryModel.value = null;
        if (this.queryModelMultiple) {
            this.queryModelMultiple.value = null;
        }
    }

    protected initMainModel(): void {
        this.mainModelSuffix = '<i class="fas fa-ellipsis-h"></i>';

        this.mainModel = new DynamicInputModel({
            id: this.filter.field,
            label: this.filter.label,
            placeholder: this.filter.label,
            suffix: this.mainModelSuffix,
            disabled: true,
        });
    }

    private getQueryModel(): DynamicInputModel {
        if (this.queryModel) {
            return this.queryModel;
        }

        const initialValue = this.getInitialValue();
        const initialComparisonValue = this.getInitialComparisonValue();

        this.queryModel = this.getModel(initialValue, initialComparisonValue, MATCH_HIDDEN);

        if ((initialValue || this.isComparisonNullable(initialComparisonValue)) && !this.multipleModelComparisons.includes(initialComparisonValue)) {
            this.applyFilter(this.getInitialValue() || null, false);
        }

        this.bindSearch();

        this.getComparisonModel().valueChanges.subscribe((value) => {
            this.queryModel.readOnly = this.isComparisonNullable(value);
            this.queryModel.disabled = this.isComparisonNullable(value);

            QueryFilterHelper.toggleDisabled(this.queryModel);
        });

        return this.queryModel;
    }

    private getQueryMultipleModel(): DynamicInputModel {
        if (this.queryModelMultiple) {
            return this.queryModelMultiple;
        }

        const initialValue = this.getInitialValue();
        const initialComparisonValue = this.getInitialComparisonValue();

        this.queryModelMultiple = this.getModel(initialValue, initialComparisonValue, MATCH_VISIBLE);
        this.queryModelMultiple.id = `${this.filter.field}-multiple-field`;
        this.queryModelMultiple.multiple = true;

        if ((initialValue || this.isComparisonNullable(initialComparisonValue)) && this.multipleModelComparisons.includes(initialComparisonValue)) {
            this.applyFilter(this.getInitialValue() || null, false);
        }

        this.getComparisonModel().valueChanges.pipe(pairwise()).subscribe(([prev, value]) => {
            if (!this.multipleModelComparisons.includes(prev) || !this.multipleModelComparisons.includes(value)) {
                this.updateQueryValue(value);
            }
        });

        return this.queryModelMultiple;
    }

    private getModel(initialValue: any, initialComparisonValue: any, match: string): DynamicInputModel {
        return new DynamicInputModel({
            id: `${this.filter.field}-${this.id}-field`,
            label: this.filter.label,
            autoComplete: this.isAutocomplete() ? 'off' : '',
            placeholder: this.filter.label,
            min: this.filter.settings.min,
            max: this.filter.settings.max,
            minLength: this.filter.settings.minLength,
            maxLength: this.filter.settings.maxLength,
            value: initialValue,
            readOnly: this.isComparisonNullable(initialComparisonValue),
            disabled: this.isComparisonNullable(initialComparisonValue),
            relations: this.getRelations(match),
        });
    }

    private getRelations(match: string) {
        return [
            {
                match: match,
                when: [
                    {
                        id: this.comparisonModel.id,
                        value: Comparison.IN
                    },
                    {
                        id: this.comparisonModel.id,
                        value: Comparison.NOT_IN
                    }
                ],
            }
        ]
    }

    protected getInitialValue(): any {
        const value = this.filter.initialValue ? Object.values(this.filter.initialValue).shift() : '';

        if (value) {
            return Array.isArray(value) ? [...value] : value.toString();
        }

        return '';
    }

    private bindSearch(): void {
        if (this.isAutocomplete()) {
            QueryFilterHelper.bindSearch(this, this.queryModel, this.requestService);
        }
    }

    private isAutocomplete(): boolean {
        return this.filter.getAutoCompleteUrl() !== '';
    }

    private isMultipleComparisonExist() {
        return this.filter.comparison.some((filterComparison: FilterComparison) => {
            return this.multipleModelComparisons.includes(filterComparison.operator);
        });
    }

    private getQueryValue() {
        if (this.multipleModelComparisons.includes(this.getComparisonModel().value)) {
            return [...(this.queryModelMultiple.value as Array<string>)];
        }

        return this.queryModel.value;
    }

    private updateQueryValue(value: Comparison) {
        if (this.multipleModelComparisons.includes(value)) {
            this.queryModelMultiple.value = this.queryModel.value ? [this.queryModel.value.toString()] : '';
        } else {
            this.queryModel.value = this.queryModelMultiple.value ? this.queryModelMultiple.value[0] : '';
        }
    }
}

