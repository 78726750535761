import {DynamicFormControlLayout, DynamicInputModel, DynamicInputModelConfig} from '@ng-dynamic-forms/core';
import {Options} from '@angular-slider/ngx-slider';

export const DYNAMIC_FORM_CONTROL_TYPE_SLIDER_RANGE = 'SLIDER_RANGE';

export interface DynamicSliderRangeConfig extends DynamicInputModelConfig {
    fromValue: number | null;
    toValue: number | null;
    logScale: boolean | null;
}

export class DynamicSliderRangeModel extends DynamicInputModel {
    type: string = DYNAMIC_FORM_CONTROL_TYPE_SLIDER_RANGE;
    fromValue: number;
    toValue: number;
    logScale: boolean;
    options: Options;
    model: DynamicSliderRangeModel;

    constructor(config: DynamicSliderRangeConfig, layout?: DynamicFormControlLayout, type?: string) {
        super(config, layout);

        this.model     = this;
        this.type      = type || DYNAMIC_FORM_CONTROL_TYPE_SLIDER_RANGE;
        this.fromValue = +config.fromValue || +config.min;
        this.toValue   = +config.toValue || +config.max;
        this.logScale  = config.logScale;
        this.setOptions(+config.min, +config.max, this.logScale);

        this.sliderValueChange({value: this.fromValue, highValue: this.toValue});
    }

    public sliderValueChange(event: any) {
        this.value = `${event.value}|${event.highValue}`;
    }

    public setOptions(min: number, max: number, logScale: boolean = false): Options {
        if (this.options !== undefined) {
            return;
        }

        this.options             = new Options();
        this.options.noSwitching = true;
        this.options.ceil        = max;

        if (logScale) {
            this.options.logScale = logScale;
            this.options.floor    = min > 0 ? min : 1;
        } else {
            this.options.step      = Math.ceil((max - min) / 10);
            this.options.floor     = min;
            this.options.showTicks = true;
        }

        return this.options;
    }

}


