import {DynamicFormGroupModel} from '@ng-dynamic-forms/core';
import {FilterPresentation} from '../../filter-main-panel/filters/filter.presentation';

export class RightPanelFilterPresentation extends FilterPresentation {

    public getFormGroupModel(): DynamicFormGroupModel {
        let filterGroup = new DynamicFormGroupModel({
            id: `${this.filter.getFilter().field}-filter-group`,
        });

        if (this.filter.getComparisonModel()) {
            filterGroup.group.push(this.filter.getComparisonModel());
        }

        this.filter.getModels().forEach(model => filterGroup.group.push(model));

        return filterGroup;
    }

    public updateMainModelValue(value: any, prefix: string = ''): void {
        return null;
    }

}


