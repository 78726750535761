import {Filter} from '../filter-main-panel/filters';

export class FilterStore {
    public value: any;
    public id: string;
    public modelType: string;
    public field: string;
    public label: string;
    public comparison: string;
    public optionValues: {};
    public groups: Array<string>;

    constructor(filter: Filter, value: any) {
        this.value        = value;
        this.id           = filter.getId();
        this.modelType    = filter.modelType;
        this.field        = filter.getFilter().getField();
        this.label        = filter.getFilter().label;
        this.comparison   = filter.getSelectedComparison().label;
        this.optionValues = filter.getFilter().optionValues;
        this.groups       = filter.getFilter().groups;
    }
}
