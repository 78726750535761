import {Action} from '@ngrx/store';
import {ListFilterData} from './list-filter.interface';

export enum ListFilterActionTypes {
    ClearFilters      = '[LIST_FILTER] Clear filters',
    ClearFilterModels = '[LIST_FILTER] Clear filter models',
    LoadData          = '[LIST_FILTER] Load data',
    AddFilter         = '[LIST_FILTER] Filter add',
    RemoveFilter      = '[LIST_FILTER] Filter remove',
}

export class ClearFilters implements Action {
    readonly type = ListFilterActionTypes.ClearFilters;
}

export class ClearFilterModels implements Action {
    readonly type = ListFilterActionTypes.ClearFilterModels;
}

export class LoadData implements Action {
    readonly type = ListFilterActionTypes.LoadData;

    constructor(public payload: ListFilterData) {
    }
}

export class AddFilter implements Action {
    readonly type = ListFilterActionTypes.AddFilter;

    constructor(public payload: any) {
    }
}

export class RemoveFilter implements Action {
    readonly type = ListFilterActionTypes.RemoveFilter;

    constructor(public payload: any) {
    }
}

export type ListFilterActions =
    LoadData | AddFilter | RemoveFilter | ClearFilters | ClearFilterModels;
