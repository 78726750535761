import {HttpParams} from '@angular/common/http';
import {DynamicInputModel} from '@ng-dynamic-forms/core';
import {debounce} from 'lodash';

import {Filter} from '../../filter-main-panel/filters';
import {RequestService} from '@shared/services/request.service';

export class QueryFilterHelper {

    public static toggleDisabled(model: DynamicInputModel): void {
        setTimeout(() => {
            if (document.querySelector(`#${model.id}`) === null) {
                return;
            }

            const matField      = document.querySelector(`#${model.id}`).closest('mat-form-field');
            const matFieldInput = document.querySelector(`#${model.id}`).closest('mat-form-field input');

            if (model.readOnly) {
                matField.classList.add('mat-form-field-disabled');
                matFieldInput.setAttribute('disabled', 'disabled');
            } else {
                matField.classList.remove('mat-form-field-disabled');
                matFieldInput.removeAttribute('disabled');
            }
        }, 0);
    }

    public static bindSearch(filter: Filter, model: DynamicInputModel, requestService: RequestService) {
        setTimeout(() => {
            if (document.getElementById(model.id) === null) {
                return;
            }

            document.getElementById(model.id).addEventListener('click', (event) => {
                const target       = event.target as HTMLTextAreaElement | any;
                const modelControl = target.closest(`#${model.id}`);

                if (!modelControl) {
                    return;
                }

                if (modelControl.classList.contains('search-event')) {
                    return;
                }

                modelControl.classList.add('search-event');

                let searchCallTimer  = null;
                const searchListener = (event: any) => {
                    const target           = event.target as HTMLInputElement;
                    const searched: string = target.value.trim().toLowerCase();

                    if (!QueryFilterHelper.isSearchPermitted(searched, filter)) {
                        return;
                    }

                    const searchRequest = (requestService, filter) => {
                        const params = (new HttpParams({encoder: RequestService.getHttpEncoder()})).append('query', searched);

                        requestService.get(filter.getFilter().getAutoCompleteUrl(), params)
                            .subscribe((list) => {
                                model.list = Object.values(list);

                                if (modelControl) {
                                    modelControl.click();
                                }
                            });
                    };

                    clearTimeout(searchCallTimer);
                    searchCallTimer = setTimeout(() => searchRequest(requestService, filter), 500);
                };

                modelControl.addEventListener('input', debounce(searchListener, 700), false);
            }, false)
        }, 1000);
    }

    public static isSearchPermitted(searched: string, filter: Filter) {
        const minLength = filter.getFilter().settings.minLength;
        const maxLength = filter.getFilter().settings.maxLength;

        return searched.length >= minLength && searched.length <= maxLength;
    }
}
