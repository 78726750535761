import {ListFilter} from '../models/filter';

export class BaseFormModelFactory {
    protected static filters = [];

    public static getFilters() {
        return this.filters;
    }

    protected static isAutocompleteUrlProvided(filter: ListFilter): boolean {
        return filter.getAutoCompleteUrl() !== '';
    }

    protected static isMaxMinSettingProvided(filter: ListFilter): boolean {
        return filter.settings.min !== null && filter.settings.max !== null;
    }
}
