import {DynamicPathable, DynamicSwitchModel} from '@ng-dynamic-forms/core';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';

import {Filter} from '../filter';
import * as fromCrud from '../../../../../../store/list-filter';
import {ListFilter} from '../../../models/filter';
import {FilterPresentationInterface} from '../filter.presentation';
import {ListFilterService} from '../../../list-filter.service';
import * as listFilterActions from '../../../../../../store/list-filter/list-filter.actions';
import {pairwise} from 'rxjs';

@Injectable()
export class SwitchFilter extends Filter {
    static readonly modelType: string = 'switch';
    protected emitChanges             = true;

    constructor(
        listFilterService: ListFilterService,
        store$: Store<fromCrud.State>,
        filter: ListFilter,
        filterPresentation: FilterPresentationInterface,
    ) {
        super(listFilterService, store$, filter, filterPresentation, SwitchFilter.modelType);
    }

    public getModels(): Array<DynamicPathable> {
        return [];
    }

    public setValue(value: any): void {
        /** to prevent sending request on switch filter value changing in right panel */
        this.emitChanges     = false;
        this.mainModel.value = value;
        this.emitChanges     = true;
    }

    public getValue(): any {
        return this.mainModel.value;
    }

    protected initMainModel(): void {
        const initialValue = this.getInitialValue();
        this.mainModel = new DynamicSwitchModel({
            id: this.filter.field,
            label: this.filter.label,
            value: initialValue
        });

        if (initialValue) {
            this.applyFilter(initialValue, false);
        }

        this.subscribeOnMainModelChange();
    }

    protected subscribeOnMainModelChange(): void {
        this.mainModel.valueChanges.pipe(pairwise()).subscribe(([prev, value]) => {
            if (this.emitChanges
                && prev !== value
                && prev !== null
                && value !== null
            ) {
                this.applyFilter(value);
            }
        });
    }

    protected getInitialValue(): boolean {
        return super.getInitialValue() == 'true';
    }

}
