import {Action} from '@ngrx/store';

export const SHOW_GLOBAL_PROGRESS_BAR = '[Layout] - show global progress bar';
export const HIDE_GLOBAL_PROGRESS_BAR = '[Layout] - hide global progress bar';
export const SHOW_TABLE_PROGRESS_BAR = '[Layout] - show table progress bar';
export const HIDE_TABLE_PROGRESS_BAR = '[Layout] - hide table progress bar';

const initialState = {
    showGlobal: false,
    showTable: false
};

export function progressReducer(state = initialState, action: Action) {
    switch (action.type) {
        case SHOW_GLOBAL_PROGRESS_BAR:
            return state.showTable ? state : {...state, showGlobal: true};

        case HIDE_GLOBAL_PROGRESS_BAR:
            return state.showTable ? state : {...state, showGlobal: false};

        case SHOW_TABLE_PROGRESS_BAR:
            return {...state, showTable: true};

        case HIDE_TABLE_PROGRESS_BAR:
            return {...state, showTable: false};

        default:
            return state;
    }
}
