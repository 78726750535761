import {DynamicFormArrayModel, DynamicFormControlModel, DynamicFormGroupModel} from '@ng-dynamic-forms/core';
import {InputControl} from './controls/input-control';
import {CheckboxControl} from './controls/checkbox-control';
import {SelectControl} from './controls/select-control';
import {SelectMultipleControl} from './controls/select-multiple-control';
import {InputPasswordControl} from './controls/input-password-control';
import {InputHiddenControl} from './controls/input-hidden-control';
import {InputEmailControl} from './controls/input-email-control';
import {TextareaControl} from './controls/textarea-control';
import {InputNumberControl} from './controls/input-number-control';
import {InputFileControl} from './controls/input-file-control';
import {SelectAutocompleteControl} from "@shared/modules/form/controls/select-autocomplete-control";
import {RequestService} from "@shared/services/request.service";

export class FormBuilder {
    fields: any;
    modelData: any;
    errorMessages: any;
    model: DynamicFormControlModel[] = [];
    requestService: RequestService;

    constructor(fields, modelData: any = [], requestService: RequestService = null) {
        this.fields         = fields;
        this.errorMessages  = {};
        this.modelData      = modelData;
        this.requestService = requestService;
    }

    build() {
        for (const field of this.fields) {
            switch (field.type) {
                case 'input':
                case 'input-date':
                case 'time':
                case 'color':
                    this.model.push((new InputControl(this, field)).build());
                    break;
                case 'checkbox':
                    this.model.push((new CheckboxControl(this, field)).build());
                    break;
                case 'select':
                    this.model.push((new SelectControl(this, field)).build());
                    break;
                case 'select-autocomplete':
                    this.model.push((new SelectAutocompleteControl(this, field, this.requestService)).build());
                    break;
                case 'select-multiple':
                    this.model.push((new SelectMultipleControl(this, field)).build());
                    break;
                case 'password':
                    this.model.push((new InputPasswordControl(this, field)).build());
                    break;
                case 'hidden':
                    this.model.push((new InputHiddenControl(this, field)).build());
                    break;
                case 'email':
                    this.model.push((new InputEmailControl(this, field)).build());
                    break;
                case 'number':
                    this.model.push((new InputNumberControl(this, field)).build());
                    break;
                case 'textarea':
                    this.model.push((new TextareaControl(this, field)).build());
                    break;
                case 'file':
                    this.model.push((new InputFileControl(this, field)).build());
                    break;
                case 'input-array':
                    const arrayModel = new DynamicFormArrayModel({
                        id:           field.name,
                        initialCount: parseInt(field.count, 10),
                        groupFactory: () => {
                            return [
                                (new InputFileControl(this, field)).build()
                            ];
                        }
                    });

                    this.model.push(arrayModel);
                    break;
                case 'array-group':
                    const dynamicSelectModel = (new SelectControl(this, field)).build();
                    this.model.push(dynamicSelectModel);

                    for (const children in field.children) {
                        if (field.children.hasOwnProperty(children)) {
                            const groups = new FormBuilder(field.children[children]).build();

                            const groupModel  = new DynamicFormGroupModel({
                                id:    children,
                                group: groups
                            })
                            groupModel.parent = dynamicSelectModel;

                            this.model.push(groupModel);
                        }
                    }

                    break;
            }
        }
        return this.model;
    }
}
