<section class="offset-6 qr-code-verification">
    <div (click)="close()"><i class="fa fa-close close-button"></i></div>
    <div class="content">
        <div class="header-text">{{ userName }} QR-Code Authenfizierung</div>
        <div class="sub-header-text" *ngIf="!showError">Bitte scanne den QR-Code von deiner Karte</div>
        <div class="sub-header-text error" *ngIf="showError">
            <i class="fa fa-exclamation"></i>Huups .. Der QR-Code ist nicht gültig!
        </div>
        <form (ngSubmit)="switch(code)">
            <div class="form-content">
                <div class="form-group">
                    <mat-form-field>
                        <input matInput
                               placeholder="QR-code"
                               #qrCodeInput
                               [(ngModel)]="code"
                               (ngModelChange)="codeChanged($event)"
                               (paste)="onPaste()"
                               class="form-control input-underline input-lg"
                               id="code"
                               autocomplete="off"
                               name="code"
                               type="password"
                               required>
                    </mat-form-field>
                </div>
                <button class="submit-button" [disabled]="isLoading || !code" mat-raised-button color="primary">
                    <span [hidden]="isLoading">Authentifizieren</span>
                    <span [hidden]="!isLoading"><i class="fa fa-circle-o-notch fa-spin"></i>Code wird geprüft</span>
                </button>
            </div>
        </form>
    </div>
</section>
