import {Injectable} from '@angular/core';

import {DateFilter as BaseDateFilter} from '../../../filter-main-panel/filters/date/date.filter';

@Injectable()
export class DateFilter extends BaseDateFilter {

    protected initMainModel(): void {
    }

}
