import {InjectionToken} from "@angular/core";
import {IAppConfig} from "./app-config.interface";

export const APP_DI_CONFIG: IAppConfig = {
    ENVIRONMENT: 'production',
    API_DOMAIN: 'https://www.shirtracer.de',
    API_ADMIN_URL: 'https://www.shirtracer.de/api/admin/v1/',
//    API_DOMAIN: 'https://shirtracer.loc:4443',
//    API_ADMIN_URL: 'https://shirtracer.loc:4443/api/admin/v1/',
    PUB_KEY: '-----BEGIN PUBLIC KEY-----\n' +
        'MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAxUA7tEldyAL0M8t8/9XJ\n' +
        '86eU5W2mn+FMUdJHlrkpF2QrsFQamKK6xve5i5TsL7PXEGU3nxVGNXXrfiQYcteZ\n' +
        'ceHj8vy0VwEdXbwnDVr0AxHtBqcN2+QVwv8l0jO7g0ATnZbIpEjgNNMxRSmjMz+J\n' +
        'wTe+wVZDaxkxWPEAFOH1YERhjHoYFQk3SOJU3Ct7UpbRKRMW9u15IAeh/P3xLBfL\n' +
        '5X6PykIHUWItL99gsSTG2fYOFLOMRZJ8aGzVL/9gA3lt2FrhcZanZB+VGr9xfqFI\n' +
        'rFYuTjGmvpV/HN3sCFfd6hWoHQaWPTwuKQg4mWH1PFBHekrQf3W4uczHC7vgwqZm\n' +
        '4zZjkBl3Io8FcefPCMttxR7bBdTANk9mnVRWB5fnxYVnzlcUryze7GlfaPV18dk1\n' +
        'ghylp5lsEGpxkX5eTEOeiUKYDG9DZB+U6a3RpMkz8wSraqDR52KqON/6/6WseClJ\n' +
        'qKU7E+GyqAhwy68VhLqXxGUPtgB8/DM6fnC1mHMuhI00YPpn4ePRtQzHBbsdx2tw\n' +
        'hFPYWj04S2WlJE9pfGgkPXEq7Q8pzBfVedjhg1dYxj4u3vpS+5tj3Lq8/GbW/Zw2\n' +
        '2EtNWbWbgUmMKZikODHMEANU+j3bbic3wskhvNd4EndEF2qHI3M/mxwem6z9eRnW\n' +
        'Hd4pyispkRLZFvnYTGsfGIMCAwEAAQ==\n' +
        '-----END PUBLIC KEY-----',
    MERCURE_HUB_URL: 'https://mercure.shirtracer.com/.well-known/mercure',
    MERCURE_JWT_TOKEN: 'eyJhbGciOiJIUzI1NiJ9.eyJtZXJjdXJlIjp7InN1YnNjcmliZSI6WyJzZWFyY2h0ZXJtUmV2aWV3Il19fQ.9w3uzJLD67OTrsEoE8GF_6i4JFK2SnR8fW-pry6NiCQ',
};
export let APP_CONFIG = new InjectionToken<IAppConfig>('app.config');
