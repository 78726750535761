import {DynamicFormControlLayout, DynamicInputModel, DynamicInputModelConfig} from '@ng-dynamic-forms/core';

export const DYNAMIC_FORM_CONTROL_TYPE_TITLE = 'TITLE';
export const DYNAMIC_FORM_CONTROL_TYPE_TITLE_POPUP = 'TITLE_POPUP';

export class DynamicTitleModel extends DynamicInputModel {
    type: string = DYNAMIC_FORM_CONTROL_TYPE_TITLE;

    constructor(config: DynamicInputModelConfig, layout?: DynamicFormControlLayout, type?: string) {
        super(config, layout);

        this.type = type || DYNAMIC_FORM_CONTROL_TYPE_TITLE;
    }

}