<app-bulk-actions *ngIf="bulkActionItems.size && bulkActions.length"
                  [actions]="bulkActions"
                  [bulkActionItems]="bulkActionItems"
                  [confirm]="confirmBulkActionSubject"
                  [notDisabledBulkActionItemsCount]="getNotDisabledBulkActionItemsCount()"
                  (afterExecute)="afterExecuteBulkActionCallback($event)"
                  (beforeExecute)="beforeExecuteBulkActionCallback($event)"
                  (onShowNotification)="showNotificationCallback($event)">
</app-bulk-actions>

<ng-container
    *ngIf="!hidePaginationIfFewResults || (hidePaginationIfFewResults && paginationSource && paginationSource.total > paginationSource.perPage)">
    <ng-container *ngIf="hasPagination">
        <mat-divider></mat-divider>

        <div class="pagination">
            <div *ngIf="refreshCallback" class="button" (click)="refreshCallback()">
                <mat-icon matTooltip="neu laden" class="refresh-icon">cached</mat-icon>
            </div>
            <ng-container *ngIf="breadcrumbCallback">
                <ol class="breadcrumb">
                    <li>
                        <span (click)="breadcrumbCallback(true)">Root</span>
                    </li>
                    <li *ngFor="let breadcrumb of breadcrumbSource">
                        <span (click)="breadcrumbCallback(false, breadcrumb)"> / {{ breadcrumb.name }}</span>
                    </li>
                </ol>
            </ng-container>
            <ng-container *ngIf="additionalControlTemplate" >
                <ng-template [ngTemplateOutlet]="additionalControlTemplate"></ng-template>
            </ng-container>

            <mat-paginator #paginatorTop
                           [disabled]="paginationSource.disabled"
                           [length]="paginationSource.total"
                           [pageSize]="paginationSource.perPage"
                           [pageIndex]="paginationSource.currentPage - 1"
                           [pageSizeOptions]="paginationSource.pageSizeOptions"
                           (page)="onPaginationChange($event);">
            </mat-paginator>
        </div>
        <mat-divider></mat-divider>
    </ng-container>
</ng-container>

<table *ngIf="_dataSource.length" mat-table matSort matSortMultiple
       (matSortChange)="changeSorting($event)"
       [matSortActive]="defaultSortingColumn" [matSortDirection]="defaultSortingDirection" [matSortDisableClear]="false"
       [matSortUsedSortings]="_usedSorting"
       [dataSource]="_dataSource"
       cdkDropList
       [cdkDropListData]="_dataSource"
>
    <ng-container *ngIf="isHorizontalDirection()">
        <tr mat-header-row *matHeaderRowDef="displayedColumns.names"></tr>
    </ng-container>

    <ng-container *ngIf="hasSorting">
        <tr mat-row *matRowDef="let row; columns: displayedColumns.names; let i = index;"
            (click)="clickRowCallback ? clickRowCallback(row) : ''"
            [ngClass]="{'is-deleted': row.isDeleted, 'cursor-pointer': clickRowCallback}"
            cdkDrag
            [cdkDragDisabled]="!dragEnabled"
            (cdkDragDropped)="dropSuccess($event)"
        ></tr>
    </ng-container>

    <ng-container *ngIf="!hasSorting">
        <tr mat-row *matRowDef="let row; columns: displayedColumns.names;"
            (click)="clickRowCallback ? clickRowCallback(row) : ''"
            [ngClass]="{'is-deleted': row.isDeleted, 'cursor-pointer': clickRowCallback}">
        </tr>
    </ng-container>

    <ng-container *ngFor="let name of displayedColumns.names; let i = index">
        <ng-container *ngIf="isVerticalDirection()">
            <ng-container matColumnDef="{{name}}">
                <ng-container mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element['value']">
                        <th mat-header-cell>
                            {{element['title']}}
                        </th>
                        <td mat-cell>
                            <ng-container *ngIf="hasDataModifier(element['value'])">{{modifyColumnData(element['name'],
                                element['value'])}}
                            </ng-container>
                            <ng-container *ngIf="!hasDataModifier(element['value'])">
                                <ng-container *ngIf="isInReplacedControls(element['value'], element['value'])">
                                    <ng-template
                                        [ngTemplateOutlet]="getReplacedControlRef(element['name'], element['value'])"></ng-template>
                                </ng-container>
                                <ng-container *ngIf="!isInReplacedControls(element['name'], element['value'])">
                                    {{element['value']}}
                                </ng-container>
                            </ng-container>
                        </td>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container [matColumnDef]="name" *ngIf="isHorizontalDirection()">

            <ng-container *ngIf="canBeSorted(name)">
                <th [attr.data-column]="name" mat-header-cell *matHeaderCellDef
                    mat-sort-header
                    [ngStyle]="{'width': displayedColumns['widths'][i]}"
                >
                    <ng-container *ngTemplateOutlet="tableHeaderCell;context:{element: element}"></ng-container>
                </th>
            </ng-container>

            <ng-container *ngIf="!canBeSorted(name)">
                <th [attr.data-column]="name" mat-header-cell *matHeaderCellDef
                    [ngStyle]="{'width': displayedColumns['widths'][i]}"
                >
                    <ng-container *ngTemplateOutlet="tableHeaderCell;context:{element: element}"></ng-container>
                </th>
            </ng-container>

            <td mat-cell *matCellDef="let element" [ngStyle]="{'width': displayedColumns['widths'][i]}">
                <ng-container *ngTemplateOutlet="tableCell;context:{element: element}"></ng-container>
            </td>

        </ng-container>

        <ng-template #tableHeaderCell let-element="element">
            <ng-container *ngIf="isHorizontalDirection()">
                <ng-container *ngIf="name == 'actions'">
                    <button *ngIf="createNewItemCallback"
                            class="add-new-item-button"
                            mat-raised-button
                            mat-mini-fab
                            matTooltip="{{buttonAddNewItemTitle}}"
                            color="primary"
                            (click)="createNewItemCallback()"
                    >
                        <mat-icon>add</mat-icon>
                    </button>
                </ng-container>
                {{displayedColumns.titles[i]}}
            </ng-container>
        </ng-template>

        <ng-template #tableCell let-element="element">
            <ng-container *ngIf="isHorizontalDirection()">
                <ng-container *ngIf="name == 'actions'">
                    <ng-container *ngIf="hasSorting">
                        <ng-container *ngIf="sortingEnabled">
                            <mat-icon (mousedown)="enableSorting()" cdkDragHandle matTooltip="Change position"
                                      matTooltipPosition="above">drag_handle
                            </mat-icon>
                        </ng-container>
                        <ng-container *ngIf="!sortingEnabled">
                            <mat-icon disabled
                                      matTooltip="Disabled. Sorting is not possible when there is an active filter or the table is sorted by a column except position column."
                            >drag_handle
                            </mat-icon>
                        </ng-container>
                    </ng-container>

                    <ng-container
                        *ngIf="isEditingControlTemplate(element) && isInReplacedControls(getEditingControlName(element), element)">
                        <ng-template
                            [ngTemplateOutlet]="getReplacedControlRef(getEditingControlName(element), element)"></ng-template>
                    </ng-container>

                    <ng-container *ngIf="!isInReplacedAdditional(getEditingControlName(element), element)">
                        <button class="edit-button" mat-icon-button [disabled]="!hasEditingButton(element)">
                            <mat-icon (click)="editItemCallbackProxy(element)"
                                      matTooltip="{{buttonEditItemTitle || 'bearbeiten'}}" matTooltipPosition="above">edit
                            </mat-icon>
                        </button>
                    </ng-container>

                    <ng-container
                        *ngIf="hasEditingButton(element) && isInReplacedAdditional(getEditingControlName(element), element)">
                        <ng-template
                            [ngTemplateOutlet]="getReplacedAdditionalRef(getEditingControlName(element), element)"
                        ></ng-template>
                    </ng-container>

                    <ng-container
                        *ngIf="isAdditionalControlTemplate(element) && isInReplacedAdditional(getAdditionalControlName(element), element)">
                        <ng-template
                            [ngTemplateOutlet]="getReplacedAdditionalRef(getAdditionalControlName(element), element)"
                        ></ng-template>
                    </ng-container>

                    <ng-container
                        *ngIf="isDeletingControlTemplate(element) && isInReplacedControls(getDeletingControlName(element), element)">
                        <ng-template
                            [ngTemplateOutlet]="getReplacedControlRef(getDeletingControlName(element), element)"></ng-template>
                    </ng-container>

                    <ng-container
                        *ngIf="hasDeletingButton(element) && !isInReplacedControls(getDeletingControlName(element), element)">
                        <mat-icon (click)="deleteItemCallback(element)"
                                  matTooltip="{{buttonDeleteItemTitle || 'löschen'}}">delete
                        </mat-icon>
                    </ng-container>

                    <ng-container
                        *ngIf="hasSelectRowCheckbox(element) && !isInReplacedControls(getSelectedControlName(element), element)">
                        <mat-checkbox [checked]="bulkActionItems.has(element['id'])"
                                      [disabled]="isBulkActionItemDisabled(element)"
                                      (change)="selectRowCallback(element, $event.checked)">&nbsp;
                        </mat-checkbox>
                    </ng-container>
                    <ng-container *ngIf="hasSelectRowCheckbox(element) && isInReplacedControls(getSelectedControlName(element), element)">
                        <ng-template
                            [ngTemplateOutlet]="getReplacedControlRef(getSelectedControlName(element), element)"></ng-template>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="name != 'actions'">
                    <ng-container *ngIf="hasDataModifier(name)">{{modifyColumnData(name, element)}}</ng-container>
                    <ng-container *ngIf="!hasDataModifier(name)">
                        <ng-container *ngIf="isInReplacedControls(name, element)">
                            <ng-template [ngTemplateOutlet]="getReplacedControlRef(name, element)"></ng-template>
                        </ng-container>
                        <ng-container *ngIf="!isInReplacedControls(name, element)">{{element[name]}}</ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-template>

    </ng-container>
</table>

<app-data-not-found [emptyTableText]="emptyTableText">
    <mat-divider></mat-divider>
</app-data-not-found>


<ng-container
    *ngIf="!hidePaginationIfFewResults || (hidePaginationIfFewResults && paginationSource && paginationSource.total > paginationSource.perPage)">
    <ng-container *ngIf="hasPagination">
        <div class="pagination">
            <div *ngIf="refreshCallback" class="button" (click)="refreshCallback()">
                <mat-icon matTooltip="neu laden" class="refresh-icon">cached</mat-icon>
            </div>
            <mat-paginator #paginatorBottom
                           [disabled]="paginationSource.disabled"
                           [length]="paginationSource.total"
                           [pageSize]="paginationSource.perPage"
                           [pageIndex]="paginationSource.currentPage - 1"
                           [pageSizeOptions]="paginationSource.pageSizeOptions"
                           (page)="onPaginationChange($event);">
            </mat-paginator>
        </div>
        <mat-divider></mat-divider>
    </ng-container>
</ng-container>
