import {DynamicFormControlLayout, DynamicInputModel, DynamicInputModelConfig} from '@ng-dynamic-forms/core';
import {MatLegacyCheckbox as MatCheckbox} from '@angular/material/legacy-checkbox';
import {map, take} from 'rxjs';

export const DYNAMIC_FORM_CONTROL_TYPE_INPUT_AUTOCOMPLETE_MULTIPLE_WITH_SETTINGS = 'INPUT_AUTOCOMPLETE_MULTIPLE_WITH_SETTINGS';

export class DynamicInputAutocompleteMultipleWithSettingsModel extends DynamicInputModel {
    model: DynamicInputAutocompleteMultipleWithSettingsModel;

    type: string         = DYNAMIC_FORM_CONTROL_TYPE_INPUT_AUTOCOMPLETE_MULTIPLE_WITH_SETTINGS;
    checkAllOptionsValue = 'Alle auswählen';

    set list(list: Array<any>) {
        super.list = list;

        this.setPredefinedList(list);
    }

    get list() {
        return super.list as Array<any>;
    }

    constructor(config: DynamicInputModelConfig, layout?: DynamicFormControlLayout, type?: string) {
        super(config, layout);

        this.model = this;
        this.type  = type || DYNAMIC_FORM_CONTROL_TYPE_INPUT_AUTOCOMPLETE_MULTIPLE_WITH_SETTINGS;
        this.value = config.value || [];
    }

    public closed(inputElement: HTMLInputElement) {
        this.resetInputElementValue(inputElement);
        this.list = [];
    }

    optionClicked(event, checkBox: MatCheckbox, option, inputElement: HTMLInputElement) {
        if (event.target.classList.contains('mat-option-div')) {
            checkBox.checked = !checkBox.checked;
        }
        event.stopPropagation();
        this.toggleSelection(option, inputElement);
    }

    public toggleSelection(option, inputElement: HTMLInputElement) {
        if (!Array.isArray(this.value)) {
            return;
        }

        if (option === this.checkAllOptionsValue) {
            this.selectAllOptions(inputElement);

            return;
        }

        const index = this.value.findIndex(value => value === option);
        if (index >= 0) {
            this.value.splice(index, 1);
        } else {
            this.value.push(option);
        }

        this.resetInputElementValue(inputElement);
    }

    public removeOption(option) {
        if (Array.isArray(this.value)) {
            const index = this.value.indexOf(option);

            if (index >= 0) {
                this.value.splice(index, 1);
            }
        }
    }

    public triggerAcceptButton(acceptButtonId) {
        document.getElementById(acceptButtonId).click();
    }

    private setPredefinedList(list: any[]) {
        if (list.length) {
            return;
        }

        const predefinedList = this.getAdditional('predefinedList', []);
        if (!predefinedList.length) {
            return;
        }

        super.list = predefinedList;
    }

    private resetInputElementValue(inputElement: HTMLInputElement) {
        inputElement.value = ''
    }

    private selectAllOptions(inputElement: HTMLInputElement) {
        this.list$.pipe(
            take(1),
            map(list => {
                list.shift();
                this.value = [...new Set([...this.value as Array<any>, ...list])];

                this.closed(inputElement);
            })
        ).subscribe()
    }
}
