export enum Comparison {
    EQUAL            = 'eq',
    NOT_EQUAL        = 'neq',
    LESS             = 'lt',
    LESS_OR_EQUAL    = 'lte',
    GREATER          = 'gt',
    GREATER_OR_EQUAL = 'gte',
    IN               = 'in',
    NOT_IN           = 'notIn',
    IS_NULL          = 'isNull',
    IS_NOT_NULL      = 'isNotNull',
    CONTAINS         = 'contains',
    STARTS_WITH      = 'startsWith',
    ENDS_WITH        = 'endsWith',
}
