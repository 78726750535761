import {Injectable} from '@angular/core';

import {QueryFilter as BaseQueryFilter} from '../../../filter-main-panel/filters/query/query.filter';

@Injectable()
export class QueryFilter extends BaseQueryFilter {

    protected initMainModel(): void {
    }
}

