import {Injectable} from '@angular/core';
import {DynamicDatePickerModel, DynamicSelectModel} from '@ng-dynamic-forms/core';

import {ListFilter} from '../../models/filter';

@Injectable()
export class DateFilterHelper {

    public static disableDatepicker(id: string): void {
        const datepicker           = document.querySelector(`#${id}`).closest('dynamic-material-datepicker');
        const datepickerPredefined = datepicker.closest('dynamic-material-form-control').nextSibling as HTMLElement;

        datepicker.querySelector('mat-form-field').classList.add('mat-form-field-disabled');
        datepicker.querySelector('mat-datepicker-toggle > button').setAttribute('disabled', 'disabled');

        datepickerPredefined.querySelectorAll('mat-chip').forEach((matChip) => {
            matChip.classList.add('without-pointer-events');
            matChip.classList.add('mat-chip-disabled');
        });
    }

    public static enableDatepicker(id: string): void {
        const datepicker           = document.querySelector(`#${id}`).closest('dynamic-material-datepicker');
        const datepickerPredefined = datepicker.closest('dynamic-material-form-control').nextSibling as HTMLElement;

        datepicker.querySelector('mat-form-field').classList.remove('mat-form-field-disabled');
        datepicker.querySelector('mat-datepicker-toggle > button').removeAttribute('disabled');

        datepickerPredefined.querySelectorAll('mat-chip').forEach((matChip) => {
            matChip.classList.remove('without-pointer-events');
            matChip.classList.remove('mat-chip-disabled');
        });
    }

    public static addPredefinedClickListener(filter: ListFilter, model: DynamicDatePickerModel, comparisonModel: DynamicSelectModel<any>) {
        document.querySelectorAll(`[ng-reflect-name$="${filter.field}-pd"] mat-chip`).forEach((matChip) => {
            matChip.addEventListener('click', (event) => {
                const chipText       = matChip.querySelector('span').textContent;
                const preDefinedItem = filter.preDefined.find((i) => i['label'] === chipText);
                const value: any     = preDefinedItem['value'];

                if (value === 'until_today') {
                    comparisonModel.value = 'lte';
                    model.value           = new Date();
                } else {
                    comparisonModel.value = 'eq';
                    model.value = new Date(Date.parse(value));
                }
            }, false);
        })
    }

}
