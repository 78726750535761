import {ApiRoute} from '../../services/api-router.service';

export class MenuItem {
    title                  = '';
    url: string            = null;
    icon                   = '';
    iconClass              = '';
    customIcon             = null;
    items: Array<MenuItem> = [];
    parent: MenuItem;
    active                 = false;
    aclRoute: ApiRoute;

    constructor() {
    }

    setTitle(title: string) {
        this.title = title;

        return this;
    }

    setUrl(url: string) {
        this.url = url;

        return this;
    }

    setIcon(icon: string) {
        this.icon = icon;

        return this;
    }

    setIconClass(iconClass: string) {
        this.iconClass = iconClass;

        return this;
    }

    setCustomIcon(iconName: string, extension: string = 'svg') {
        this.customIcon = iconName + '.' + extension;

        return this;
    }

    activate() {
        this.active = true;
    }

    deactivate() {
        this.active = false;
    }

    addItem(item: MenuItem) {
        item.parent = this;
        this.items.push(item);

        return this;
    }

    getItems() {
        return this.items;
    }

    hasItems() {
        return !!this.items.length;
    }

    setACLRoute(route: ApiRoute) {
        this.aclRoute = route;

        return this;
    }

    getAclRoute() {
        return this.aclRoute;
    }

}
