import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BulkActionAfterExecuteModel, BulkActionModel} from './models/bulk-action.model';
import {BulkActionsService} from './bulk-actions.service';
import {ngIfAnimation} from '../../animations';
import {SuccessResponse} from '../../components/response';
import {Subject, Subscription} from 'rxjs';
import {take} from 'rxjs';
import {Crud} from '@shared/components/crud/crud.interface';

@Component({
    selector:    'app-bulk-actions',
    templateUrl: './bulk-actions.component.html',
    styleUrls:   ['./bulk-actions.component.scss'],
    animations:  [ngIfAnimation]
})
export class BulkActionsComponent implements OnInit, OnDestroy {
    @Output() afterExecute: EventEmitter<BulkActionAfterExecuteModel> = new EventEmitter<BulkActionAfterExecuteModel>();
    @Output() beforeExecute: EventEmitter<BulkActionModel>            = new EventEmitter<BulkActionModel>();
    @Output() onShowNotification: EventEmitter<any>                   = new EventEmitter<any>();
    @Input() bulkActionItems                                          = new Map();
    @Input() notDisabledBulkActionItemsCount: number;
    @Input() actions: Array<BulkActionModel>;
    @Input() confirm: Subject<BulkActionModel>;

    confirmSubscriber: Subscription;

    constructor(private bulkActionsService: BulkActionsService) {
    }

    ngOnInit(): void {
        this.createConfirmSubscription();
    }

    ngOnDestroy() {
        if (this.confirmSubscriber) {
            this.confirmSubscriber.unsubscribe();
        }
    }

    processAction(action: BulkActionModel) {
        if (action.confirm) {
            this.beforeExecute.emit(action);
        } else {
            this.executeAction(action)
        }
    }

    executeAction(action: BulkActionModel) {
        const ids = Array.from(this.bulkActionItems.keys())
        this.bulkActionsService.executeAction(action, ids)
            .pipe(take(1))
            .subscribe((data: SuccessResponse<Crud>) => {
                const afterExecute: BulkActionAfterExecuteModel = {
                    ids:  ids,
                    data: data.data
                };

                this.afterExecute.emit(afterExecute);

                if (data['messages']) {
                    this.onShowNotification.emit(data.messages);
                }
            });
    }

    private createConfirmSubscription() {
        if (this.confirm) {
            this.confirmSubscriber = this.confirm.asObservable().subscribe((action: BulkActionModel) => {
                this.executeAction(action);
            });
        }
    }
}
