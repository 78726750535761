import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {ListTableComponent} from './list-table.component';
import {RouterModule} from '@angular/router';
import {ControlNameDirective} from './directives/control-name.directive';
import {MatSortMultipleDirective} from '../../directives/material/sort.directive';
import {SharedPipesModule} from '../../pipes/shared-pipes.module';
import {ListFilterService} from '../list-filter/list-filter.service';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatDividerModule} from '@angular/material/divider';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {BulkActionsModule} from '../bulk-actions/bulk-actions.module';
import {DataNotFoundModule} from '../../components/data-not-found/data-not-found.module';
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        MatTableModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatDividerModule,
        MatSortModule,
        SharedPipesModule,
        BulkActionsModule,
        MatCheckboxModule,
        DataNotFoundModule,
        DragDropModule
    ],
    exports:      [
        CommonModule,
        TranslateModule,
        ListTableComponent,
        ControlNameDirective,
        MatTableModule,
        MatButtonModule,
        MatIconModule,
        MatSortModule,
        MatTooltipModule,
        MatSortMultipleDirective
    ],
    declarations: [
        ListTableComponent,
        ControlNameDirective,
        MatSortMultipleDirective
    ],
    providers:    [
        ListFilterService
    ]
})
export class ListTableModule {
}
