import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BulkActionsComponent} from './bulk-actions.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {BulkActionsService} from './bulk-actions.service';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
    ],
    declarations: [BulkActionsComponent],
    exports: [BulkActionsComponent],
    providers: [BulkActionsService]
})
export class BulkActionsModule { }
