import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {DynamicDatePickerModel, DynamicFormModel, DynamicFormService} from '@ng-dynamic-forms/core';
import {UntypedFormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';

import * as fromListFilter from '../../../../store/list-filter/index';
import {ListFilter} from '../models/filter';
import {FormModelFactory} from './form/form-model-factory';
import {FormModelFactory as FormModelFactoryMain} from '../filter-main-panel/form/form-model-factory';
import {RequestService} from '@shared/services/request.service';
import {ListFilterService} from '../list-filter.service';
import {Filter} from '../filter-main-panel/filters';

@Component({
    selector: 'filter-right-panel',
    templateUrl: './filter-right-panel.component.html',
    styleUrls: ['./filter-right-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FilterRightPanelComponent implements OnChanges {
    @Output() onFilterClose: EventEmitter<any> = new EventEmitter<any>();
    @Input() title = '';
    @Input() introText = '';
    @Input() filters: Array<ListFilter> = [];
    @Input() show = null;

    formModel: DynamicFormModel = [];
    formGroup: UntypedFormGroup;

    constructor(
        private listFilterService: ListFilterService,
        private storeFilter$: Store<fromListFilter.State>,
        public  formService: DynamicFormService,
        public  requestService: RequestService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['filters']) {
            this.initModel();
            this.initFiltersFormControls();
        }
    }

    initModel() {
        if (!this.filters.length) {
            return;
        }

        this.formModel = FormModelFactory.create(this.listFilterService, this.filters, this.storeFilter$, this.requestService);
        this.formGroup = this.formService.createFormGroup(this.formModel);
    }

    onSubmit() {
        FormModelFactory.getFilters().forEach(filterRightPanel => {
            this.setValueToMainFilters(filterRightPanel);

            filterRightPanel.accept(false);
        });

        this.listFilterService.getFiltersChangeEmitter().emit();
        this.onClose();
    }

    onClose() {
        this.onFilterClose.emit(true);
    }

    onFocus($event: FocusEvent | any) {
        if ($event.model instanceof DynamicDatePickerModel) {
            this.openDatePickerDialog($event);
        }
    }

    private openDatePickerDialog($event: FocusEvent | any) {
        $event.$event.target.closest('dynamic-material-form-control')
            .querySelector('mat-datepicker-toggle > button').click();
    }

    private initFiltersFormControls() {
        setTimeout(() => {
            this.addFieldClassToSelect();
            this.addGroupClassToSelectCheckbox();
            this.addMarkClassToDateRange();
        }, 0);
    }

    private addFieldClassToSelect() {
        document.querySelectorAll('mat-chip-list').forEach((element) => {
            const control = element.closest('dynamic-material-form-control');

            if (control) {
                control.classList.add(element.getAttribute('ng-reflect-name'));
            }
        });
    }

    private addGroupClassToSelectCheckbox() {
        document.querySelectorAll('[id$="-field-check-all"]').forEach((element) => {
            element.closest('div[role="group"]').classList.add('multiple-select-group');
        });
    }

    private addMarkClassToDateRange() {
        document.querySelectorAll('dynamic-material-form-control').forEach((formControl) => {
            const datePickerControls = formControl.querySelectorAll('dynamic-material-datepicker');

            if (datePickerControls.length === 2) {
                datePickerControls.forEach((datepicker) => {
                    datepicker.closest('dynamic-material-form-control')
                        .classList.add('datepicker-range-control');
                })
            }
        });
    }

    private setValueToMainFilters(filterRightPanel: Filter): void {
        FormModelFactoryMain.getFilters().forEach(filterMainPanel => {
            if (filterRightPanel.getFilter().field === filterMainPanel.filter.field) {
                filterMainPanel.setValue(filterRightPanel.getValue());

                filterMainPanel.accept(false);
            }
        });
    }
}
