<section class="list-filter" [class.disabled]="loadingProgress$ | async">
    <filter-status-panel
        [filterGroups]="filterGroups"
    >
    </filter-status-panel>

    <mat-divider></mat-divider>

    <form *ngIf="formGroup" [formGroup]="formGroup" class="filters-main-form">
        <dynamic-material-form
            [group]="formGroup"
            [model]="formModel"
            (click)="onClick($event)"
            (keydown)="onKeyPress($event)"
            (focus)="onFocus($event)"
        >
            <ng-template modelType="BUTTON_POPUP_CLOSE">
                <button mat-mini-fab color="white" class="popup-button-close" (click)="closeFilterPopup($event)">
                    <mat-icon>clear</mat-icon>
                </button>
            </ng-template>

            <ng-template modelType="BUTTON_POPUP_ACCEPT" let-id="id">
                <button [id]="id" mat-button color="primary" class="popup-button-accept"
                        (click)="closeFilterPopup($event)">
                    ÜBERNEHMEN
                </button>
            </ng-template>

            <ng-template modelType="SLIDER_RANGE"
                         let-model="model"
                         let-fromValue="fromValue"
                         let-toValue="toValue"
                         let-options="options"
                         let-sliderValueChange="sliderValueChange">
                <div class="slider">
                    <ngx-slider [value]="fromValue"
                                [highValue]="toValue"
                                [options]="options"
                                (userChange)="sliderValueChange.call(model, $event)">
                    </ngx-slider>
                </div>
            </ng-template>

            <ng-template modelType="INPUT_AUTOCOMPLETE_MULTIPLE_WITH_SETTINGS"
                         let-model="model"
                         let-options$="list$"
                         let-className="id"
                         let-acceptButtonId="additional.acceptButtonId"
                         let-closed="closed"
                         let-selectedOptions="value"
                         let-removeOption="removeOption"
                         let-optionClicked="optionClicked"
                         let-toggleSelection="toggleSelection"
                         let-triggerAcceptButton="triggerAcceptButton"
                         let-placeholder="placeholder">
                <mat-form-field [ngClass]="className">
                    <mat-chip-list #chipList>
                        <mat-chip
                            *ngFor="let selectedOption of ((selectedOptions | typeof) === 'string' ? [] : selectedOptions)"
                            [selectable]="true"
                            [removable]="true"
                            (removed)="removeOption.call(model, selectedOption)"
                        >
                            {{selectedOption}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <input type="text" class="mat-input-element"
                               #inputElement
                               matInput
                               [value]="!model.multiple ? model.value : ''"
                               [matAutocomplete]="auto"
                               [matChipInputFor]="chipList"
                               [placeholder]="placeholder">
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (closed)="closed.call(model, inputElement)">
                        <ng-container *ngIf="options$ | async as options">
                            <ng-container *ngFor="let option of options">
                                <mat-option *ngIf="option" [value]="option">
                                    <div class="mat-option-div"
                                         (click)="optionClicked.call(model, $event, ref, option, inputElement)">
                                        <mat-checkbox #ref
                                                      (change)="toggleSelection.call(model, option, inputElement)"
                                                      (click)="$event.stopPropagation()"
                                                      [matTooltip]="option"
                                                      [matTooltipDisabled]="option.length < optionMaxLength"
                                                      matTooltipPosition="right"
                                        >
                                            {{ option }}
                                        </mat-checkbox>
                                    </div>
                                </mat-option>
                            </ng-container>
                            <div *ngIf="options.length && options[0] !== null" class="sticky-accept-button">
                                <button mat-button color="primary"
                                        (click)="triggerAcceptButton.call(model,acceptButtonId)">
                                    ÜBERNEHMEN
                                </button>
                            </div>
                        </ng-container>

                    </mat-autocomplete>
                </mat-form-field>
            </ng-template>

        </dynamic-material-form>

        <mat-icon class="filter-panel-button" (click)="toggleRightPanelFilter()">more_vert</mat-icon>

        <ng-container *ngIf="hasSaveFilterButton">
            <mat-icon class="save-filter-button" (click)="toggleSaveFilterForm()">save</mat-icon>
            <ng-container *ngIf="showSaveFilterForm">
                <ng-container *ngTemplateOutlet="saveFilterTemplateOutlet"></ng-container>
            </ng-container>
        </ng-container>
    </form>

    <mat-divider></mat-divider>

</section>

<filter-right-panel *ngIf="formModel.length"
                    [show]="showRightPanelFilter"
                    [title]="rightFilterTitle"
                    [introText]="rightFilterIntroText"
                    [filters]="rightPanelFilters"
                    (onFilterClose)="toggleRightPanelFilter()"
></filter-right-panel>

