import {Injectable} from '@angular/core';
import {DynamicDatePickerModel} from '@ng-dynamic-forms/core';

import {ListFilter} from '../../models/filter';
import {PreDefined} from '@shared/components/crud/crud.interface';

@Injectable()
export class DateRangeFilterHelper {

    public static addPredefinedClickListener(filter: ListFilter, modelFrom: DynamicDatePickerModel, modelTo: DynamicDatePickerModel) {
        document.querySelectorAll(`[ng-reflect-name$="${filter.field}-pdr"] mat-chip`).forEach((matChip) => {
            matChip.addEventListener('click', (event) => {
                const chipText = matChip.querySelector('span').textContent;
                const preDefinedItem: PreDefined = filter.preDefined.find((i) => i['label'] === chipText);

                modelFrom.max = null;
                modelTo.min = null;


                setTimeout(() => {
                    modelFrom.value = new Date(preDefinedItem.value.lte);
                    modelTo.value = new Date(preDefinedItem.value.gte);
                }, 0);
            }, false);
        });
    }

}
