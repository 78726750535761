import {Observable, catchError, finalize, mergeMap, throwError as observableThrowError} from 'rxjs';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from '../auth/auth.service';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {HIDE_GLOBAL_PROGRESS_BAR, SHOW_GLOBAL_PROGRESS_BAR} from '../store/layout/progress-bar/progress-bar';

export const INTERCEPTOR_SKIP_HEADER = 'X-Skip-Interceptor';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(public auth: AuthService, private store$: Store<any>) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.store$.dispatch({type: SHOW_GLOBAL_PROGRESS_BAR});

        return next.handle(
            this.getHttpRequest(request)
        ).pipe(
            catchError(error => {
                if (error.status === 401) {
                    return this.auth.getRefreshedAuthHeaders(request).pipe(mergeMap((headers: any) => {

                        const contentType = request.headers.getAll('Content-Type');
                        if (contentType && contentType[0] === 'multipart/form-data') {
                            return observableThrowError(error);
                        }
                        return next.handle(request.clone({
                            setHeaders: headers
                        }));
                    }));
                }

                return observableThrowError(error);
            }),
            finalize(() => {
                this.store$.dispatch({type: HIDE_GLOBAL_PROGRESS_BAR});
            })
        );
    }

    private getHttpRequest(req: HttpRequest<any>): HttpRequest<any> {
        if (req.headers.has(INTERCEPTOR_SKIP_HEADER)) {
            return req.clone({
                headers: new HttpHeaders({
                    'Authorization': 'Bearer ' + this.auth.getToken()
                })
            })
        } else {
            return req.clone({
                headers: new HttpHeaders(this.auth.getAuthHeaders(req))
            })
        }
    }
}
