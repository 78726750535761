import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ApiRoute, ApiRouterService} from './api-router.service';
import {APP_CONFIG} from '../../app-config/app-config.constants';
import {IAppConfig} from '../../app-config/app-config.interface';

export interface AvailableApiEndpoint {
    url: string
    methods: string[]
}

@Injectable()
export class PermissionService {

    private permissions: AvailableApiEndpoint[] = [];

    constructor(
        protected http: HttpClient,
        @Inject(APP_CONFIG) protected config: IAppConfig
    ) {
    }

    public isInitialized() {
        if (this.isInDevelopment()) {
            return true;
        }

        return this.permissions.length;
    }

    public isPermitted(apiRoute: ApiRoute) {
        if (this.isInDevelopment()) {
            return true;
        }

        return this.findInPermitted(apiRoute['url'], apiRoute['method'].toUpperCase());
    }

    public initPermissions() {
        if (this.isInDevelopment()) {
            return this.permissions = [];
        }

        if (this.isInitialized()) {
            return this.permissions;
        }

        const permissionsRoute = ApiRouterService.getAdminRoute('permissionAvailableEndpoints');
        this.http.get(permissionsRoute).subscribe((data: Array<AvailableApiEndpoint>) => {
            this.permissions = data;
        });
    }

    private findInPermitted(url: string, method: string) {
        return this.getPermissions().some((apiRoute: AvailableApiEndpoint) => {
            return apiRoute['url'].endsWith(url) && apiRoute['methods'].includes(method);
        });
    }

    private getPermissions() {
        return this.permissions;
    }

    private isInDevelopment() {
        return this.config.ENVIRONMENT === 'development';
    }

}
