import {DynamicFormControlLayout, DynamicInputModel, DynamicInputModelConfig} from '@ng-dynamic-forms/core';

export class DynamicInputAutocompleteMultipleModel extends DynamicInputModel {
    private fullList: string[];

    constructor(config: DynamicInputModelConfig, fullList?: string[], layout?: DynamicFormControlLayout) {
        super(config, layout);

        this.fullList = fullList;
    }

    set value(value: string[] | null) {
        value = value?.filter((item) => {
            return this.fullList.includes(item);
        });

        super.value = value;
    }

    get value(): string[] | null {
        return super.value as string[];
    }
}
