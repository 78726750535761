import {DynamicFormControlModel} from '@ng-dynamic-forms/core';
import {UntypedFormGroup} from '@angular/forms';
import {serverValidator} from './server-validator';

export class ServerValidation {

    static validateControls(errors: any, formModel: DynamicFormControlModel[], formGroup: UntypedFormGroup) {
        for (let controlName in errors) {
            ServerValidation.validateControl(errors, controlName, formModel, formGroup);
        }
    }

    static validateControl(errors: any, currentControlName: string, formModel: DynamicFormControlModel[], formGroup: UntypedFormGroup) {
        ServerValidation.reviewAllControls(errors, formModel, formGroup);

        if (!errors[currentControlName]) {
            return;
        }

        let messages = [];
        for (let error of errors[currentControlName]) {
            messages.push(error.message);
        }

        formModel.forEach(function (control: DynamicFormControlModel) {
            if (currentControlName == control.name) {
                if (!('serverValidator' in control['validators'])) {
                    return;
                }
                control.errorMessages['serverValidator'] = messages.join(' ');
            }
        });

        formGroup.get(currentControlName).setErrors({serverValidator: true});
    }

    static reviewAllControls(errors: any, formModel: DynamicFormControlModel[], formGroup: UntypedFormGroup) {
        formModel.forEach(function (control: DynamicFormControlModel) {
            if (!errors[control.name]) {
                control.errorMessages['serverValidator'] = '';
                formGroup.get(control.name).setErrors(null);
            }

            if (formGroup.get(control.name).valid && errors[control.name] ) {
                let messages = [];
                for (let error of errors[control.name]) {
                    messages.push(error.message);

                    control.errorMessages['serverValidator'] = messages.join(' ');
                    formGroup.get(control.name).setErrors({serverValidator: true});
                }
            }
        });
    }
}
