import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {AuthService} from '../../../auth/auth.service';
import {UsersService} from '../../services/users.service';
import {slideInOutAnimationHigh} from '../../animations'

import {Router} from '@angular/router';

@Component({
    selector: 'app-qr-code-verification',
    templateUrl: './qr-code-verification.component.html',
    styleUrls: ['./qr-code-verification.component.scss'],
    providers: [AuthService, UsersService],
    animations: [slideInOutAnimationHigh]
})
export class QrCodeVerificationComponent implements OnInit, AfterViewInit {
    @ViewChild('qrCodeInput', {static: false}) qrCodeIdInput: ElementRef;
    @Output() showError = false;
    @Output() isLoading = false;
    @Output() qrCodeIsValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    @Input() code: string;
    @Input() userName = '';
    @Input() type = 'switch';

    constructor(private authService: AuthService,
                private usersService: UsersService,
                private router: Router) {
    }

    @HostBinding('@slideInOutAnimation') get slideInOutAnimation() {
        return '';
    }

    ngOnInit() {
    }

    onPaste() {
        // this.switch(this.code);
    }

    codeChanged($event) {
        this.showError = false;
    }

    switch(barCode: string) {
        if (barCode) {
            const barCodeArray = barCode.split('-');
            const userId = barCodeArray[0];
            const internalClientToken = barCodeArray[1];
            const previouslyLoggedUser = this.usersService.getPreviouslyLoggedUser(userId);

            if (previouslyLoggedUser && previouslyLoggedUser.user) {
                const verificationToken = this.authService.createSha1Token(internalClientToken + previouslyLoggedUser.user.salt);
                if (verificationToken === previouslyLoggedUser.user.verification) {

                    switch (this.type) {
                        case 'switch':
                            this.usersService.switchUserById(userId);
                            this.qrCodeIsValid.emit(true);
                            break;

                        case 'logout':
                            this.usersService.removeUserFromLoggedUsersList(userId);
                            if (this.usersService.isNoUserLoggedIn()) {
                                this.router.navigate(['login-logout'])
                            }
                            if (this.usersService.isOnlyOneUserLogged()) {
                                this.usersService.switchToLastLoggedUser();
                            }
                            this.qrCodeIsValid.emit(true);
                            break;

                        case 'operator1':
                        case 'operator2':
                            this.usersService.setOperator(this.type, previouslyLoggedUser.user);
                            this.qrCodeIsValid.emit(true);
                            break;

                    }
                } else {
                    this.showError = true;
                }
            } else {
                this.showError = true;
            }
        }
    }

    ngAfterViewInit() {
        this.qrCodeIdInput.nativeElement.focus();
    }

    close() {
        this.onClose.emit(true);
    }
}
