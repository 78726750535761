import {Injectable} from '@angular/core';

import {DateRangeFilter as BaseDateRangeFilter} from '../../../filter-main-panel/filters/date-range/date-range.filter';

@Injectable()
export class DateRangeFilter extends BaseDateRangeFilter {

    protected initMainModel(): void {
    }

}
