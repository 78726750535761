import {Injectable} from '@angular/core';

import {NumberRangeFilter as BaseNumberRangeFilter} from '../../../filter-main-panel/filters/number-range/number-range.filter';

@Injectable()
export class NumberRangeFilter extends BaseNumberRangeFilter {

    protected initMainModel(): void {
    }
}
