import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {DynamicDatePickerModel, DynamicFormModel, DynamicFormService} from '@ng-dynamic-forms/core';
import {Observable, Subscription, delay, filter} from 'rxjs';
import {select, Store} from '@ngrx/store';

import * as fromCrud from '../../../store/crud/index';
import * as fromListFilter from '../../../store/list-filter/index';
import {Filter} from '../../components/crud/crud.interface';
import {FILTER_DEFAULT_GROUPS, ListFilter} from './models/filter';
import {FormModelFactory} from './filter-main-panel/form/form-model-factory';
import {routerTransition} from '../../../router.animations';
import {RequestService} from '../../services/request.service';
import {FiltersState} from '../../../store/crud';
import {ListFilterService} from './list-filter.service';
import {cloneDeep, intersection} from 'lodash';


@Component({
    selector:      'app-list-filter',
    templateUrl:   './list-filter.component.html',
    styleUrls:     ['./list-filter.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations:    [routerTransition()],
    providers:     [
        FormModelFactory
    ]
})
export class ListFilterComponent implements OnInit, OnDestroy {
    static readonly mainFiltersCount = 5;
    public readonly optionMaxLength  = 40;

    @Input() filterGroups         = FILTER_DEFAULT_GROUPS;
    @Input() rightFilterTitle     = '';
    @Input() rightFilterIntroText = '';
    @Input() hasSaveFilterButton  = false;
    @Input() saveFilterTemplateOutlet: TemplateRef<any>;

    showRightPanelFilter = null;
    showSaveFilterForm   = false;

    filterDefaultGroups        = FILTER_DEFAULT_GROUPS;
    filters: Array<ListFilter> = [];
    rightPanelFilters: Array<ListFilter> = [];

    filtersStore$: Observable<{ filters: Array<Filter>, usedFilters: {} }>;
    filtersStoreSubscriber: Subscription;

    formModel: DynamicFormModel = [];
    formGroup: UntypedFormGroup;

    loadingProgress$: Observable<boolean>;

    constructor(
        private listFilterService: ListFilterService,
        private store$: Store<fromCrud.State>,
        private storeFilter$: Store<fromListFilter.State>,
        public formService: DynamicFormService,
        public requestService: RequestService) {
    }

    ngOnInit(): void {
        this.loadingProgress$ = this.store$.pipe(
            delay(0),
            select(state => state['layout'].progress.showGlobal)
        );

        this.subscribeToFiltersStore();
    }

    ngOnDestroy() {
        this.filtersStoreSubscriber.unsubscribe();
    }

    onClick($event) {
        const target = $event.target as HTMLTextAreaElement;
        this.openFilterPopup(target);
    }

    onFocus($event: FocusEvent | any) {
        if ($event.model instanceof DynamicDatePickerModel) {
            this.openDatePickerDialog($event);
        }
    }

    onKeyPress($event) {
        this.submitOnEnterKey($event);
    }

    toggleRightPanelFilter() {
        this.showRightPanelFilter = !this.showRightPanelFilter;
    }

    toggleSaveFilterForm() {
        this.showSaveFilterForm = !this.showSaveFilterForm;
    }

    private submitOnEnterKey($event) {
        if ($event.key !== 'Enter') {
            return;
        }

        const target = $event.target as HTMLElement;
        const popup  = target.closest('[id$="-popup-group"]');

        if (popup) {
            const acceptButton = popup.querySelector('.popup-button-accept') as HTMLElement;
            acceptButton.click();
        }
    }

    private initMainFilters(filtersState: FiltersState) {
        filtersState.filters.forEach((filter) => {
            return this.filters.push(new ListFilter(filter, filtersState.usedFilters))
        });
    }

    private initRightPanelFilters() {
        this.rightPanelFilters = cloneDeep(this.filters);

        this.rightPanelFilters.forEach(filter => {
            filter.inBothPresentations = true;
        });
    }

    private initModel() {
        this.formModel = FormModelFactory.create(this.listFilterService, this.filters, this.storeFilter$, this.requestService);
        this.formGroup = this.formService.createFormGroup(this.formModel);
    }

    private subscribeToFiltersStore() {
        this.filtersStore$ = this.store$.pipe(select(fromCrud.getFilters));

        this.filtersStoreSubscriber = this.filtersStore$
            .pipe(
                filter(filtersStore => {
                    return filtersStore.filters.length > 0
                })
            )
            .subscribe((filtersStore) => {
                const filters = filtersStore.filters.filter(filter => {
                    return (!filter.groups && this.filterGroups === this.filterDefaultGroups) || intersection(filter.groups, this.filterGroups).length;
                });

                if (filters.length > 0) {
                    this.filters           = [];
                    this.rightPanelFilters = [];

                    this.initFilters(filtersStore);
                    this.initModel();
                }
            });
    }

    private initFilters(filters) {
        filters = filters || {};

        this.initMainFilters(filters);
        this.initRightPanelFilters();
    }

    private openFilterPopup(target: HTMLTextAreaElement) {
        if (target.closest('[id$="-popup-group"]') || target.closest('.list-filter').classList.contains('disabled')) {
            return;
        }

        document.querySelectorAll('[id$="-comparison"]').forEach((comparison) => {
            comparison.closest('mat-form-field').classList.add('half-width')
        });

        document.querySelectorAll('mat-chip-list').forEach((element) => {
            if (element.closest('dynamic-material-form-control')) {
                element.closest('dynamic-material-form-control').classList
                    .add(element.getAttribute('ng-reflect-name'));
            }
        });

        document.querySelectorAll('[id$="-popup-group"]')
            .forEach((popup) => popup.classList.remove('show'));

        if (!target.closest('dynamic-material-form-control')?.nextSibling) {
            return;
        }

        const nextSibling = target.closest('dynamic-material-form-control').nextSibling as HTMLTextAreaElement;
        if (nextSibling && nextSibling.nodeType === Node.ELEMENT_NODE) {
            const currentPopup = nextSibling.querySelector('[id$="-popup-group"]');

            currentPopup.classList.add('show');

            const inputElement = currentPopup.querySelector('.mat-input-element') as HTMLElement;
            if (inputElement) {
                setTimeout(() => inputElement.click(), 0);
            }
        }
    }

    private openDatePickerDialog($event: FocusEvent | any) {
        $event.$event.target.closest('dynamic-material-form-control')
            .querySelector('mat-datepicker-toggle > button').click();
    }

    public closeFilterPopup($event) {
        const target = $event.target as HTMLTextAreaElement;
        target.closest('[id$="-popup-group"]').classList.remove('show');
    }

}
