import {DynamicInputModel} from '@ng-dynamic-forms/core';
import {ValidatorsBuilder} from '../validators/validators-builder';
import {FormBuilder} from '../form-builder';

export class InputControl {
    field: any;
    formBuilder: FormBuilder;

    constructor(formBuilder: FormBuilder, field: any) {
        this.field = field;
        this.formBuilder = formBuilder;
    }

    build() {
        const validatorBuilder = (new ValidatorsBuilder(this.field.constraints, this.formBuilder)).build();

        return new DynamicInputModel({
            id: this.field.name,
            label: this.field.label,
            value: this.formBuilder.modelData[this.field.name],
            hint: this.field.help,
            placeholder: this.field.placeholder,
            readOnly: this.field.attributes?.readonly,
            inputType: this.field.type,
            maxLength: this.getMaxLengthValue(validatorBuilder),
            minLength: this.getMinLengthValue(validatorBuilder),
            pattern: this.getPatternValue(validatorBuilder),
            validators: validatorBuilder.getValidators(),
            asyncValidators: validatorBuilder.getAsyncValidators(),
            errorMessages: validatorBuilder.getErrorMessages()
        });
    }

    private getMaxLengthValue(validatorBuilder: ValidatorsBuilder) {
        return validatorBuilder.getValidators()['maxLength'] ?? null;
    }

    private getMinLengthValue(validatorBuilder: ValidatorsBuilder) {
        return validatorBuilder.getValidators()['minLength'] ?? null;
    }

    private getPatternValue(validatorBuilder: ValidatorsBuilder) {
        return validatorBuilder.getValidators()['pattern'] ?? null;
    }
}
