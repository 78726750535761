import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AmountConverterPipe} from './amount.pipe';
import {SafePipe} from './safe.pipe';
import {KeysPipe} from './keys.pipe';
import {LinkifyPipe} from './linkify.pipe';
import {TypeofPipe} from './typeof.pipe';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [AmountConverterPipe, SafePipe, KeysPipe, LinkifyPipe, TypeofPipe],
    exports: [AmountConverterPipe, SafePipe, KeysPipe, LinkifyPipe, TypeofPipe]
})
export class SharedPipesModule {
}
