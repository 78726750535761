import {DynamicPathable, DynamicSelectModel} from '@ng-dynamic-forms/core';
import {Injectable} from '@angular/core';

import {DynamicInputAutocompleteMultipleWithSettingsModel} from '../../form/model';
import {Filter} from '../filter';
import {SelectMultipleFilterHelper} from '../../../helpers/filters/select-multiple-filter.helper';
import {Store} from '@ngrx/store';
import * as fromCrud from '../../../../../../store/list-filter';
import {ListFilter} from '../../../models/filter';
import {FilterPresentationInterface} from '../filter.presentation';
import {ListFilterService} from '../../../list-filter.service';
import {RequestService} from '@shared/services/request.service';
import {skip} from 'rxjs';
import {Comparison} from '@shared/enums/comparison.enum';

@Injectable()
export class SelectMultipleAutocompleteFilter extends Filter {
    static readonly modelType: string = 'multiselect';
    readonly modelType: string        = SelectMultipleAutocompleteFilter.modelType;

    public list: any[];
    protected autocompleteWithSettingsModel: DynamicInputAutocompleteMultipleWithSettingsModel | any;
    public requestService: RequestService;

    protected readonly notMultipleModelComparisons = [Comparison.CONTAINS];

    constructor(
        listFilterService: ListFilterService,
        store$: Store<fromCrud.State>,
        filter: ListFilter,
        filterPresentation: FilterPresentationInterface,
        requestService: RequestService
    ) {
        super(listFilterService, store$, filter, filterPresentation, SelectMultipleAutocompleteFilter.modelType);

        this.requestService = requestService;
    }

    public getModels(): Array<DynamicPathable> {
        return [this.getAutocompleteWithSettingsModel()];
    }

    public accept(emitChanges = true, inputValue = null): void {
        const values         = this.getAutocompleteValues(inputValue);
        const mainModelValue = Array.isArray(values) ? values : [values];

        this.updateMainModelValue(mainModelValue, this.getAutocompletePrefix());
        this.applyFilter(values, emitChanges);
    }

    public setValue(value: {}): void {
        this.autocompleteWithSettingsModel.value = value['autocompleteWithSettingsModel'];
    }

    public getValue(): {} {
        return {
            'autocompleteWithSettingsModel': this.autocompleteWithSettingsModel.value,
        };
    }

    protected getAutocompleteValues(inputValue = null) {
        if (inputValue) {
            return inputValue;
        }

        let values = [];

        if (this.autocompleteWithSettingsModel.value) {
            values = [...this.autocompleteWithSettingsModel.value];
        }

        return values;
    }

    protected reset(): void {
        super.reset([]);

        this.autocompleteWithSettingsModel.value = [];
    }

    protected initMainModel(): void {
        this.mainModel = new DynamicSelectModel({
            id:          this.filter.field,
            label:       this.filter.label,
            placeholder: this.filter.label,
            multiple:    true,
            filterable:  true,
            disabled:    true,
            options:     this.filter.options,
        });
    }

    protected getAutocompletePrefix(initialValue: any = null): string {
        const value = initialValue || this.autocompleteWithSettingsModel.value;

        if (!(value && value.length > 1)) {
            return '';
        }

        if (!this.autocompleteWithSettingsModel.multiple) {
            return ` ${value}`;
        }

        return `<span class="mat-badge mat-badge-above mat-badge-after mat-badge-medium mat-primary">
                    <span class="mat-badge-content mat-badge-active">${value.length}</span>
                </span>`;
    }

    protected getAutocompleteWithSettingsModel(): DynamicInputAutocompleteMultipleWithSettingsModel {
        if (this.autocompleteWithSettingsModel) {
            return this.autocompleteWithSettingsModel;
        }

        const {initialValues, isMultiple, list} = this.getInitialModelConfig();

        this.autocompleteWithSettingsModel = new DynamicInputAutocompleteMultipleWithSettingsModel({
            id:          this.getAutocompleteWithSettingsModelId(),
            label:       '',
            placeholder: this.filter.label,
            multiple:    isMultiple,
            value:       initialValues,
            list:        list,
            additional:  {
                acceptButtonId: `${this.filter.field}-popup-button-accept`
            },
        });

        if (this.filter.getOptionsLabels()) {
            this.autocompleteWithSettingsModel.additional.predefinedList = this.filter.getOptionsLabels();
        }

        if (initialValues) {
            let values = [];
            if (this.autocompleteWithSettingsModel.value && isMultiple) {
                values = this.autocompleteWithSettingsModel.value;
            }

            this.updateMainModelValue(values, this.getAutocompletePrefix());
            this.applyFilter(this.getInitialValue(), false);
        }

        this.getComparisonModel().valueChanges
            .pipe(
                skip(1)
            )
            .subscribe(() => {
                const isMultiple = this.isMultiple();

                this.autocompleteWithSettingsModel.multiple                  = isMultiple;
                this.autocompleteWithSettingsModel.additional.predefinedList = isMultiple ? this.filter.getOptionsLabels() : [];
                this.autocompleteWithSettingsModel.list                      = [];
                this.autocompleteWithSettingsModel.value                     = [];
            });

        SelectMultipleFilterHelper.bindSearchForAutocomplete(
            this.getFilter().getAutoCompleteUrl(),
            this.getFilter().settings,
            this.autocompleteWithSettingsModel,
            this.requestService
        );

        return this.autocompleteWithSettingsModel;
    }

    protected getInitialValue(): Array<string> | null {
        return this.filter.initialValue
            ? Object.values<Array<string>>(this.filter.initialValue).shift()
            : null;
    }

    protected getAutocompleteWithSettingsModelId() {
        return `${this.filter.field}-field`;
    }

    private getInitialModelConfig() {
        const isMultiple    = this.isMultiple();
        const initialValues = this.getInitialValue();
        const list          = this.filter.getOptionsLabels() && isMultiple ? this.filter.getOptionsLabels() : [];

        return {
            isMultiple:    isMultiple,
            initialValues: initialValues,
            list:          list
        }
    }

    private isMultiple() {
        return !this.notMultipleModelComparisons.includes(this.getComparisonModel().value);
    }
}
