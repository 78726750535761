import {Filter} from '../../filter-main-panel/filters';
import {
    DynamicInputAutocompleteMultipleModel,
    DynamicInputAutocompleteMultipleWithSettingsModel
} from '../../filter-main-panel/form/model';
import {RequestService} from '@shared/services/request.service';
import {HttpParams} from '@angular/common/http';
import {debounce, difference} from 'lodash';
import {QueryFilterHelper} from './query-filter.helper';
import {
    DynamicInputAutocompleteSingleWithRequestModel
} from "@shared/modules/list-filter/filter-main-panel/form/model/input-autocomplete-single-with-request";

export class SelectMultipleFilterHelper {

    public static bindSearch(filter: Filter, model: DynamicInputAutocompleteMultipleModel): void {
        setTimeout(() => {
            if (document.querySelector(`.${model.id}`) === null) {
                return;
            }

            document.querySelector(`.${model.id}`).addEventListener('input', (event) => {
                const target       = event.target as HTMLTextAreaElement;
                const modelControl = target.closest('dynamic-material-form-control.' + model.name);

                if (!modelControl) {
                    return;
                }

                const searched     = target.value.trim().toLowerCase();
                const optionLabels = filter.getFilter().getOptionsLabels();

                if (!searched) {
                    return model.list = optionLabels;
                }

                setTimeout(() => {
                    model.list = optionLabels.filter(label => label.toLowerCase().indexOf(searched) === 0 && !model.value?.includes(label))
                }, 0)
            }, false)
        }, 1000);
    }

    public static bindSearchForAutocomplete(
        autoCompleteUrl: string,
        settings: {},
        model: DynamicInputAutocompleteMultipleWithSettingsModel | DynamicInputAutocompleteSingleWithRequestModel,
        requestService: RequestService,
    ) {
        setTimeout(() => {
            if (document.querySelector(`.${model.id}`) === null) {
                return;
            }

            document.querySelector(`.${model.id}`).addEventListener('input', (event) => {
                const target       = event.target as HTMLTextAreaElement;
                const modelControl = target.closest(`.${model.id}`);

                if (!modelControl || modelControl.classList.contains('search-event')) {
                    return;
                }

                modelControl.classList.add('search-event');

                let searchCallTimer  = null;
                const searchListener = (event: any) => {
                    const target           = event.target as HTMLInputElement;
                    const searched: string = target.value.trim().toLowerCase();

                    if (!SelectMultipleFilterHelper.isSearchPermitted(searched, settings, model)) {
                        if (!searched.length) {
                            model.list = [];
                        }

                        return;
                    }

                    const searchRequest = (requestService, autoCompleteUrl) => {
                        const params = (new HttpParams({encoder: RequestService.getHttpEncoder()})).append('query', searched);

                        requestService.get(autoCompleteUrl, params)
                            .subscribe((list: { [key: number]: string }) => {
                                if (model instanceof DynamicInputAutocompleteSingleWithRequestModel) {
                                    model.values = list;

                                    if (!model.values[model.value as any]) {
                                        model.value = null;
                                    }
                                }
                                const listValues = difference(Object.values(list), model.value);
                                if (listValues.length) {
                                    listValues.unshift(model.checkAllOptionsValue);
                                    model.list = difference(listValues, model.value);
                                } else {
                                    model.list = [null];
                                }
                            });
                    };

                    clearTimeout(searchCallTimer);
                    searchCallTimer = setTimeout(() => searchRequest(requestService, autoCompleteUrl), 500);
                };

                modelControl.addEventListener('input', debounce(searchListener, 500), false);
            }, false)
        }, 1000);
    }

    public static isSearchPermitted(
        searched: string,
        settings: any,
        model: DynamicInputAutocompleteMultipleWithSettingsModel | DynamicInputAutocompleteSingleWithRequestModel,
    ) {
        if (!model.multiple && !settings?.skipMultipleCheck) {
            return false;
        }

        return searched.length >= settings.minLength && searched.length <= settings.maxLength;
    }
}
