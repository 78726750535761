import {AfterViewInit, Directive, Input} from '@angular/core';
import {MatSortable, SortDirection} from '@angular/material/sort';
import {cloneDeep} from 'lodash';

import {ListTableComponent} from '../../modules/list-table/list-table.component';
import {delay, of, take, tap} from 'rxjs';

let _isSorted = function () {
    return this._sort.active.indexOf(this.id) > -1 && typeof this._sort.direction == 'object' &&
        (this._sort.direction[this.id] === 'asc' || this._sort.direction[this.id] === 'desc');
};

let _updateArrowDirection = function () {
    if (this._isSorted()) {
        this._arrowDirection = (typeof this._sort.direction === 'object' ?
            this._sort.direction[this.id] :
            this._sort.direction);
    } else {
        this._arrowDirection = (this.start || this._sort.start);
    }
};

@Directive({
    selector: '[matSortMultiple]',
    exportAs: 'matSortMultiple',
})
export class MatSortMultipleDirective implements AfterViewInit {
    @Input('matSortUsedSortings') usedSortings: {} | null;

    private matSort;
    private originMatSort;
    private isMultipleInUse = false;
    private isSingleInUse = false;
    private isInitialState = true;
    private readonly defaultEntityAlias = 'dea';


    constructor(public hostSel: ListTableComponent) {
    }

    ngAfterViewInit(): void {
        this.bindSortTypeSwitch();

        of(true)
            .pipe(
                take(1),
                delay(0),
                tap(() => {
                    const matSort      = this.hostSel.matSort;
                    this.originMatSort = cloneDeep(matSort);
                    this.matSort       = matSort;

                    if (Object.keys(this.usedSortings).length > 1) {
                        this.transformMatSortToMultiple();
                    }
                })
            ).subscribe()
    }

    transformMatSortToMultiple() {
        if (this.isMultipleInUse) {
            return;
        }

        this.isSingleInUse   = false;
        this.isMultipleInUse = true;

        this.matSort.sortables.forEach((sortable: any) => {
            sortable._isSorted = _isSorted.bind(sortable);
            sortable._updateArrowDirection = _updateArrowDirection.bind(sortable);
        });

        this.defineDirection();

        this.matSort.active    = [];
        this.matSort.direction = {};

        this.matSort.sortables.forEach((sortable: any) => {
            const id = `${this.defaultEntityAlias}.${sortable.id}`;
            if (this.usedSortings[id]) {
                const direction     = this.usedSortings[id].toLowerCase();
                const defaultState  = {toState: 'active'};
                sortable._viewState = direction === 'asc' ? defaultState : {fromState: 'desc', ...defaultState};

                sortable._arrowDirection            = direction;
                this.matSort.direction[sortable.id] = direction;
                this.matSort.active.push(sortable.id)
            }
        });

        this.matSort.sort = this.sort.bind(this);
        this.matSort.isSortDirectionValid = this.isSortDirectionValid.bind(this);
        this.matSort.getNextSortDirection = this.getNextSortDirection.bind(this);

        this.matSort.sortChange.emit({
            active: this.matSort.active,
            direction: this.matSort.direction,
            init: this.isInitialState
        });

        this.isInitialState = false;
    }

    transformMatSortToSingle() {
        if (this.isSingleInUse) {
            return;
        }

        this.isMultipleInUse = false;
        this.isSingleInUse   = true;

        const originSortable: any = this.originMatSort.sortables.values().next().value;

        this.matSort.sortables.forEach((sortable: any) => {
            sortable._isSorted             = originSortable._isSorted.bind(sortable);
            sortable._updateArrowDirection = originSortable._updateArrowDirection.bind(sortable);
        });

        this.matSort.sort                 = this.originMatSort.sort;
        this.matSort.isSortDirectionValid = this.originMatSort.isSortDirectionValid;
        this.matSort.getNextSortDirection = this.originMatSort.getNextSortDirection;
    }


    isSortDirectionValid(direction: SortDirection | { [id: string]: SortDirection }) {
        return typeof direction === 'object' &&
            Object.keys(direction).every((id) => this.matSort.isIndividualSortDirectionValid(direction[id]));
    }

    sort(sortable: MatSortable) {
        if (!Array.isArray(this.matSort.active)) {
            this.matSort.active = [sortable.id];
            this.matSort.direction[sortable.id] = sortable.start ? sortable.start : this.matSort.start;
        } else {
            const index = this.matSort.active.indexOf(sortable.id);

            if (index === -1) {
                this.matSort.active.push(sortable.id);
                this.matSort.direction[sortable.id] = sortable.start ? sortable.start : this.matSort.start;
            } else {
                this.matSort.direction[sortable.id] = this.matSort.getNextSortDirection(sortable);

                if (!this.matSort.direction[sortable.id]) {
                    this.matSort.active.splice(index, 1);
                }
            }
        }

        this.matSort.sortChange.emit({active: this.matSort.active, direction: this.matSort.direction});
    }

    getNextSortDirection(sortable: MatSortable): SortDirection {
        if (!sortable) {
            return '';
        }

        // Get the sort direction cycle with the potential sortable overrides.
        const disableClear = sortable.disableClear != null ? sortable.disableClear : this.matSort.disableClear;
        const sortDirectionCycle = getSortDirectionCycle(sortable.start || this.matSort.start, disableClear);

        // Get and return the next direction in the cycle
        const direction = typeof this.matSort.direction === 'object' ?
            this.matSort.direction[sortable.id] :
            this.matSort.direction;

        let nextDirectionIndex = sortDirectionCycle.indexOf(direction) + 1;

        if (nextDirectionIndex >= sortDirectionCycle.length) {
            nextDirectionIndex = 0;
        }

        return sortDirectionCycle[nextDirectionIndex];
    }

    defineDirection() {
        Object.defineProperty(this.matSort, 'direction', {
            get: function () {
                return this._direction;
            },
            set: function (direction) {
                this._direction = direction;
            },
            enumerable: true,
            configurable: true
        });
    }

    bindSortTypeSwitch() {
        document.querySelector('.mat-header-row').addEventListener('click', (event: KeyboardEvent) => {
            const target = event.target as HTMLElement;

            if (!this.isSortableHeader(target)) {
                return;
            }

            if (event.shiftKey) {
                this.transformMatSortToMultiple();
            } else {
                this.transformMatSortToSingle();
            }
        }, true);
    }

    isSortableHeader(target: HTMLElement) {
        return (target.classList.contains('mat-header-cell') && target.hasAttribute('mat-sort-header'))
            || target.closest('th.mat-header-cell[mat-sort-header]') !== null;
    }

}

function getSortDirectionCycle(start: 'asc' | 'desc', disableClear: boolean): SortDirection[] {
    const sortOrder: SortDirection[] = ['asc', 'desc'];
    if (start === 'desc') {
        sortOrder.reverse();
    }
    if (!disableClear) {
        sortOrder.push('');
    }

    return sortOrder;
}
