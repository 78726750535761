import {Injectable} from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {ResponseMessage} from '../components/response/response.interface';
import {UUID} from 'angular2-uuid';
import {NotificationTypes} from '@shared/enums/notification-types.enum';

@Injectable()
export class NotificationService {
    private readonly timeToHide = 5000;

    private readonly notHideNotificationTypes = [
        NotificationTypes.error,
        NotificationTypes.warning
    ]

    constructor(protected notifierService: NotifierService) {
    }

    public processMessages(messages: Array<ResponseMessage>) {
        messages.forEach((message: ResponseMessage) => {
            this.processMessage(message.type, message.message);
        });
    }

    public processMessage(type: NotificationTypes, message: string, notificationId: string = UUID.UUID()) {
        this.notifierService.notify(type, message, notificationId);
        this.hideNotification(type, notificationId);
    }

    private hideNotification(type: NotificationTypes, notificationId?: string) {
        if (this.notHideNotificationTypes.includes(type)) {
            return;
        }

        setTimeout(() => {
            this.notifierService.hide(notificationId);
        }, this.timeToHide);
    }

}
