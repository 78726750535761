import {CrudActions, CrudActionTypes} from './crud.actions';
import {Crud, Links, Pagination, Statistics} from '@shared/components/crud/crud.interface';
import {BulkActionModel} from '@shared/modules/bulk-actions/models/bulk-action.model';

export interface CrudState {
    data: Array<Crud>,
    pagination: Pagination | object,
    links: Links | object,
    availableFilters: any[],
    availableSortingFields: string[],
    usedSorting: {},
    usedFilters: {},
    statistics: Array<Statistics>,
    statisticsOnDemandUrl: string,
    error: string,
    isDataLoaded: boolean,
    bulkActions: Array<BulkActionModel>,
}

const initialState: CrudState = {
    data: [],
    pagination: {},
    links: {},
    availableFilters: [],
    availableSortingFields: [],
    usedSorting: {},
    usedFilters: {},
    statistics: [],
    statisticsOnDemandUrl: null,
    error: '',
    isDataLoaded: false,
    bulkActions: [],
};


export function crudReducer(state = initialState, action: CrudActions): CrudState {
    let updatedData = [];

    switch (action.type) {
        case CrudActionTypes.ClearDataSuccess:
            return {
                ...state,
                data: [],
                availableFilters: [],
                usedSorting: {},
                isDataLoaded: false,
            };

        case CrudActionTypes.LoadDataSuccess:
            return {
                ...state,
                ...action.payload,
                isDataLoaded: true,
            };

        case CrudActionTypes.CreateItemSuccess:
            updatedData = state.data.slice();
            updatedData.splice(0, 0, action.payload);
            return {
                ...state,
                data: updatedData,
                error: ''
            };

        case CrudActionTypes.UpdateItemSuccess:
            updatedData = state.data.map(item => action.payload.id === item.id ? {...item, ...action.payload} : item);
            return {
                ...state,
                data: updatedData,
                error: ''
            };

        case CrudActionTypes.DeleteItemSuccess:
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
                error: ''
            };

        case CrudActionTypes.HandleErrorSuccess:
            return {
                ...state,
                error: action.payload
            };

        case CrudActionTypes.UpdateStatisticsSuccess:
            return {
                ...state,
                statistics: action.payload
            };

        case CrudActionTypes.UpdateFiltersSuccess:
            return {
                ...state,
                availableFilters: action.payload.availableFilters,
                usedFilters: action.payload.usedFilters,
            };

        default:
            return state;
    }
}
