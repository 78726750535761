import {Crud} from '../crud/crud.interface';
import {Response, ResponseMessage} from './response.interface';
import {NotificationTypes} from '@shared/enums/notification-types.enum';

export class SuccessResponse<TEntity extends Crud> implements Response<TEntity> {
    entity?: TEntity;
    messages?: Array<SuccessResponseMessage> = [];
    data?: {};

    constructor(data) {
        this.entity = data['entity'];
        this.data   = data['data'];

        for (const message of data['messages']) {
            this.messages.push(new SuccessResponseMessage(message));
        }
    }
}

export class SuccessResponseMessage implements ResponseMessage {
    type: NotificationTypes;
    message: string;
    description: string;
    code: string;

    constructor(data) {
        this.type = data['type'];
        this.message = data['message'];
        this.description = data['description'];
        this.code = data['code'];
    }
}
