import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthGuard, MultipleRolesGuard, PickerGuard, PrinterGuard} from './shared';
import {FormModule} from '@shared/modules';
import {CrudCreateComponent, CrudUpdateComponent} from '@shared/components';
import {APP_CONFIG, APP_DI_CONFIG} from './app-config/app-config.constants';

import {HttpClient, HttpClientModule} from '@angular/common/http';

import {AuthService} from './auth/auth.service';
import {UsersService} from '@shared/services/users.service';

import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {ActionReducerMap, MetaReducer, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {RequestService} from '@shared/services/request.service';
import {PermissionService} from '@shared/services/permission.service';
import {PermissionsGuard} from '@shared/guard/permissions.guard';
import {interceptorProviders} from './interceptors/interceptors';
import {NotificationService} from '@shared/services/notification.service';
import {NotifierModule} from 'angular-notifier';

registerLocaleData(localeDe, 'de');

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
    // for development
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export interface State {
    // reducer interfaces
}

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
export const reducers: ActionReducerMap<State>  = {
    // reducers
}

@NgModule({
    declarations: [
        AppComponent,
        CrudCreateComponent,
        CrudUpdateComponent,
    ],
    imports:      [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide:    TranslateLoader,
                useFactory: createTranslateLoader,
                deps:       [HttpClient]
            }
        }),
        NotifierModule.withConfig({
            behaviour: {
                autoHide: false
            },
            position: {
                horizontal: {
                    position: 'right'
                },
                vertical: {
                    position: 'top'
                }
            }
        }),
        StoreModule.forRoot(
            reducers, {
                metaReducers,
                runtimeChecks: {
                    strictStateImmutability:     false,
                    strictActionImmutability:    false,
                    strictStateSerializability:  false,
                    strictActionSerializability: false
                }
            }),
        StoreDevtoolsModule.instrument({
            maxAge:  25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
        EffectsModule.forRoot([]),
        FormModule,
    ],
    providers:    [
        AuthService,
        UsersService,
        RequestService,
        NotificationService,
        PermissionService,
        {
            provide:  APP_CONFIG,
            useValue: APP_DI_CONFIG,
        },
        AuthGuard,
        PrinterGuard,
        PickerGuard,
        MultipleRolesGuard,
        PermissionsGuard,
        interceptorProviders,
        {
            provide:  LOCALE_ID,
            useValue: 'de'
        },
    ],
    bootstrap:    [AppComponent]
})
export class AppModule {
}
