import {Injectable} from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class DatesService {
    static convertDateToIso(date: Date): string {
        return moment(date).toISOString(true).slice(0, 10);
    }

    static toLocaleTimeString(date: Date, locale = 'de'): string {
        return date.toLocaleTimeString(locale);
    }

    static toLocaleDateString(date: Date, locale = 'de'): string {
        return date.toLocaleDateString(locale);
    }

    static toLocaleDateTimeString(date: Date, locale = 'de'): string {
        return DatesService.toLocaleDateString(date, locale) + ' ' + DatesService.toLocaleTimeString(date, locale);
    }

    yesterday() {
        const date = this.today();
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
    }

    today() {
        return new Date();
    }

    thisMonthStart() {
        const date = this.today();
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    lastMonday() {
        let result;
        const today = this.today();
        const day = today.getDay();
        const diff = today.getDate() - day + (day === 0 ? -6 : 1);
        // if today is Monday
        if (today.getDay() === 1) {
            result = new Date(today.setDate(diff));
            result = new Date(result.getFullYear(), result.getMonth(), result.getDate() - 7)
        } else {
            result = new Date(today.setDate(diff));
        }
        return result;
    }

    lastLastMonday() {
        const lastMonday = this.lastMonday();
        return new Date(lastMonday.getFullYear(), lastMonday.getMonth(), lastMonday.getDate() - 7)
    }

    lastLastSunday() {
        const lastMonday = this.lastMonday();
        return new Date(lastMonday.getFullYear(), lastMonday.getMonth(), lastMonday.getDate() - 1)
    }

    lastMonthStart() {
        const date = this.today();
        date.setDate(1);
        date.setMonth(date.getMonth() - 1);
        return date
    }

    lastMonthEnd() {
        const date = this.today();
        date.setMonth(date.getMonth() - 1);
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }
}
