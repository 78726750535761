import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtInterceptor} from './jwt.interceptor';
import {NotifyInterceptor} from './notify.interceptor';


export const interceptorProviders = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: NotifyInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtInterceptor,
        multi: true
    },
]
