import '@angular/compiler';
import 'core-js/es/reflect';
import 'zone.js/dist/zone';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';
import '../node_modules/chart.js/dist/chart.js';
// import './assets/js/jquery.min.js';
// import './assets/js/jquery-ui.js';
// import 'assets/js/gridstack.js';
// import 'assets/js/jquery-ui-gridstack.js';
import 'assets/js/newWaterfall.js';

if (environment.production) {
    enableProdMode();
}
platformBrowserDynamic().bootstrapModule(AppModule);
