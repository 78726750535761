/* tslint:disable:import-blacklist */
import {map} from 'rxjs';
import {Observable} from 'rxjs';
import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';

import {APP_CONFIG} from '../app-config/app-config.constants';
import {IAppConfig} from '../app-config/app-config.interface';

@Injectable()
export class AuthService {
    private refreshTokenUrl = '/api/admin/v1/refresh-token';
    private headers: Object = {
        'Accept':       'application/json',
        'Content-Type': 'application/json',
    };

    public static getTokenStatic(): string {
        return localStorage.getItem('id_token');
    }

    private static isMultipartContentTypeHeader(request) {
        return request && request.headers.get('Content-Type') === 'multipart/form-data';
    }

    constructor(private http: HttpClient,
                private router: Router,
                @Inject(APP_CONFIG) private config: IAppConfig) {
        this.refreshTokenUrl = config.API_DOMAIN + this.refreshTokenUrl;
    }

    public getToken(): string {
        return localStorage.getItem('id_token');
    }

    public setToken(token: string) {
        return localStorage.setItem('id_token', token);
    }

    public setRefreshToken(refresh_token: string) {
        return localStorage.setItem('refresh_token', refresh_token);
    }

    public getRefreshToken(): string {
        return localStorage.getItem('refresh_token');
    }

    getHeaders(): HttpHeaders | any {
        return this.headers;
    }

    getAuthHeaders(request: HttpRequest<any> = null): HttpHeaders | any {
        const headers: any = {...this.headers};

        for (const headerName in headers) {
            if (request && request.headers.getAll(headerName)) {
                headers[headerName] = request.headers.getAll(headerName);
            }
        }

        if (AuthService.isMultipartContentTypeHeader(request)) {
            delete headers['Content-Type'];
        }

        headers['Authorization'] = 'Bearer ' + this.getToken();
        return headers;
    }

    getRefreshedAuthHeaders(request: HttpRequest<any> = null) {
        return new Observable(observer => {
            this.http.post(this.refreshTokenUrl, {'refreshToken': this.getRefreshToken()}, {
                    headers: this.getHeaders()
                }
            ).pipe(map(res => res)).subscribe({
                next: (data) => {
                    this.setToken(data['token']);
                    this.setRefreshToken(data['refresh_token']);
                    observer.next(this.getAuthHeaders(request))
                },
                error: () => {
                    localStorage.removeItem('isLoggedin');
                    this.router.navigate(['login']);
                }
            });
        });
    }

    createSha1Token(string) {
        return KJUR.crypto.Util.sha1(string);
    }

}
