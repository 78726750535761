import {ValidatorsBuilder} from '../validators/validators-builder';
import {FormBuilder} from '../form-builder';
import {SelectMultipleFilterHelper} from "@shared/modules/list-filter/helpers/filters/select-multiple-filter.helper";
import {RequestService} from "@shared/services/request.service";
import {
    DynamicInputAutocompleteSingleWithRequestModel
} from "@shared/modules/list-filter/filter-main-panel/form/model/input-autocomplete-single-with-request";

export class SelectAutocompleteControl {
    field: any;
    formBuilder: FormBuilder;
    requestService: RequestService;

    constructor(formBuilder: FormBuilder, field: any, requestService: RequestService) {
        this.field          = field;
        this.formBuilder    = formBuilder;
        this.requestService = requestService;
    }

    build() {
        let validatorBuilder = (new ValidatorsBuilder(this.field.constraints, this.formBuilder)).build();
        let options          = this.field['choices'] ? this.field['choices'] : {};

        let selectOptions = [];
        let values        = Object.keys(options);
        for (let optionValue of values) {
            selectOptions.push({
                value: options[optionValue],
                label: optionValue,
            });
        }
        this.addNoneOption(selectOptions);

        let selected = this.getSelected();

        for (const selectOption in selectOptions) {
            if (selectOptions[selectOption]['value'] === selected
                || (selected instanceof Object
                    && (selectOptions[selectOption]['value'] === selected['value']
                        || selectOptions[selectOption]['value'] === selected['id'])
                )
            ) {
                selected = [selectOption] || (values.length ? [0] : null);
            }
        }

        const model = new DynamicInputAutocompleteSingleWithRequestModel({
                id:          this.getAutocompleteWithSettingsModelId(),
                label:       '',
                placeholder: this.field.label,
                hint:        this.field.help,
                multiple:    false,
                value:       this.field.data,
                disabled:    this.field.attributes?.disabled,
                validators:  validatorBuilder.getValidators(),
                additional:  {
                    acceptButtonId: `${this.field.field}-popup-button-accept`
                },
            },
            null,
            null,
            Object.fromEntries(
                Object.entries(this.field.choices).map(([key, value]) => [value, key])
            )
        );


        const settings = {
            minLength:         1,
            maxLength:         200,
            skipMultipleCheck: true,
        };
        SelectMultipleFilterHelper.bindSearchForAutocomplete(this.field.attributes.autoCompleteUrl, settings, model, this.requestService);

        return model;
    }

    private getSelected() {
        return this.formBuilder.modelData[this.field.name] ?? this.field.data ?? null;
    }

    private addNoneOption(selectOptions: Array<object>) {
        if (selectOptions.length === 0 || this.field.attributes?.skipNone) {
            return;
        }

        selectOptions.unshift({value: null, label: 'None'});
    }

    protected getAutocompleteWithSettingsModelId() {
        return `${this.field.name}`;
    }

}
