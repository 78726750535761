import {FormBuilder} from '../form-builder';
import {serverValidator} from './server-validator';

export class ValidatorsBuilder {
    private readonly fieldValidators: any = [];
    private validators: any = [];
    private asyncValidators: any = [];
    private validatorErrorMessage: any = [];
    private formBuilder: FormBuilder;

    constructor(validators: any, formBuilder: FormBuilder) {
        this.fieldValidators = validators;
        this.formBuilder = formBuilder;
    }

    build() {
        for (const validator in this.fieldValidators) {
            if (!this.fieldValidators.hasOwnProperty(validator)) {
                continue;
            }

            switch (validator) {
                case 'required':
                    this.validators['required'] = null;
                    this.validatorErrorMessage['required'] = 'Field is required';
                    break;
                case 'min':
                case 'minLength':
                    this.validators[validator] = this.fieldValidators[validator].value;
                    this.validatorErrorMessage[validator] = `Can no be shorter than ${this.fieldValidators[validator].value}`;
                    break;
                case 'max':
                case 'maxLength':
                    this.validators[validator] = this.fieldValidators[validator].value;
                    this.validatorErrorMessage[validator] = `Can no be longer than ${this.fieldValidators[validator].value}`;
                    break;
                case 'length':
                    this.validators['min'] = this.validators['max'] = this.fieldValidators[validator].value;
                    this.validatorErrorMessage['min'] = `Can no be shorter than ${this.fieldValidators[validator].value}`;
                    this.validatorErrorMessage['max'] = `Can no be longer than ${this.fieldValidators[validator].value}`;
                    break;
                case 'lessThan':
                    this.validators['max'] = this.fieldValidators[validator].value;
                    this.validatorErrorMessage['max'] = `This value should be less than or equal to ${this.fieldValidators[validator].value}`;
                    break;
                case 'greaterThan':
                    this.validators['min'] = this.fieldValidators[validator].value;
                    this.validatorErrorMessage['min'] = `This value should be greater than or equal to ${this.fieldValidators[validator].value}`;
                    break;
                case 'email':
                    this.validators['email'] = null;
                    this.validatorErrorMessage['email'] = 'Not valid email';
                    break;
                case 'pattern':
                case 'regex':
                    this.validators['pattern'] = this.fieldValidators[validator].value;
                    this.validatorErrorMessage['pattern'] = `Please match the requested format ${this.fieldValidators[validator].value}`;
                    break;
                case 'serverSide':
                    this.validators['serverValidator'] = null;
                    break;
            }

        }
        return this;
    }

    getAsyncValidators() {
        return this.asyncValidators;
    }

    getValidators() {
        return this.validators;
    }

    getErrorMessages() {
        return this.validatorErrorMessage;
    }
}
