import {DynamicFormGroupModel, DynamicFormOption} from '@ng-dynamic-forms/core';

import {
    DYNAMIC_FORM_CONTROL_TYPE_BUTTON_POPUP_ACCEPT,
    DYNAMIC_FORM_CONTROL_TYPE_BUTTON_POPUP_CLOSE,
    DYNAMIC_FORM_CONTROL_TYPE_TITLE_POPUP,
    DynamicButtonModel,
    DynamicTitleModel
} from '../form/model';
import {FilterPresentation} from './filter.presentation';
import {FormModelFactory} from '../../filter-right-panel/form/form-model-factory';

export class PopupFilterPresentation extends FilterPresentation {

    public getFormGroupModel(): DynamicFormGroupModel {
        const popupGroup = this.getPopupGroup();
        const filterGroup = new DynamicFormGroupModel({
            id: `${this.filter.getFilter().field}-filter-group`,
            group: [this.filter.getMainModel()],
        });

        if (popupGroup) {
            filterGroup.group.push(popupGroup);
            this.addAcceptListener();
        }

        return filterGroup;
    }

    public updateMainModelValue(value: any, prefix: string = ''): void {
        const comparison: DynamicFormOption<any> = this.filter.getSelectedComparison();

        if (this.filter.isComparisonNullable(comparison.value)) {
            this.filter.getMainModel().suffix = '';
            this.filter.getMainModel().prefix = comparison.text + prefix;
            value = '';
        } else {
            this.filter.getMainModel().suffix = value ? '' : this.filter.getMainModel().getAdditional('defaultSuffix');
            this.filter.getMainModel().prefix = (!value ? '' : comparison.text) + prefix;
        }

        this.filter.getMainModel().value = value;
    }

    private addAcceptListener(): void {
        setTimeout(() => {
            const fieldName = this.filter.getFilter().field;

            if (document.querySelector(`#${fieldName}-popup-button-accept`) === null) {
                return;
            }

            document.querySelector(`#${fieldName}-popup-button-accept`).addEventListener('click', ($event) => {
                const target = $event.target as HTMLTextAreaElement;
                const isCurrentPopup = target.closest(`#${fieldName}-popup-group`);

                if (isCurrentPopup) {
                    const inputElement = isCurrentPopup.querySelector('.mat-input-element') as HTMLInputElement;
                    this.filter.accept(undefined, inputElement?.value);
                    this.setValueToRightPanelFilters();
                }
            }, false)
        }, 1000);
    }

    private getPopupGroup(): DynamicFormGroupModel | null {
        const popupModels = this.filter.getModels();

        if (!popupModels.length) {
            return null;
        }

        if (this.filter.getComparisonModel()) {
            popupModels.unshift(this.filter.getComparisonModel());
        }

        popupModels.unshift(
            new DynamicTitleModel({
                id: `${this.filter.getFilter().field}-popup-title`,
                label: this.filter.getFilter().label,
            }, undefined, DYNAMIC_FORM_CONTROL_TYPE_TITLE_POPUP),
            new DynamicButtonModel({
                id: `${this.filter.getFilter().field}-popup-button-close`,
            }, undefined, DYNAMIC_FORM_CONTROL_TYPE_BUTTON_POPUP_CLOSE),
        );

        popupModels.push(
            new DynamicButtonModel({
                id: `${this.filter.getFilter().field}-popup-button-accept`,
            }, undefined, DYNAMIC_FORM_CONTROL_TYPE_BUTTON_POPUP_ACCEPT)
        );

        return new DynamicFormGroupModel({
            id: `${this.filter.getFilter().field}-popup-group`,
            legend: this.filter.getFilter().label,
            group: popupModels
        });
    }

    private setValueToRightPanelFilters(): void {
        FormModelFactory.getFilters().forEach(filter => {
            if (this.filter.getFilter().field === filter.filter.field) {
                filter.setValue(this.filter.getValue());
            }
        });
    }

}
