import {ChangeDetectionStrategy, Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UsersService} from '../../services/users.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [UsersService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {

    constructor(private translate: TranslateService,
                public router: Router,
                private usersService: UsersService) {

        this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd && window.innerWidth <= 992) {
                // this.toggleSidebar();
            }
        });
    }

    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('push-right');
    }

    onLoggedOut() {
        const currentUser: any = this.usersService.getCurrentUser();
        localStorage.removeItem('currentUser');
        localStorage.removeItem('isLoggedin');
        localStorage.removeItem('id_token');
        localStorage.removeItem('refresh_token');
        this.usersService.removeUserFromLoggedUsersList(currentUser.id);
    }

    getCurrentUserName() {
        const user = this.usersService.getCurrentUser();
        if (user) {
            return user.firstName + ' ' + user.lastName;
        }
    }

    changeLang(language: string) {
        this.translate.use(language);
    }
}
