import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {DataNotFoundComponent} from './data-not-found.component';
import {CommonModule} from '@angular/common';

@NgModule({
    imports: [
        RouterModule,
        CommonModule
    ],
    declarations: [DataNotFoundComponent],
    exports: [DataNotFoundComponent]
})
export class DataNotFoundModule {
}
