import {DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER, DynamicInputModel} from '@ng-dynamic-forms/core';
import {ValidatorsBuilder} from '../validators/validators-builder';
import {FormBuilder} from '../form-builder';

export class InputNumberControl {
    field: any;
    formBuilder: FormBuilder;

    constructor(formBuilder: FormBuilder, field: any) {
        this.field = field;
        this.formBuilder = formBuilder;
    }

    build() {
        let validatorBuilder = (new ValidatorsBuilder(this.field.constraints, this.formBuilder)).build();

        return new DynamicInputModel({
            id: this.field.name,
            label: this.field.label,
            max: this.getMaxValue(validatorBuilder),
            min: this.getMinValue(validatorBuilder),
            value: this.getValue(validatorBuilder),
            inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER,
            placeholder: this.field.placeholder,
            validators: validatorBuilder.getValidators(),
            asyncValidators: validatorBuilder.getAsyncValidators(),
            errorMessages: validatorBuilder.getErrorMessages()
        });
    }

    private getMaxValue(validatorBuilder: ValidatorsBuilder) {
        return validatorBuilder.getValidators()['max'] ?? null;
    }

    private getMinValue(validatorBuilder: ValidatorsBuilder) {
        return validatorBuilder.getValidators()['min'] ?? null;
    }

    private isRequired(validatorBuilder: ValidatorsBuilder) {
        return validatorBuilder.getValidators().hasOwnProperty('required');
    }

    private getValue(validatorBuilder: ValidatorsBuilder) {
        if (this.formBuilder.modelData[this.field.name]) {
            return +this.formBuilder.modelData[this.field.name];
        }

        if (this.isRequired(validatorBuilder)) {
            return this.getMinValue(validatorBuilder) ?? 0;
        }

        return null;
    }
}
