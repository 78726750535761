import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {DynamicFormOption} from '@ng-dynamic-forms/core';
import * as fromCrud from '../../../../../../store/list-filter';
import {ListFilter} from '../../../models/filter';
import {FilterPresentationInterface} from '../filter.presentation';
import {ListFilterService} from '../../../list-filter.service';
import {SelectFilter} from '@shared/modules/list-filter/filter-main-panel/filters';

@Injectable()
export class IsSetFilter extends SelectFilter {
    static readonly modelType: string = 'is_set';
    readonly modelType: string        = SelectFilter.modelType;

    constructor(
        listFilterService: ListFilterService,
        store$: Store<fromCrud.State>,
        filter: ListFilter,
        filterPresentation: FilterPresentationInterface,
    ) {
        super(listFilterService, store$, filter, filterPresentation, IsSetFilter.modelType);
    }
}

