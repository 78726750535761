import {Injectable} from '@angular/core';

import {DatePeriodFilter as BaseDatePeriodFilter} from '../../../filter-main-panel/filters/date-period/date-period.filter';

@Injectable()
export class DatePeriodFilter extends BaseDatePeriodFilter {

    protected initMainModel(): void {
    }

}
