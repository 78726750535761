import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromCrud from '../../../store/crud';

@Component({
    selector: 'app-data-not-found',
    templateUrl: './data-not-found.component.html',
    styleUrls: ['./data-not-found.component.scss']
})

export class DataNotFoundComponent implements OnInit, OnDestroy {
    @Input() emptyTableText: string;
    public isDataLoaded     = false;
    public isDataExists     = false;
    public isDataLoadedSubscriber: Subscription;
    protected readonly defaultText = 'Wir konnten keine passenden Daten finden';

    constructor(private store$: Store<fromCrud.State>) {
    }

    ngOnInit(): void {
        this.isDataLoadedSubscriber = this.store$.pipe(select(fromCrud.isDataLoadedAndExists)).subscribe(isDataLoadedAndExists => {
            this.isDataLoaded = isDataLoadedAndExists.isDataLoaded;
            this.isDataExists = isDataLoadedAndExists.isDataExists;
        });

        this.emptyTableText = this.emptyTableText ? this.emptyTableText : this.defaultText;
    }

    ngOnDestroy() {
        if (this.isDataLoadedSubscriber) {
            this.isDataLoadedSubscriber.unsubscribe();
        }
    }

}
