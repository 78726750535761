import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap, of} from 'rxjs';
import * as crudActions from './crud.actions';
import {Crud, Statistics} from '@shared/components/crud/crud.interface';

@Injectable()
export class CrudEffects {

    loadDataSource$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(crudActions.CrudActionTypes.LoadData),
            mergeMap((data: any) => {
                    return of(new crudActions.LoadDataSuccess(data.payload));
                }
            ),
        );
    });

    clearDataSource$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(crudActions.CrudActionTypes.ClearData),
            mergeMap(() => {
                    return of(new crudActions.ClearDataSuccess());
                }
            ),
        );
    });

    createDataSource$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(crudActions.CrudActionTypes.CreateItem),
            map((action: crudActions.CreateItem) => action.payload),
            mergeMap((data: Crud) => {
                    return of(new crudActions.CreateItemSuccess(data));
                }
            ),
        );
    });

    updateDataSource$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(crudActions.CrudActionTypes.UpdateItem),
            map((action: crudActions.UpdateItem) => action.payload),
            mergeMap((data: Crud) => {
                    return of(new crudActions.UpdateItemSuccess(data));
                }
            ),
        );
    });

    deleteDataSource$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(crudActions.CrudActionTypes.DeleteItem),
            map((action: crudActions.DeleteItem) => action.payload),
            mergeMap((id: number) => {
                    return of(new crudActions.DeleteItemSuccess(id));
                }
            ),
        );
    });

    error$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(crudActions.CrudActionTypes.HandleError),
            map((action: crudActions.HandleError) => action.payload),
            mergeMap((error: any) => {
                    return of(new crudActions.HandleErrorSuccess(error.message));
                }
            ),
        );
    });

    updateStatisticsSource$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(crudActions.CrudActionTypes.UpdateStatistics),
            map((action: crudActions.UpdateStatistics) => action.payload),
            mergeMap((statistics: Array<Statistics>) => {
                    return of(new crudActions.UpdateStatisticsSuccess(statistics));
                }
            ),
        );
    });

    updateFiltersSource$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(crudActions.CrudActionTypes.UpdateFilters),
            map((action: crudActions.UpdateFilters) => action.payload),
            mergeMap((filters) => {
                    return of(new crudActions.UpdateFiltersSuccess(filters));
                }
            ),
        );
    });

    constructor(private actions$: Actions) {
    }
}
