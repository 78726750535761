import {Injectable} from '@angular/core';

import {SelectMultipleFilter as BaseSelectMultipleFilter} from '../../../filter-main-panel/filters/select-multiple/select-multiple.filter';

@Injectable()
export class SelectMultipleFilter extends BaseSelectMultipleFilter {

    protected initMainModel(): void {
    }

    protected getAutocompleteModelId() {
        return `${this.filter.field}-right-panel-field`;
    }

    protected getCheckAllModelId() {
        return `${this.filter.field}-right-panel-field-check-all`;
    }

}
