import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class ListFilterService {
    filtersChange: EventEmitter<any> = new EventEmitter();

    emitFiltersChange() {
        this.filtersChange.emit();
    }

    getFiltersChangeEmitter() {
        return this.filtersChange;
    }


}
