import {Injectable} from '@angular/core';

import {SelectMultipleAutocompleteFilter as BaseSelectMultipleAutocompleteFilter} from '../../../filter-main-panel/filters/select-multiple/select-multiple-autocomplete.filter';

@Injectable()
export class SelectMultipleAutocompleteFilter extends BaseSelectMultipleAutocompleteFilter {

    protected initMainModel(): void {
    }

    protected getAutocompleteWithSettingsModelId() {
        return `${this.filter.field}-right-panel-field`;
    }

}
