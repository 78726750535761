import {Component, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-image',
    templateUrl: './image.component.html',
})
export class ImageComponent implements OnInit {
    @Input() blob: Blob;
    @Input() thumbnail: boolean;
    @Output() imageClass: string;
    @Output() imageToShow: any;


    constructor() {
    }

    ngOnInit() {
        this.createImageFromBlob(this.blob);
        this.imageClass = this.thumbnail ? 'img-thumbnail' : '';
    }

    createImageFromBlob(image: Blob) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            this.imageToShow = reader.result;
        }, false);

        if (image) {
            return reader.readAsDataURL(image);
        }
    }

}
