import {Component, HostBinding, Input} from '@angular/core';
import {slideInOutAnimation} from '../../animations';

@Component({
    selector: 'app-overlay',
    templateUrl: './overlay.component.html',
    styleUrls: ['./overlay.component.scss'],
    animations: [slideInOutAnimation]
})

export class OverlayComponent {
    @Input() withSpinner = true;
    constructor() {}
    @HostBinding('@slideInOutAnimation') get slideInOutAnimation() {
        return '';
    }

}
