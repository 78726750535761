import {DynamicTextAreaModel} from '@ng-dynamic-forms/core';
import {ValidatorsBuilder} from '../validators/validators-builder';
import {FormBuilder} from '../form-builder';

export class TextareaControl {
    field: any;
    formBuilder: FormBuilder;

    constructor(formBuilder: FormBuilder, field: any) {
        this.field = field;
        this.formBuilder = formBuilder;
    }

    build() {
        let validatorBuilder = (new ValidatorsBuilder(this.field.constraints, this.formBuilder)).build();

        return new DynamicTextAreaModel({
            id: this.field.name,
            label: this.field.label,
            value: this.formBuilder.modelData[this.field.name] || '',
            placeholder: this.field.placeholder,
            validators: validatorBuilder.getValidators(),
            asyncValidators: validatorBuilder.getAsyncValidators(),
            errorMessages: validatorBuilder.getErrorMessages()
        });

    }
}
