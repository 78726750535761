import {DynamicDatePickerModel, DynamicInputModel} from '@ng-dynamic-forms/core';
import {Injectable} from '@angular/core';

import * as listFilterActions from '../../../../../../store/list-filter/list-filter.actions';
import {DatesService} from '@shared/services/dates.service';
import {DateRangeFilterHelper} from '../../../helpers/filters/date-range-filter.helper';
import {FilterStore} from '../../../models/filter-store';
import {Filter} from '../filter';
import {Store} from '@ngrx/store';
import * as fromCrud from '../../../../../../store/list-filter';
import {ListFilter} from '../../../models/filter';
import {FilterPresentationInterface} from '../filter.presentation';
import {ListFilterService} from '../../../list-filter.service';
import * as moment from 'moment';

@Injectable()
export class DateRangeFilter extends Filter {
    static readonly modelType: string = 'date_range';

    protected datePickerFromModel: DynamicDatePickerModel | any;
    protected datePickerToModel: DynamicDatePickerModel | any;
    protected preDefinedModel: DynamicInputModel | any;

    constructor(
        listFilterService: ListFilterService,
        store$: Store<fromCrud.State>,
        filter: ListFilter,
        filterPresentation: FilterPresentationInterface,
    ) {
        super(listFilterService, store$, filter, filterPresentation, DateRangeFilter.modelType);
    }

    public getModels(): any[] {
        return [this.getDatePickerFromModel(), this.getDatePickerToModel(), this.getPreDefinedModel()];
    }

    public accept(emitChanges = true): void {
        this.updateMainModelValue(this.getDateRange());
        this.applyFilter(this.getDateRange(), emitChanges);
    }

    public setValue(value: {}): void {
        this.datePickerFromModel.value = value['datePickerFromModel']
        this.datePickerToModel.value = value['datePickerToModel']
    }

    public getValue(): {} {
        return {
            'datePickerFromModel': this.datePickerFromModel.value,
            'datePickerToModel': this.datePickerToModel.value,
        };
    }

    protected reset(): void {
        super.reset();

        this.datePickerFromModel.value = null;
        this.datePickerToModel.value   = null;
    }

    protected initComparisonModel(config: {} = {}): void {
        super.initComparisonModel({hidden: true});
    }

    protected applyFilter(value: any, emitChanges = true): void {
        const filterData: any = {};
        const fromDate        = this.datePickerFromModel.value ? DatesService.convertDateToIso(this.datePickerFromModel.value) : null;
        const toDate          = this.datePickerToModel.value ? DatesService.convertDateToIso(this.datePickerToModel.value) : null;

        if (!fromDate && !toDate) {
            return this.store$.dispatch(new listFilterActions.RemoveFilter(this.id));
        }

        if (!this.filter.inBothPresentations) {
            filterData[this.filter.getField()] = {gte: fromDate, lte: toDate};
            this.store$.dispatch(new listFilterActions.LoadData(filterData));

            const filterModel = {[this.id]: new FilterStore(this, this.getDateRange())};
            this.store$.dispatch(new listFilterActions.AddFilter(filterModel));
        }

        if (emitChanges) {
            this.listFilterService.getFiltersChangeEmitter().emit();
        }
    }

    protected initMainModel(): void {
        this.mainModelSuffix = '<i class="material-icons">date_range</i>';

        this.mainModel = new DynamicInputModel({
            id:       this.filter.field,
            label:    this.filter.label,
            disabled: true,
            suffix:   this.mainModelSuffix,
        });
    }

    protected getDatePickerFromModel(): DynamicDatePickerModel {
        if (this.datePickerFromModel) {
            return this.datePickerFromModel;
        }

        this.datePickerFromModel = new DynamicDatePickerModel({
            id:    `${this.filter.field}-field-date-from`,
            label: this.filter.label + ' Von',
        });

        this.datePickerFromModel.valueChanges.subscribe((value) => {
            if (this.datePickerToModel) {
                this.datePickerToModel.min = value;
            }
        });

        return this.datePickerFromModel;
    }

    protected getDatePickerToModel(): DynamicDatePickerModel {
        if (this.datePickerToModel) {
            return this.datePickerToModel;
        }

        this.datePickerToModel = new DynamicDatePickerModel({
            id:    `${this.filter.field}-field-date-to`,
            label: this.filter.label + ' Bis',
        });

        this.datePickerToModel.valueChanges.subscribe((value) => {
            if (moment(value).isAfter(this.datePickerFromModel.value)) {
                this.datePickerFromModel.max = value;
            }
        });

        this.datePickerToModel.value   = this.getInitialDateToValue();
        this.datePickerFromModel.value = this.getInitialDateFromValue();

        this.applyFilter(this.getDateRange(), false);

        return this.datePickerToModel;
    }

    protected getPreDefinedModel(): DynamicInputModel {
        if (this.preDefinedModel) {
            return this.preDefinedModel;
        }

        this.addPreDefinedModelListener();

        return this.preDefinedModel = new DynamicInputModel({
            id:       `${this.filter.field}-pdr`,
            label:    this.filter.label,
            list:     this.filter.getPreDefinedLabels(),
            value:    this.filter.getPreDefinedLabels(),
            multiple: true,
        });
    }

    protected addPreDefinedModelListener(): void {
        setTimeout(() => {
            DateRangeFilterHelper.addPredefinedClickListener(this.filter, this.datePickerFromModel, this.datePickerToModel);
        }, 0);
    }

    protected getDateRange(): string {
        let fromDate: any = this.datePickerFromModel.value;
        let toDate: any   = this.datePickerToModel.value;

        if (moment.isMoment(fromDate)) {
            fromDate = fromDate.toDate().toLocaleDateString('de');
        }

        fromDate = fromDate instanceof Date ? fromDate.toLocaleDateString('de') : fromDate;

        if (moment.isMoment(toDate)) {
            toDate = toDate.toDate().toLocaleDateString('de');
        }

        toDate = toDate instanceof Date ? toDate.toLocaleDateString('de') : toDate;

        return this.combineDateRange(fromDate, toDate);
    }

    protected combineDateRange(fromDate: string, toDate?: string) {
        return fromDate || toDate ? [fromDate, toDate].filter((item) => item).join(' - ') : '';
    }

    protected getInitialValue(): any[] {
        let values = this.filter.initialValue ? Object.values(this.filter.initialValue) : [];
        return values.filter((value) => value !== 'null').map((value: string) => new Date(value));
    }

    protected getInitialDateFromValue(): Date | null {
        let date: any = this.filter.initialValue ? Object.values(this.filter.initialValue).shift() : null;
        return date && date !== 'null' ? new Date(date) : null;
    }

    protected getInitialDateToValue(): Date | null {
        let date: any = this.filter.initialValue ? Object.values(this.filter.initialValue).pop() : null;
        return date && date !== 'null' ? new Date(date) : null;
    }

    protected setInitialValue(): void {
        let initialValue = this.getInitialValue();
        initialValue     = initialValue.map((date: Date) => date.toLocaleDateString('de'))

        if (initialValue) {
            this.updateMainModelValue(this.combineDateRange(initialValue[0] || null, initialValue[1] || null));
        }
    }

}
