import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpUrlEncodingCodec} from '@angular/common/http';

@Injectable()
export class RequestService {

    public static getHttpEncoder() {
        return {
            encodeKey:   (key: string): string => encodeURIComponent(key),
            encodeValue: (value: string): string => encodeURIComponent(value),
            decodeKey:   (key: string): string => decodeURIComponent(key),
            decodeValue: (value: string): string => decodeURIComponent(value),
        } as HttpUrlEncodingCodec;
    }

    constructor(private http: HttpClient) {
    }

    get(url: string, params?: HttpParams) {
        return this.http.get(url, {params: params});
    }
}
