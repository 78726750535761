import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable()
export class PrinterGuard implements CanActivate {
    constructor(public router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        const permissionGranted = user.roles.printer || user.roles.admin;

        if (!permissionGranted) {
            this.router.navigate(['not-found'])
        }
        return true;
    }
}
