import {DynamicSelectModel} from '@ng-dynamic-forms/core';
import {ValidatorsBuilder} from '../validators/validators-builder';
import {FormBuilder} from '../form-builder';

export class SelectMultipleControl {
    field: any;
    formBuilder: FormBuilder;

    constructor(formBuilder: FormBuilder, field: any) {
        this.field = field;
        this.formBuilder = formBuilder;
    }

    build() {
        let validatorBuilder = (new ValidatorsBuilder(this.field.constraints, this.formBuilder)).build();
        let options  = this.field['choices'] ? this.field['choices'] : {};
        let selected = this.formBuilder.modelData[this.field.name]
            ? this.formBuilder.modelData[this.field.name].map(option => option.id ? option.id : option)
            : [];

        let selectOptions = [];
        let values = Object.keys(options);
        for (let optionValue of values) {
            selectOptions.push({
                value: options[optionValue],
                label: optionValue,
            });
        }

        let selectedOptions = [];
        if (selected.length) {
            for(const selectOption in selectOptions){
                if(selected.includes(selectOptions[selectOption]['value'])){
                    selectedOptions.push(selectOption);
                }
            }
        }

        let model = new DynamicSelectModel({
            id: this.field.name,
            label: this.field.label,
            options: selectOptions,
            filterable: true,
            multiple: true,
            validators: validatorBuilder.getValidators(),
            asyncValidators: validatorBuilder.getAsyncValidators(),
            errorMessages: validatorBuilder.getErrorMessages()
        });

        if (selectedOptions.length) {
            model.select.apply(model, selectedOptions);
        }
        return model;
    }
}
