import {Action} from '@ngrx/store';

export const MENU_SLIDE_OUT = 'MENU_SLIDE_OUT';
export const MENU_SLIDE_IN = 'MENU_SLIDE_IN';

export function menuReducer(state: boolean = false, action: Action) {
    switch (action.type) {
        case MENU_SLIDE_OUT:
            return true;

        case MENU_SLIDE_IN:
            return false;

        default:
            return state;
    }
}
