import {DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER, DynamicInputModel, DynamicPathable} from '@ng-dynamic-forms/core';
import {Injectable} from '@angular/core';

import {Filter} from '../filter';
import {QueryFilterHelper} from '../../../helpers/filters/query-filter.helper';
import {Store} from '@ngrx/store';
import * as fromCrud from '../../../../../../store/list-filter';
import {ListFilter} from '../../../models/filter';
import {FilterPresentationInterface} from '../filter.presentation';
import {ListFilterService} from '../../../list-filter.service';

@Injectable()
export class NumberFilter extends Filter {
    static readonly modelType: string = 'number';

    private numberModel: DynamicInputModel;

    constructor(
        listFilterService: ListFilterService,
        store$: Store<fromCrud.State>,
        filter: ListFilter,
        filterPresentation: FilterPresentationInterface,
    ) {
        super(listFilterService, store$, filter, filterPresentation, NumberFilter.modelType);
    }

    public getModels(): Array<DynamicPathable> {
        return [this.getNumberModel()];
    }

    public accept(emitChanges = true): void {
        this.updateMainModelValue(this.numberModel.value);
        this.applyFilter(this.numberModel.value, emitChanges);
    }

    public setValue(value: any): void {
        this.numberModel.value = value
    }

    public getValue(): any {
        return this.numberModel.value;
    }

    protected reset(): void {
        super.reset();

        this.numberModel.value = null;
    }

    protected initMainModel(): void {
        this.mainModelSuffix = '<i class="fas fa-ellipsis-h"></i>';

        this.mainModel = new DynamicInputModel({
            id:          this.filter.field,
            label:       this.filter.label,
            placeholder: this.filter.label,
            inputType:   DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER,
            suffix:      this.mainModelSuffix,
            disabled:    true,
        });
    }

    private getNumberModel(): DynamicInputModel {
        if (this.numberModel) {
            return this.numberModel;
        }

        this.getComparisonModel().valueChanges.subscribe((value) => {
            if (this.numberModel) {
                this.numberModel.readOnly = this.isComparisonNullable(value);
                QueryFilterHelper.toggleDisabled(this.numberModel);
            }
        });

        const initialValue = this.getInitialValue();

        if (initialValue) {
            this.applyFilter(initialValue, false);
        }

        return this.numberModel = new DynamicInputModel({
            id:          `${this.filter.field}-field`,
            label:       this.filter.label,
            placeholder: this.filter.label,
            inputType:   DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER,
            value:       this.useSettingsValue(initialValue) ? this.filter.settings.value : initialValue
        });
    }

    protected getInitialValue(): number {
        return +super.getInitialValue() || null;
    }

    protected useSettingsValue(initialValue?: number): boolean {
        return initialValue ? false : !!this.filter.settings.value;
    }
}
