<mat-divider *ngIf="filtersValues.length"></mat-divider>

<section class="filters-status-pannel" *ngIf="filtersValues.length">
    <mat-chip-list aria-label="Filter selection">
        <mat-chip *ngFor="let filter of filtersValues" (click)="onClick(filter)" (removed)="removeValue(filter)">
            <ng-container *ngIf="filter.modelType === 'query'">
                {{filter.label}} {{filter.comparison}}
                <ng-container *ngIf="filter.value">
                    „{{filter.value}}“
                </ng-container>
            </ng-container>

            <ng-container
                *ngIf="filter.modelType === 'number'
                    || filter.modelType === 'date'
                    || filter.modelType === 'number_range'
                    || filter.modelType === 'date_period'
                    || filter.modelType === 'date_range_with_input'"
            >
                {{filter.label}} {{filter.comparison}} {{filter.value}}
            </ng-container>

            <ng-container *ngIf="filter.modelType === 'switch'">
                {{filter.label}}
            </ng-container>

            <ng-container *ngIf="filter.modelType === 'multiselect'">
                <ng-container *ngIf="filter.value.length === 1">
                    {{filter.label}}: {{getFilterValue(filter)}}
                </ng-container>
                <ng-container *ngIf="filter.value.length > 1">
                    {{filter.label}}
                    <span *ngIf="(filter.value | typeof) === 'string'; else notString">
                        : {{filter.comparison}} „{{filter.value}}“
                    </span>
                    <ng-template #notString>
                        <span class="mat-primary select-values-count-badge" matBadge="{{filter.value.length}}"></span>
                    </ng-template>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="filter.modelType === 'date_range'">
                {{filter.label}}: {{filter.value}}
            </ng-container>

            <ng-container *ngIf="filter.modelType === 'select'">
                {{filter.label}}: {{filter.optionValues[filter.value]}}
            </ng-container>

            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
    </mat-chip-list>
</section>

