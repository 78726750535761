import {Response, ResponseMessage} from './response.interface';
import {Crud} from '../crud/crud.interface';
import {NotificationTypes} from '@shared/enums/notification-types.enum';

export class ErrorResponse implements Response<Crud> {
    entity?: Crud;
    messages: Array<ErrorResponseMessage> = [];
    formErrors?: {};
    data?: {};

    constructor(data) {
        this.entity     = data['entity'];
        this.formErrors = data['formErrors'];
        this.data       = data['data'];

        data['messages'] = data['messages'] || [];

        for (const message of data['messages']) {
            this.messages.push(new ErrorResponseMessage(message));
        }
    }
}

export class ErrorResponseMessage implements ResponseMessage {
    type: NotificationTypes;
    message: string;
    description: string;
    code: string;

    constructor(data) {
        this.type = data['type'];
        this.message = data['message'];
        this.description = data['description'];
        this.code = data['code'];
    }
}
