import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromRoot from '../../../app/app.module';
import * as fromCrud from '../crud/crud.reducer';

export interface State extends fromRoot.State {
    crud: fromCrud.CrudState;
}

export interface FiltersState extends fromRoot.State {
    filters: [],
    usedFilters: {},
}

const getCrudFeatureState = createFeatureSelector<fromCrud.CrudState>('crud');

export const getCrud = createSelector(
    getCrudFeatureState,
    state => state
);

export const getData = createSelector(
    getCrudFeatureState,
    state => state.data
);

export const getError = createSelector(
    getCrudFeatureState,
    state => state.error
);

export const getFilters = createSelector(
    getCrudFeatureState,
    state => {
        return {
            filters: state ? state.availableFilters.filter(filter => !filter.isHidden) : [],
            usedFilters: state ? state.usedFilters : {}
        } as FiltersState;
    }
);

export const getSortings = createSelector(
    getCrudFeatureState,
    state => {
        return {
            sortings: state ? state.availableSortingFields : [],
            usedSorting: state ? state.usedSorting : {}
        }
    }
);

export const getStatistics = createSelector(
    getCrudFeatureState,
    state => {
        return {
            statistics: state ? state.statistics : [],
            statisticsOnDemandUrl: state ? state.statisticsOnDemandUrl : null
        }
    }
);

export const isDataLoadedAndExists = createSelector(
    getCrudFeatureState,
    state => {
        return {
            isDataLoaded: state.isDataLoaded,
            isDataExists: !!Object.keys(state.data).length
        }
    }
);
