<div class="pos-f-t fixed-top header">
    <nav class="navbar">
        <a class="navbar-brand" [routerLink]="['/dashboard']" href="javascript:void(0)">
            <img src="/assets/images/logo-white.svg" class="logo user-avatar"/>
        </a>
        <div class="navbar-nav">
            <mat-icon>donut_large</mat-icon>
            <mat-icon>chat</mat-icon>
            <mat-icon>notifications</mat-icon>
            <button class="user-name" mat-button [matMenuTriggerFor]="menu">{{getCurrentUserName()}}</button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item [routerLink]="['/login']">Login/Logout</button>
            </mat-menu>
            <div class="avatar"></div>
        </div>
    </nav>
</div>

