import {DynamicFormControlLayout, DynamicInputModel, DynamicInputModelConfig} from '@ng-dynamic-forms/core';

export const DYNAMIC_FORM_CONTROL_TYPE_BUTTON = 'BUTTON';
export const DYNAMIC_FORM_CONTROL_TYPE_BUTTON_POPUP_ACCEPT = 'BUTTON_POPUP_ACCEPT';
export const DYNAMIC_FORM_CONTROL_TYPE_BUTTON_POPUP_CLOSE = 'BUTTON_POPUP_CLOSE';

export class DynamicButtonModel extends DynamicInputModel {
    type: string = DYNAMIC_FORM_CONTROL_TYPE_BUTTON;

    constructor(config: DynamicInputModelConfig, layout?: DynamicFormControlLayout, type?: string) {
        super(config, layout);

        this.type = type || DYNAMIC_FORM_CONTROL_TYPE_BUTTON;
    }

}
