import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {ApiRoute} from '../services/api-router.service';
import {PermissionService} from '../services/permission.service';
import {SleepHelper} from '../helpers/sleep.helper';

@Injectable()
export class PermissionsGuard implements CanActivate {
    constructor(public router: Router, private  permissionService: PermissionService) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        const permission: ApiRoute = route.data['permission'];

        if (!this.permissionService.isInitialized()) {
            await SleepHelper.sleep(200);

            return this.canActivate(route, state);
        }

        if (!this.permissionService.isPermitted(permission)) {
            return this.router.navigate(['/denied']);
        }

        return true;
    }
}
