export interface Roles {
    user?: boolean;
    admin?: boolean;
    picker?: boolean;
    printer?: boolean;
}

export class User {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    roles: Roles;
    color: string | null;

    constructor(userData: any) {
        this.id        = userData.id;
        this.color     = userData['color'] ?? null;
        this.firstName = userData.firstName || userData.firstname;
        this.lastName  = userData.lastName || userData.lastname;
        this.email     = userData.email;
        this.roles     = userData.roles;
    }

}

export interface Users {
    [index: string]: {
        user: User,
        token: string,
        refresh_token: string
    }
}
