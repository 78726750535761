import {Component} from '@angular/core';

import {PermissionService} from '@shared/services/permission.service';
import {UsersService} from '@shared/services/users.service';

@Component({
    selector:    'app-root',
    templateUrl: './app.component.html',
    styleUrls:   ['./app.component.scss']
})
export class AppComponent {

    constructor(
        private permissionService: PermissionService,
        private usersService: UsersService
    ) {
        if (this.usersService.isLoggedIn()) {
            this.permissionService.initPermissions();
        }
    }

    canStart() {
        return !this.usersService.isLoggedIn()
            || (this.usersService.isLoggedIn() && !localStorage.getItem('isLoggedin'))
            || this.permissionService.isInitialized();
    }
}
