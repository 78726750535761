import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ListStatisticComponent} from './list-statistic.component';
import {NgChartsModule} from 'ng2-charts';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgChartsModule,
        MatCardModule,
        MatIconModule,
        MatExpansionModule,
        MatButtonModule
    ],
    declarations: [ListStatisticComponent],
    exports: [ListStatisticComponent]
})
export class ListStatisticModule { }
