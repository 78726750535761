import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromRoot from '../../../app/app.module';
import * as fromListFilter from '../list-filter/list-filter.reducer';

export interface State extends fromRoot.State {
    listFilter: fromListFilter.ListFilterState;
}

const getListFilterFeatureState = createFeatureSelector<fromListFilter.ListFilterState>('list-filter');

export const getData = createSelector(
    getListFilterFeatureState,
    state => state.data
);

export const getFiltersValues = createSelector(
    getListFilterFeatureState,
    state => state.filtersModels
);

export const getFilters = createSelector(
    getListFilterFeatureState,
    state => state ? state.filters : []
);
