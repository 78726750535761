import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable()
export class MultipleRolesGuard implements CanActivate {
    constructor(public router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        const multipleRoles = (user.roles.picker && user.roles.printer) || user.roles.admin;

        if (!multipleRoles) {
        }

        return true;
    }
}
