import {Injectable} from '@angular/core';

import {IsSetFilter as BaseFilter} from '../../../filter-main-panel/filters/is-set/is-set.filter';

@Injectable()
export class IsSetFilter extends BaseFilter {

    protected initMainModel(): void {
    }
}

