import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG} from '../../../app-config/app-config.constants';
import {IAppConfig} from '../../../app-config/app-config.interface';
import {BulkActionModel} from './models/bulk-action.model';

@Injectable()
export class BulkActionsService {
    orderAdjustmentsUrl = '/api/admin/v1/orders/adjustments/';

    constructor(private http: HttpClient,
                @Inject(APP_CONFIG) private config: IAppConfig) {
        this.orderAdjustmentsUrl = config.API_DOMAIN + this.orderAdjustmentsUrl;
    }

    executeAction(action: BulkActionModel, ids) {
        return this.http.request(action.method, action.url, { body : {'ids' : ids}});
    }
}
